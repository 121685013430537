import { init } from '@sentry/nextjs';
import {
  GET_BILLING_SUMMARY_REQUEST,
  GET_BILLING_SUMMARY_SUCCESS,
  GET_BILLING_SUMMARY_FAILURE,
  GET_BILLING_DOCUMENTS_REQUEST,
  GET_BILLING_DOCUMENTS_SUCCESS,
  GET_BILLING_DOCUMENTS_FAILURE,
  GET_BILLING_DOCUMENT_REQUEST,
  GET_BILLING_DOCUMENT_SUCCESS,
  GET_BILLING_DOCUMENT_FAILURE,
  GET_BILLING_DRAFT_DOCUMENT_REQUEST,
  GET_BILLING_DRAFT_DOCUMENT_SUCCESS,
  GET_BILLING_DRAFT_DOCUMENT_FAILURE,
  GET_BILLING_PROFILES_REQUEST,
  GET_BILLING_PROFILES_SUCCESS,
  GET_BILLING_PROFILES_FAILURE,
  PATCH_BILLING_PROFILE_REQUEST,
  PATCH_BILLING_PROFILE_FAILURE,
  PATCH_BILLING_PROFILE_SUCCESS,
  POST_BILLING_PROFILE_REQUEST,
  POST_BILLING_PROFILE_FAILURE,
  POST_BILLING_PROFILE_SUCCESS,
  DELETE_BILLING_PROFILE_REQUEST,
  DELETE_BILLING_PROFILE_FAILURE,
  DELETE_PAYMENT_ACCOUNT_REQUEST,
  DELETE_PAYMENT_ACCOUNT_FAILURE,
  DELETE_PAYMENT_ACCOUNT_SUCCESS,
  GET_PAYMENT_ACCOUNTS_REQUEST,
  GET_PAYMENT_ACCOUNTS_SUCCESS,
  GET_PAYMENT_ACCOUNTS_FAILURE,
  PATCH_PAYMENT_ACCOUNT_REQUEST,
  PATCH_PAYMENT_ACCOUNT_FAILURE,
  PATCH_PAYMENT_ACCOUNT_SUCCESS,
  POST_PAYMENT_ACCOUNT_REQUEST,
  POST_PAYMENT_ACCOUNT_FAILURE,
  POST_PAYMENT_ACCOUNT_SUCCESS,
  GET_BILLING_CACHED,
  SET_USER_IMPERSONATION,
  APPROVE_INVOICE_DOCUMENT_REQUEST,
  APPROVE_INVOICE_DOCUMENT_SUCCESS,
  APPROVE_INVOICE_DOCUMENT_FAILURE,
  IS_WITHDRAW_AVAILABLE_REQUEST,
  IS_WITHDRAW_AVAILABLE_SUCCESS,
  IS_WITHDRAW_AVAILABLE_FAILURE,
  GET_WITHDRAWALS_REQUEST,
  GET_WITHDRAWALS_SUCCESS,
  GET_WITHDRAWALS_FAILURE,
  GET_LAST_WITHDRAWAL_REQUEST,
  GET_LAST_WITHDRAWAL_SUCCESS,
  GET_LAST_WITHDRAWAL_FAILURE,
  POST_WITHDRAWAL_REQUEST,
  POST_WITHDRAWAL_SUCCESS,
  POST_WITHDRAWAL_FAILURE,
  CHECK_PAYEE_ID_REQUEST,
  CHECK_PAYEE_ID_SUCCESS,
  CHECK_PAYEE_ID_FAILURE,
  POST_PAYONEER_REGISTRATION_REQUEST,
  POST_PAYONEER_REGISTRATION_SUCCESS,
  POST_PAYONEER_REGISTRATION_FAILURE,
  CHECK_TIN_REQUEST,
  CHECK_TIN_SUCCESS,
  CHECK_TIN_FAILURE,
  CHECK_COMPANY_TIN_REQUEST,
  CHECK_COMPANY_TIN_SUCCESS,
  CHECK_COMPANY_TIN_FAILURE,
} from '../action/actionTypes';

const initialState = {
  summary: null,
  documents: null,
  document: null,
  profiles: null,
  paymentAccounts: null,
  error: '',
  errors: null,
  errorApprove: null,
  errorDocument: null,
  isWaiting: false,
  isWaitingDocuments: false,
  isWaitingPayee: false,
  isWaitingLastWidrawal: false,
  isProfilesWaiting: false,
  isPaymentAccountsWaiting: false,
  isApproveWaiting: false,
  lastRefresh: null,
  isWithrawAvailable: false,
  lastWithdrawal: { status: 'processed' },
  createWithdrawal: false,
  payee: false,
  payoneerRegistration: null,
  checkTin: null,
  checkCompanyTin: null,
};

export default function billing(state = initialState, action) {
  switch (action.type) {
    case APPROVE_INVOICE_DOCUMENT_REQUEST:
      return { ...state, isApproveWaiting: true, errorApprove: initialState.errorApprove };
    case APPROVE_INVOICE_DOCUMENT_SUCCESS:
      return {
        ...state,
        document: action.payload,
        isApproveWaiting: false,
      };
    case APPROVE_INVOICE_DOCUMENT_FAILURE:
      return { ...state, errorApprove: action.error, isApproveWaiting: false };
    case GET_BILLING_SUMMARY_REQUEST:
      return { ...state, isWaiting: true, error: initialState.error };
    case GET_BILLING_SUMMARY_SUCCESS:
      return {
        ...state,
        summary: action.payload,
        isWaiting: false,
        error: initialState.error,
      };
    case GET_BILLING_SUMMARY_FAILURE:
      return { ...state, error: action.error, isWaiting: false };
    case GET_BILLING_DOCUMENTS_REQUEST:
      return {
        ...state,
        documents: initialState.documents,
        isWaitingDocuments: true,
        error: initialState.error,
      };
    case GET_BILLING_DOCUMENTS_SUCCESS:
      return {
        ...state,
        documents: action.payload,
        isWaitingDocuments: false,
        error: initialState.error,
      };
    case GET_BILLING_DOCUMENTS_FAILURE:
      return { ...state, error: action.error, isWaitingDocuments: false };
    case GET_BILLING_DOCUMENT_REQUEST:
      return {
        ...state,
        document: initialState.document,
        isWaiting: true,
        errorDocument: initialState.errorDocument,
        errorApprove: initialState.errorApprove,
      };
    case GET_BILLING_DOCUMENT_SUCCESS:
      return {
        ...state,
        document: action.payload,
        isWaiting: false,
      };
    case GET_BILLING_DOCUMENT_FAILURE:
      return { ...state, errorDocument: action.error, isWaiting: false };
    case GET_BILLING_DRAFT_DOCUMENT_REQUEST:
      return {
        ...state,
        document: initialState.document,
        isWaiting: true,
        errorDocument: initialState.errorDocument,
        errorApprove: initialState.errorApprove,
      };
    case GET_BILLING_DRAFT_DOCUMENT_SUCCESS:
      return {
        ...state,
        document: action.payload,
        isWaiting: false,
      };
    case GET_BILLING_DRAFT_DOCUMENT_FAILURE:
      return { ...state, errorDocument: action.error, isWaiting: false };
    case GET_BILLING_CACHED:
      return {
        ...state,
        isWaiting: false,
        error: initialState.error,
      };
    case GET_BILLING_PROFILES_REQUEST:
      return { ...state, isProfilesWaiting: true, error: initialState.error };
    case GET_BILLING_PROFILES_SUCCESS:
      return {
        ...state,
        profiles: action.payload.billingProfiles,
        paymentAccounts: action.payload.paymentAccounts,
        isProfilesWaiting: false,
        error: initialState.error,
      };
    case GET_BILLING_PROFILES_FAILURE:
      return { ...state, error: action.error, isProfilesWaiting: false };
    case POST_BILLING_PROFILE_REQUEST:
      return { ...state, isWaiting: true, errors: initialState.errors };
    case POST_BILLING_PROFILE_FAILURE:
      return { ...state, errors: action.error, isWaiting: false };
    case POST_BILLING_PROFILE_SUCCESS:
      return { ...state, isWaiting: false, errors: initialState.errors };
    case PATCH_BILLING_PROFILE_REQUEST:
      return { ...state, isWaiting: true, errors: initialState.errors };
    case PATCH_BILLING_PROFILE_FAILURE:
      return { ...state, errors: action.error, isWaiting: false };
    case PATCH_BILLING_PROFILE_SUCCESS:
      return { ...state, isWaiting: false, errors: initialState.errors };
    case DELETE_BILLING_PROFILE_REQUEST:
      return { ...state, isWaiting: true, error: initialState.error };
    case DELETE_BILLING_PROFILE_FAILURE:
      return { ...state, error: action.error, isWaiting: false };
    case GET_PAYMENT_ACCOUNTS_REQUEST:
      return { ...state, isPaymentAccountsWaiting: true, error: initialState.error };
    case GET_PAYMENT_ACCOUNTS_SUCCESS:
      return {
        ...state,
        paymentAccounts: action.payload,
        isPaymentAccountsWaiting: false,
        error: initialState.error,
      };
    case GET_PAYMENT_ACCOUNTS_FAILURE:
      return { ...state, error: action.error, isPaymentAccountsWaiting: false };
    case SET_USER_IMPERSONATION:
      return { ...initialState };
    case DELETE_PAYMENT_ACCOUNT_REQUEST:
      return { ...state, isWaiting: true, error: initialState.error };
    case DELETE_PAYMENT_ACCOUNT_SUCCESS:
      return { ...state, isWaiting: false, error: initialState.error };
    case DELETE_PAYMENT_ACCOUNT_FAILURE:
      return { ...state, error: action.error, isWaiting: false };
    case POST_PAYMENT_ACCOUNT_REQUEST:
      return { ...state, isWaiting: true, errors: initialState.errors };
    case POST_PAYMENT_ACCOUNT_FAILURE:
      return { ...state, errors: action.error, isWaiting: false };
    case POST_PAYMENT_ACCOUNT_SUCCESS:
      return { ...state, isWaiting: false, errors: initialState.errors };
    case PATCH_PAYMENT_ACCOUNT_REQUEST:
      return { ...state, isWaiting: true, errors: initialState.errors };
    case PATCH_PAYMENT_ACCOUNT_FAILURE:
      return { ...state, errors: action.error, isWaiting: false };
    case PATCH_PAYMENT_ACCOUNT_SUCCESS:
      return { ...state, isWaiting: false, errors: initialState.errors };
    case IS_WITHDRAW_AVAILABLE_REQUEST:
      return {
        ...state,
        isWithrawAvailable: initialState.isWithrawAvailable,
        isWaiting: true,
        errors: initialState.errors,
      };
    case IS_WITHDRAW_AVAILABLE_FAILURE:
      return { ...state, errors: action.error, isWaiting: false };
    case IS_WITHDRAW_AVAILABLE_SUCCESS:
      return {
        ...state,
        isWithrawAvailable: action.payload,
        isWaiting: false,
        errors: initialState.errors,
      };
    case GET_WITHDRAWALS_REQUEST:
      return { ...state, isWaiting: true, errors: initialState.errors };
    case GET_WITHDRAWALS_FAILURE:
      return { ...state, errors: action.error, isWaiting: false };
    case GET_WITHDRAWALS_SUCCESS:
      return { ...state, isWaiting: false, errors: initialState.errors };
    case GET_LAST_WITHDRAWAL_REQUEST:
      return {
        ...state,
        lastWithdrawal: initialState.lastWithdrawal,
        isWaitingLastWidrawal: true,
        errors: initialState.errors,
      };
    case GET_LAST_WITHDRAWAL_FAILURE:
      return { ...state, errors: action.error, isWaitingLastWidrawal: false };
    case GET_LAST_WITHDRAWAL_SUCCESS:
      return {
        ...state,
        lastWithdrawal: action.payload,
        isWaitingLastWidrawal: false,
        errors: initialState.errors,
      };
    case POST_WITHDRAWAL_REQUEST:
      return {
        ...state,
        isWaiting: true,
        createWithdrawal: false,
        errors: initialState.errors,
      };
    case POST_WITHDRAWAL_FAILURE:
      return { ...state, errors: action.error, isWaiting: false };
    case POST_WITHDRAWAL_SUCCESS:
      return {
        ...state,
        isWaiting: false,
        createWithdrawal: true,
        errors: initialState.errors,
      };
    case CHECK_PAYEE_ID_REQUEST:
      return {
        ...state,
        isWaitingPayee: true,
        payee: initialState.payee,
        errors: initialState.errors,
      };
    case CHECK_PAYEE_ID_FAILURE:
      return { ...state, errors: action.error, payee: initialState.payee, isWaitingPayee: false };
    case CHECK_PAYEE_ID_SUCCESS:
      return {
        ...state,
        isWaitingPayee: false,
        payee: action.payload,
        errors: initialState.errors,
      };
    case POST_PAYONEER_REGISTRATION_REQUEST:
      return {
        ...state,
        isWaiting: true,
        payoneerRegistration: initialState.payee,
        errors: initialState.errors,
      };
    case POST_PAYONEER_REGISTRATION_FAILURE:
      return { ...state, errors: action.error, isWaiting: false };
    case POST_PAYONEER_REGISTRATION_SUCCESS:
      return {
        ...state,
        isWaiting: false,
        payoneerRegistration: action.payload,
        errors: initialState.errors,
      };
    case CHECK_TIN_REQUEST:
      return {
        ...state,
        errors: initialState.errors,
      };
    case CHECK_TIN_FAILURE:
      return { ...state, errors: action.error, checkTin: null };
    case CHECK_TIN_SUCCESS:
      return {
        ...state,
        checkTin: action?.payload?.data,
        errors: initialState.errors,
      };
    case CHECK_COMPANY_TIN_REQUEST:
      return {
        ...state,
        errors: initialState.errors,
      };
    case CHECK_COMPANY_TIN_FAILURE:
      return { ...state, errors: action.error, checkCompanyTin: null };
    case CHECK_COMPANY_TIN_SUCCESS:
      return {
        ...state,
        checkCompanyTin: action?.payload?.data,
        errors: initialState.errors,
      };
    default:
      return state;
  }
}
