/* eslint-disable import/prefer-default-export */
import * as types from './actionTypes';

export function closeFeedback() {
  return {
    type: types.CLOSE_FEEDBACK,
  };
}

export function openFeedback(payload) {
  return {
    type: types.OPEN_FEEDBACK,
    payload,
  };
}
