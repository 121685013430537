import english from './en.json';
import italian from './it.json';
import portghese from './pt.json';
import deutsch from './de.json';

export default {
  '': english,
  en: english,
  it: italian,
  pt: portghese,
  de: deutsch,
};
