import React from 'react';

function OfflinePinAlt({ width }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width || '19'} viewBox="0 0 19 18" fill="none">
      <g clipPath="url(#clip0_3656_offline-pin-alt)">
        <path
          d="M14.54 15.0492H4.54004V13.0492H14.54V15.0492ZM7.84004 11.0492L4.54004 7.74922L5.94004 6.34922L7.84004 8.24922L13.14 2.94922L14.54 4.34922L7.84004 11.0492Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3656_offline-pin-alt">
          <rect width="18" height="18" fill="white" transform="translate(0.540039)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default OfflinePinAlt;
