import * as types from './actionTypes';

export function getHistogramRequest(payload) {
  return {
    type: types.GET_HISTOGRAM_REQUEST,
    payload,
  };
}

export function getHistogramSuccess(payload) {
  return {
    type: types.GET_HISTOGRAM_SUCCESS,
    payload,
  };
}

export function getHistogramFailure(error) {
  return {
    type: types.GET_HISTOGRAM_FAILURE,
    error,
  };
}

export function getHistogramCached() {
  return {
    type: types.GET_HISTOGRAM_CACHED,
  };
}
