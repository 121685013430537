import { takeLatest, put } from 'redux-saga/effects';
import { CHECK_REDIRECT_REQUEST } from 'redux-api/action/actionTypes';
import * as actions from 'redux-api/action/redirect';

function* redirectSaga({ payload }) {
  try {
    const redirectUri = process.env.REDIRECT_URI;
    const redirectMinWidth = process.env.REDIRECT_MIN_WIDTH;

    if (redirectUri && redirectMinWidth && payload < redirectMinWidth) {
      document.location.href = redirectUri;
    } else {
      yield put(actions.checkRedirectSuccess());
    }
  } catch (err) {
    yield put(actions.checkRedirectFailure(err));
  }
}

export default function* watchRedirect() {
  yield takeLatest(CHECK_REDIRECT_REQUEST, redirectSaga);
}
