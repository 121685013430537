import moment from 'moment';
import { put, takeLatest, call, select } from 'redux-saga/effects';
import { getStores } from 'api';
import { GET_STORES_REQUEST } from 'redux-api/action/actionTypes';
import * as actions from 'redux-api/action/stores';
import { openFeedback } from 'redux-api/action/feedback';
import translations from 'translations';

const selectConfig = (state) => state.config;
const selectLastRefresh = (state) => state.stores.lastRefresh;

function* getStoresSaga() {
  const { languageCode } = yield select(selectConfig);

  try {
    const now = moment();
    const lastRefresh = yield select(selectLastRefresh);
    const maxCachedTime = process.env.CACHED_TIME;

    if (lastRefresh) {
      const diff = now.diff(lastRefresh, 'minutes');

      if (diff < maxCachedTime) {
        return yield put(actions.getStoresCached());
      }
    }

    const { data } = yield call(getStores);

    return yield put(actions.getStoresSuccess(data));
  } catch (err) {
    yield put(
      openFeedback({
        message: translations[languageCode].generic_retrieve_api_error,
        error: err,
      }),
    );

    return yield put(actions.getStoresFailure(err));
  }
}

export default function* watchStores() {
  yield takeLatest(GET_STORES_REQUEST, getStoresSaga);
}
