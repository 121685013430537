import moment from 'moment';
import { put, takeEvery, call, select, take } from 'redux-saga/effects';
import { getHistory } from 'api';
import {
  AUTH_REFRESH_SUCCESS,
  AUTH_REFRESH_FAILURE,
  AUTH_REFRESH_SKIP,
  GET_HISTORY_REQUEST,
} from 'redux-api/action/actionTypes';
import * as actions from 'redux-api/action/history';
import { refreshRequest } from 'redux-api/action/auth';
import selectAuth from 'redux-api/reselect/auth';
import { openFeedback } from 'redux-api/action/feedback';
import translations from 'translations';

const selectConfig = (state) => state.config;
const selectLastRefresh = (state) => state.history.lastRefresh;

function* getHistorySaga({ payload }) {
  const { languageCode } = yield select(selectConfig);

  try {
    // REFRESH TOKEN
    yield put(refreshRequest());
    const { type } = yield take([AUTH_REFRESH_SUCCESS, AUTH_REFRESH_FAILURE, AUTH_REFRESH_SKIP]);
    if (type === AUTH_REFRESH_FAILURE) throw new Error('401 - Unauthorized');

    // eslint-disable-next-line camelcase
    const { attributes, signInUserSession, adminRequestedStreetlibInternalId } = yield select(
      selectAuth,
    );

    const { accessToken } = signInUserSession;

    const now = moment();
    const lastRefresh = yield select(selectLastRefresh);
    const maxCachedTime = process.env.CACHED_TIME;

    if (lastRefresh) {
      const diff = now.diff(lastRefresh, 'minutes');

      if (diff < maxCachedTime) {
        return yield put(actions.getHistoryCached());
      }
    }

    const { data } = yield call(
      getHistory,
      accessToken,
      attributes['custom:internal_id'],
      adminRequestedStreetlibInternalId,
    );

    return yield put(actions.getHistorySuccess({ ...payload, data }));
  } catch (err) {
    yield put(
      openFeedback({
        message: translations[languageCode].generic_retrieve_api_error,
        error: err,
      }),
    );

    return yield put(actions.getHistoryFailure({ ...payload, error: err }));
  }
}

export default function* watchHistory() {
  yield takeEvery(GET_HISTORY_REQUEST, getHistorySaga);
}
