import * as Sentry from '@sentry/react';
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { createWrapper } from 'next-redux-wrapper';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import createFilter from 'redux-persist-transform-filter';
import * as reducers from './reducer';
import rootSaga from './saga';

const configFilter = createFilter('config', ['formatDate', 'languageCode', 'currency']);
const profileFilter = createFilter('profile', ['localAuth']);
const authFilter = createFilter('auth', ['payload', 'adminRequestedStreetlibInternalId']);
const warningFilter = createFilter('warning', ['isVisible']);
const filtersFilter = createFilter('filters', [
  'compare',
  'dataType',
  'dateRange',
  'dates',
  'others',
  'terms',
  'initialState',
  'compareTo',
  'reloadChecked',
]);

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ['auth', 'profile', 'config', 'warning', 'filters'],
  transforms: [profileFilter, authFilter, configFilter, warningFilter, filtersFilter],
  timeout: null,
};

const persistedReducer = persistReducer(persistConfig, combineReducers(reducers));

const makeStore = () => {
  const sagaMiddleware = createSagaMiddleware({
    onError(err) {
      Sentry.captureException(err);
    },
  });

  const store = configureStore({
    reducer: persistedReducer,
    middleware: [sagaMiddleware],
    devTools: process.env.NODE_ENV !== 'production',
  });

  store.runSagaTask = () => {
    store.sagaTask = sagaMiddleware.run(rootSaga);
  };

  store.runSagaTask();

  store.__persistor = persistStore(store);

  return store;
};

export default createWrapper(makeStore);
