import { put, takeEvery, call, select, take } from 'redux-saga/effects';
import { getCountries, getSubdivisions } from 'api';
import {
  AUTH_REFRESH_SUCCESS,
  AUTH_REFRESH_FAILURE,
  AUTH_REFRESH_SKIP,
  GET_COUNTRIES_REQUEST,
  GET_SUBDIVISIONS_REQUEST,
} from 'redux-api/action/actionTypes';
import * as actions from 'redux-api/action/countries';
import { refreshRequest } from 'redux-api/action/auth';
import selectAuth from 'redux-api/reselect/auth';
import { openFeedback } from 'redux-api/action/feedback';
import translations from 'translations';

const selectConfig = (state) => state.config;

function* getCountriesSaga({ payload }) {
  const { languageCode } = yield select(selectConfig);

  try {
    const { data } = yield call(getCountries, payload);

    yield put(actions.getCountriesSuccess(data));
  } catch (err) {
    yield put(actions.getCountriesFailure(err));

    yield put(
      openFeedback({
        message: translations[languageCode].generic_retrieve_api_error,
        error: err
      }),
    );
  }
}

function* getSubdivisionsSaga({ payload }) {
  const { languageCode } = yield select(selectConfig);

  try {
    // REFRESH TOKEN
    yield put(refreshRequest());
    const { type } = yield take([AUTH_REFRESH_SUCCESS, AUTH_REFRESH_FAILURE, AUTH_REFRESH_SKIP]);
    if (type === AUTH_REFRESH_FAILURE) throw new Error('401 - Unauthorized');

    // eslint-disable-next-line camelcase
    const { signInUserSession } = yield select(selectAuth);

    const { accessToken } = signInUserSession;

    const { data } = yield call(getSubdivisions, languageCode, accessToken, payload);

    yield put(actions.getSubdivisionsSuccess(data));
  } catch (err) {
    yield put(actions.getSubdivisionsFailure(err));

    yield put(
      openFeedback({
        message: translations[languageCode].generic_retrieve_api_error,
        error: err
      }),
    );
  }
}

export default function* watchCountries() {
  yield takeEvery(GET_COUNTRIES_REQUEST, getCountriesSaga);
  yield takeEvery(GET_SUBDIVISIONS_REQUEST, getSubdivisionsSaga);
}
