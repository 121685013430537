const breakpoints = [920, 1024, 1440, 2100];
const fontSizes = [60, 44, 32, 28, 30, 22, 16, 14, 12];
const lineHeights = [108, 60, 44, 40, 36, 30, 22, 20];

export default {
  colors: {
    white: '#FFF',
    warning: '#FF9800',
    accent: '#FECE70',
    accent_light: '#FFF5E6',
    sales: '#66C4A1',
    earning: '#74AEBA',
    primary_600: '#0E4B53',
    primary_500: '#067786',
    primary_400: '#0A8EA0',
    primary_300: '#4CADBA',
    primary_200: '#B0DAE0',
    primary_100: '#E0F1F3',
    primary_75: '#EDF7F8',
    primary_50: '#F5FAFB',
    blue_500: '#0A3A8B',
    blue_400: '#3F6CB4',
    blue_300: '#85A1D0',
    grey_600: '#344244',
    grey_400: '#5E6F72',
    grey_200: '#D1D1D1',
    grey_150: '#E7E7E7',
    grey_05: '#9B9B9B',
    grey_01: '#F7F7F7',
    grey_100: '#F7F7F7',
    lime_300: '#88C363',
    lime_400: '#337B09',
    red_100: '#f4c7c1',
    red_400: '#EA341B',
    red_500: '#E22311',
    red_600: '#D41A0C',
    orange_400: '#FFA66D',
    area_0: '#0E4B53',
    area_1: '#0A8EA0',
    area_2: '#D1D1D1',
    map_4: '#0E4B53',
    map_3: '#0A8EA0',
    map_2: '#B44B3D',
    map_1: '#B08034',
    map_0: '#82AD68',
    pie_0_0: '#88C363',
    pie_0_1: '#2ABA83',
    pie_0_2: '#00ADA5',
    pie_0_3: '#009BBD',
    pie_0_4: '#0085C3',
    pie_0_5: '#3F6CB4',
    pie_1_0: '#CDAF7E',
    pie_1_1: '#D69679',
    pie_1_2: '#D37E85',
    pie_1_3: '#BD6F9A',
    pie_1_4: '#906BAD',
    pie_1_5: '#3F6CB4',
    pie_2_0: '#DCA3B9',
    pie_2_1: '#CB94B7',
    pie_2_2: '#B487B8',
    pie_2_3: '#987CB8',
    pie_2_4: '#7373B7',
    pie_2_5: '#3F6CB4',
    pink: '#FFE7E7',
    write: '#FF9800',
    distribute: '#00BCD4',
    print: '#009688',
    orange_400_transparent: 'rgba(255, 166, 109, 0.3)',
    orange_500_transparent: 'rgba(255, 166, 109, 0.5)',
    red_400_transparent: 'rgba(180, 75, 61, 0.3)',
    purple_50_transparent: 'rgba(106, 95, 193, 0.2)',
    area_duration: '#EFAC28',
    area_users: '#FF9800',
  },
  space: {
    xxxs: 2,
    xxs: 4,
    xs: 8,
    s: 16,
    m: 24,
    l: 32,
    xl: 56,
    xxl: 64,
  },
  fontWeights: {
    normal: 400,
    medium: 500,
    bold: 700,
  },
  fontSizes: {
    h1Large: fontSizes[0],
    h2Large: fontSizes[1],
    h3Large: fontSizes[2],
    h4Large: fontSizes[3],
    h1: fontSizes[4],
    h2: fontSizes[5],
    h3: fontSizes[6],
    h4: fontSizes[7],
    display: fontSizes[7],
    pLarge: fontSizes[7],
    pSmall: fontSizes[8],
    label: fontSizes[8],
  },
  lineHeights: {
    h1Large: `${lineHeights[0]}px`,
    h2Large: `${lineHeights[1]}px`,
    h3Large: `${lineHeights[2]}px`,
    h4Large: `${lineHeights[3]}px`,
    h1: `${lineHeights[4]}px`,
    h2: `${lineHeights[5]}px`,
    h3: `${lineHeights[6]}px`,
    h4: `${lineHeights[7]}px`,
    display: `${lineHeights[7]}px`,
    pLarge: `${lineHeights[6]}px`,
    pSmall: `${lineHeights[7]}px`,
    label: `${lineHeights[7]}px`,
  },
  breakpoints: [
    `${breakpoints[0] + 1}px`,
    `${breakpoints[1] + 1}px`,
    `${breakpoints[2] + 1}px`,
    `${breakpoints[3] + 1}px`,
  ],
  mediaQueries: {
    small: `${breakpoints[0]}px`,
    medium: `${breakpoints[1]}px`,
    large: `${breakpoints[2]}px`,
    xlarge: `${breakpoints[3]}px`,
  },
};
