import * as types from './actionTypes';

export function getStoresRequest() {
  return {
    type: types.GET_STORES_REQUEST,
  };
}

export function getStoresSuccess(payload) {
  return {
    type: types.GET_STORES_SUCCESS,
    payload,
  };
}

export function getStoresFailure(error) {
  return {
    type: types.GET_STORES_FAILURE,
    error,
  };
}

export function getStoresCached() {
  return {
    type: types.GET_STORES_CACHED,
  };
}
