/* eslint-disable no-nested-ternary */
import React, { useCallback } from 'react';
import styled, { keyframes } from 'styled-components';
import { color } from 'styled-system';
import { useSelector, useDispatch } from 'react-redux';
import { resetDownloadGet } from 'redux-api/action/csv';
import Loader from 'theme/icons/loader';
import Text from 'theme/text';
import Close from 'theme/icons/close';
import AlertRhombus from 'theme/icons/alert-rhombus';
import OfflinePinAlt from 'theme/icons/offline-pin-alt';
import translations from 'translations';

const Wrapper = styled.div`
  position: fixed;
  bottom: 32px;
  z-index: 999;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px 16px;
  box-shadow: 0px -2px 60px rgba(163, 163, 164, 0.1);
  border-radius: 8px;

  ${color}
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const WrapLoader = styled.div`
  ${color}

  width: 20px;
  height: 20px;
  animation: ${rotate} 2s linear infinite;
`;

const Box = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #fff;

  & > button {
    color: #fff;
    margin-left: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0;
  }
`;

const CustomLink = styled.a`
  color: #fff;
  text-decoration: underline;

  &:hover {
    color: #fff;
    text-decoration: underline;
  }

  &:focus {
    color: #fff;
    text-decoration: underline;
  }
`;

function DownloadBanner() {
  const dispatch = useDispatch();

  const { languageCode } = useSelector((state) => state.config);
  const { isDownloadWaiting, timeout, reportLink } = useSelector((state) => state.csv);

  const handleClose = useCallback(() => {
    dispatch(resetDownloadGet());
  }, [dispatch]);

  if (isDownloadWaiting || timeout || reportLink) {
    return (
      <Wrapper>
        <Content bg={isDownloadWaiting ? 'warning' : timeout ? 'red_400' : 'primary_400'}>
          {isDownloadWaiting && (
            <WrapLoader color="grey_100">
              <Loader width="20" />
            </WrapLoader>
          )}
          {isDownloadWaiting && (
            <Text fontSize="pLarge" lineHeight="pLarge" fontWeight="medium" color="grey_100">
              {translations[languageCode]['Report creation in progress...']}
            </Text>
          )}

          {timeout && (
            <Box>
              <AlertRhombus />
              <Text
                fontSize="pLarge"
                lineHeight="pLarge"
                fontWeight="medium"
                color="grey_100"
                ml="xs"
              >
                {translations[languageCode]['Timeout processing report please contact support']}
              </Text>
              <button type="button" className="close" onClick={handleClose}>
                <Close />
              </button>
            </Box>
          )}

          {reportLink && (
            <Box>
              <OfflinePinAlt />

              <Text
                fontSize="pLarge"
                lineHeight="pLarge"
                fontWeight="medium"
                color="grey_100"
                mr="xs"
                ml="xs"
              >
                {translations[languageCode]['Report ready']}
              </Text>

              <CustomLink target="_blank" rel="noreferrer" style={{ p: 0, m: 0 }} href={reportLink}>
                {translations[languageCode]['Download file']}
              </CustomLink>

              <button type="button" className="close" onClick={handleClose}>
                <Close />
              </button>
            </Box>
          )}
        </Content>
      </Wrapper>
    );
  }

  return null;
}

export default DownloadBanner;
