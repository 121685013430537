import moment from 'moment';
import {
  GET_WILDCARD_REQUEST,
  GET_WILDCARD_SUCCESS,
  GET_WILDCARD_FAILURE,
  SET_USER_IMPERSONATION,
} from '../action/actionTypes';

const initialState = {
  payload: {},
};

export default function wildcard(state = initialState, action) {
  switch (action.type) {
    case GET_WILDCARD_REQUEST: {
      const { term } = action.payload;

      return {
        ...state,
        payload: {
          ...state.payload,
          [term]: {
            ...state.payload[term],
            ...action.payload,
            isWaiting: true,
          },
        },
      };
    }
    case GET_WILDCARD_SUCCESS: {
      const { term } = action.payload;

      return {
        ...state,
        payload: {
          ...state.payload,
          [term]: {
            ...action.payload,
            lastRefresh: moment(),
            isWaiting: false,
          },
        },
      };
    }
    case GET_WILDCARD_FAILURE: {
      const { term } = action.payload;

      return {
        ...state,
        payload: {
          ...state.payload,
          [term]: {
            isWaiting: false,
            error: action.payload,
          },
        },
      };
    }
    case SET_USER_IMPERSONATION:
      return { ...initialState };
    default:
      return state;
  }
}
