import moment from 'moment';

const convertStringToDateFrom = (today, range) => {
  switch (range) {
    case '30-days':
      return today.clone().subtract(30, 'days');
    case 'prev-week':
      return today.clone().subtract(1, 'weeks').startOf('isoWeek');
    case 'week':
      return today.clone().startOf('isoWeek');
    case 'prev-month':
      return today.clone().subtract(1, 'month').startOf('month');
    case 'month':
      return today.clone().startOf('month');
    case 'prev-quarter':
      return today.clone().subtract(1, 'Q').startOf('quarter');
    case 'quarter':
      return today.clone().startOf('quarter');
    case 'prev-year':
      return today.clone().subtract(1, 'year').startOf('year');
    case 'year':
      return today.clone().startOf('year');
    case 'since-start':
      return moment('01-01-2020', 'DD-MM-YYYY');
    default:
      return today.clone().subtract(30, 'days');
  }
};

const convertStringToDateTo = (today, range) => {
  switch (range) {
    case '30-days':
      return today.clone();
    case 'prev-week':
      return today.clone().subtract(1, 'weeks').endOf('isoWeek');
    case 'week':
      return today.clone();
    case 'prev-month':
      return today.clone().subtract(1, 'month').endOf('month');
    case 'month':
      return today.clone();
    case 'prev-quarter':
      return today.clone().subtract(1, 'Q').endOf('quarter');
    case 'quarter':
      return today.clone();
    case 'prev-year':
      return today.clone().subtract(1, 'year').endOf('year');
    case 'year':
      return today.clone();
    case 'since-start':
      return today.clone();
    default:
      return today.clone();
  }
};

// Return a dateFrom and dateTo for query
// profileCreated is required for since_start date
const calculateRange = (range) => {
  const today = moment();

  const dateFrom = convertStringToDateFrom(today, range);
  const dateTo = convertStringToDateTo(today, range);

  return [dateFrom, dateTo];
};

export default calculateRange;
