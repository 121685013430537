import React from 'react';

function AlertRhombus({ style, width }) {
  return (
    <svg width={width || '19'} {...style} viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip-alert-rhombus-0035948)">
        <path
          d="M9.31363 0.136719C8.86368 0.136719 8.41374 0.307697 8.04478 0.667652L0.845683 7.86675C0.134772 8.56866 0.134772 9.70252 0.845683 10.4044L8.04478 17.6035C8.7467 18.3144 9.88055 18.3144 10.5825 17.6035L17.7816 10.4044C18.4925 9.70252 18.4925 8.56866 17.7816 7.86675L10.5825 0.667652C10.2135 0.307697 9.76357 0.136719 9.31363 0.136719ZM9.31363 1.93649L16.5127 9.13559L9.31363 16.3347L2.11452 9.13559L9.31363 1.93649ZM8.41374 4.63616V10.0355H10.2135V4.63616H8.41374ZM8.41374 11.8353V13.635H10.2135V11.8353H8.41374Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip-alert-rhombus-0035948">
          <rect width="18" height="18" fill="white" transform="translate(0.3125 0.136719)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default AlertRhombus;
