import moment from 'moment';
import {
  GET_WARNING_REQUEST,
  GET_WARNING_SUCCESS,
  GET_WARNING_FAILURE,
  GET_WARNING_CACHED,
  SET_WARNING_VISIBLE,
} from '../action/actionTypes';

const initialState = {
  payload: [],
  error: '',
  isWaiting: false,
  isVisible: false,
};

export default function warning(state = initialState, action) {
  switch (action.type) {
    case GET_WARNING_REQUEST:
      return {
        ...state,
        isWaiting: true,
        error: initialState.error,
      };
    case GET_WARNING_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        isWaiting: false,
        error: initialState.error,
        lastRefresh: moment(),
      };
    case GET_WARNING_FAILURE:
      return {
        ...state,
        error: action.error,
        isWaiting: false,
      };
    case GET_WARNING_CACHED:
      return {
        ...state,
        isWaiting: false,
        error: initialState.error,
      };
    case SET_WARNING_VISIBLE:
      return {
        ...state,
        isVisible: action.payload,
      };
    default:
      return state;
  }
}
