import React from 'react';

function Close() {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M13.2426 14.884L9 10.6413L4.75736 14.884L3.34315 13.4698L7.58579 9.22713L3.34315 4.98449L4.75736 3.57028L9 7.81292L13.2426 3.57028L14.6569 4.98449L10.4142 9.22713L14.6569 13.4698L13.2426 14.884Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path d="M0 0.227051H18V18.2271H0V0.227051Z" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Close;
