import moment from 'moment';
import {
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAILURE,
  GET_PROFILE_CACHED,
  PATCH_PROFILE_REQUEST,
  PATCH_PROFILE_SUCCESS,
  PATCH_PROFILE_FAILURE,
  PATCH_LOCALIZATION_REQUEST,
  PATCH_LOCALIZATION_SUCCESS,
  PATCH_LOCALIZATION_FAILURE,
  TOKEN_ACTIVATION_REQUEST,
  TOKEN_ACTIVATION_SUCCESS,
  TOKEN_ACTIVATION_FAILURE,
  SET_LOCAL_AUTH,
  AUTH_SIGNIN_SUCCESS,
  AUTH_SIGNED_SUCCESS,
  AUTH_SIGNOUT_SUCCESS,
  PATCH_AVATAR_REQUEST,
  PATCH_AVATAR_SUCCESS,
  PATCH_AVATAR_FAILURE,
  DELETE_AVATAR_REQUEST,
  DELETE_AVATAR_FAILURE,
  DELETE_AVATAR_SUCCESS,
  POST_NEWSLETTER_REQUEST,
  POST_NEWSLETTER_SUCCESS,
  POST_NEWSLETTER_FAILURE,
  DELETE_NEWSLETTER_REQUEST,
  DELETE_NEWSLETTER_SUCCESS,
  DELETE_NEWSLETTER_FAILURE,
} from '../action/actionTypes';

const initialState = {
  localAuth: false,
  payload: {},
  error: '',
  hasAdminRole: false,
  isWaiting: false,
  isWaitingCustomerType: false,
  isGetWaiting: true,
  lastRefresh: null,
  isEmailConfirmed: false,
  errorProfile: '',
  errorAvatar: '',
  errorTokenActivation: null,
  errorLocalization: null,
  errorNotification: null,
  errorCustomerType: null,
};

export default function profile(state = initialState, action) {
  switch (action.type) {
    case TOKEN_ACTIVATION_REQUEST:
      return {
        ...state,
        errorTokenActivation: initialState.errorTokenActivation,
        isWaiting: true,
      };
    case TOKEN_ACTIVATION_SUCCESS:
      return {
        ...state,
        errorTokenActivation: initialState.errorTokenActivation,
        isEmailConfirmed: true,
        isWaiting: false,
        lastRefresh: null,
      };
    case TOKEN_ACTIVATION_FAILURE:
      return {
        ...state,
        isWaiting: false,
        errorTokenActivation: action.error,
      };
    case GET_PROFILE_REQUEST:
      return { ...state, isGetWaiting: true, isWaiting: true, error: initialState.error };
    case GET_PROFILE_SUCCESS: {
      return {
        ...state,
        payload: action.payload,
        isWaiting: false,
        isGetWaiting: false,
        error: initialState.error,
        lastRefresh: moment(),
      };
    }
    case GET_PROFILE_CACHED:
      return {
        ...state,
        isWaiting: false,
        isGetWaiting: false,
        error: initialState.error,
      };
    case PATCH_AVATAR_REQUEST:
      return {
        ...state,
        isWaiting: true,
        errorAvatar: initialState.errorAvatar,
      };
    case PATCH_AVATAR_SUCCESS:
      return {
        ...state,
        payload: {
          ...state.payload,
          ...action.payload,
        },
        isWaiting: false,
        errorAvatar: initialState.errorAvatar,
        lastRefresh: moment(),
      };
    case PATCH_AVATAR_FAILURE:
      return {
        ...state,
        isWaiting: false,
        errorAvatar: action.error,
      };
    case DELETE_AVATAR_REQUEST:
      return {
        ...state,
        isWaiting: true,
        errorAvatar: initialState.errorAvatar,
      };
    case DELETE_AVATAR_SUCCESS:
      return {
        ...state,
        isWaiting: false,
        errorAvatar: initialState.errorAvatar,
        lastRefresh: null,
      };
    case DELETE_AVATAR_FAILURE:
      return {
        ...state,
        isWaiting: false,
        errorAvatar: action.error,
      };
    case GET_PROFILE_FAILURE:
      return { ...state, error: action.error, isGetWaiting: false, isWaiting: false };
    case PATCH_PROFILE_REQUEST:
      return { ...state, isWaiting: true, errorProfile: initialState.errorProfile };
    case PATCH_PROFILE_SUCCESS:
      return {
        ...state,
        payload: {
          ...state.payload,
          ...action.payload,
        },
        isWaiting: false,
        errorProfile: initialState.errorProfile,
      };
    case PATCH_PROFILE_FAILURE:
      return { ...state, errorProfile: action.error, isWaiting: false };
    case PATCH_LOCALIZATION_FAILURE:
      return { ...state, errorLocalization: action.error, isWaiting: false };
    case PATCH_LOCALIZATION_REQUEST:
      return { ...state, isWaiting: true, errorLocalization: initialState.errorLocalization };
    case PATCH_LOCALIZATION_SUCCESS:
      return {
        ...state,
        payload: {
          ...state.payload,
          ...action.payload,
        },
        isWaiting: false,
        errorLocalization: initialState.errorLocalization,
      };
    case SET_LOCAL_AUTH:
      return { ...state, localAuth: action.payload };
    case AUTH_SIGNIN_SUCCESS:
      return { ...state, isGetWaiting: true };
    case AUTH_SIGNED_SUCCESS:
      return { ...state, isGetWaiting: true };
    case AUTH_SIGNOUT_SUCCESS:
      return { ...initialState };
    case POST_NEWSLETTER_REQUEST:
      return { ...state, isWaiting: true, errorNotification: initialState.errorNotification };
    case POST_NEWSLETTER_SUCCESS:
      return {
        ...state,
        isWaiting: false,
        payload: {
          ...state.payload,
          ...action.payload,
        },
        errorNotification: initialState.errorNotification,
      };
    case POST_NEWSLETTER_FAILURE:
      return { ...state, isWaiting: false, errorNotification: action.error };
    case DELETE_NEWSLETTER_REQUEST:
      return { ...state, isWaiting: true, errorNotification: initialState.errorNotification };
    case DELETE_NEWSLETTER_SUCCESS:
      return {
        ...state,
        isWaiting: false,
        payload: {
          ...state.payload,
          ...action.payload,
        },
        errorNotification: initialState.errorNotification,
      };
    case DELETE_NEWSLETTER_FAILURE:
      return { ...state, isWaiting: false, errorNotification: action.error };
    default:
      return state;
  }
}
