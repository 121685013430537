/* eslint-disable no-nested-ternary */
import {
  SET_LANGUAGE,
  GET_PROFILE_SUCCESS,
  AUTH_SIGNOUT_SUCCESS,
  SET_USER_IMPERSONATION,
  PATCH_LOCALIZATION_SUCCESS,
} from '../action/actionTypes';

const initialState = {
  languageCode: '',
  formatDate: 'YYYY-MM-DD',
  currency: 'usd',
  selectByFilter: [
    { label: 'Last 30 days', value: '30-days' },
    { label: 'Previous week', value: 'prev-week' },
    { label: 'This week', value: 'week' },
    { label: 'Previous month', value: 'prev-month' },
    { label: 'This month', value: 'month' },
    { label: 'Previous quarter', value: 'prev-quarter' },
    { label: 'This quarter', value: 'quarter' },
    { label: 'This year', value: 'year' },
    { label: 'Previous year', value: 'prev-year' },
    { label: 'Custom', value: 'custom' },
  ],
  otherFilter: [
    {
      label: 'Title & ISBN',
      color: 'pie_0_2',
      endpoint: 'items',
      params: 'q[generic_i_cont]',
      valueKey: 'item_id',
      value: 'id',
      labelKey: 'title',
      labelKey2: 'isbn',
      locale: false,
      type: 'title_search',
      position: 1,
      showSearch: true,
    },
    {
      label: 'Publisher',
      color: 'pie_1_0',
      endpoint: 'publishers',
      params: 'q[name_i_cont]',
      valueKey: 'publisher',
      value: 'id',
      labelKey: 'name',
      locale: false,
      type: 'publisher_search',
      position: 2,
      showSearch: true,
    },
    { label: 'Author', valueKey: 'authors', color: 'pie_2_0', type: 'authors', position: 3 },
    {
      label: 'Genre',
      color: 'pie_1_4',
      endpoint: 'categories',
      params: 'q[name_i_cont]',
      valueKey: 'main_category_search.code',
      value: 'code',
      labelKey: 'name_localized',
      locale: true,
      type: 'main_category_search',
      position: 4,
      showSearch: true,
    },
    {
      label: 'Language',
      color: 'pie_2_2',
      endpoint: 'languages',
      params: 'q[name_i_cont]',
      valueKey: 'language.[lang]',
      labelKey: 'name_localized',
      value: 'name',
      locale: true,
      type: 'language',
      position: 5,
      showSearch: true,
    },
    {
      label: 'Collection',
      color: 'pie_2_5',
      endpoint: 'collections',
      params: 'q[name_i_cont]',
      valueKey: 'main_collection_id',
      value: 'id',
      labelKey: 'name',
      locale: false,
      type: 'collections',
      position: 6,
      showSearch: true,
    },
    {
      label: 'Country',
      color: 'pie_1_2',
      endpoint: 'countries',
      params: 'q[name_i_cont]',
      valueKey: 'country.code',
      value: 'alpha2',
      labelKey: 'name_localized',
      locale: true,
      type: 'country',
      position: 7,
      showSearch: true,
    },

    {
      label: 'Store',
      color: 'pie_2_4',
      endpoint: 'stores',
      params: 'q[description_i_cont]',
      valueKey: 'store_id',
      value: 'id',
      labelKey: 'name',
      locale: false,
      type: 'store_name',
      position: 8,
      showSearch: true,
    },

    {
      label: 'Format',
      color: 'pie_0_4',
      endpoint: 'item_types',
      params: 'q[name_i_cont]',
      valueKey: 'item_type_id',
      value: 'id',
      labelKey: 'description',
      locale: false,
      type: 'item_type',
      position: 9,
      showSearch: false,
    },

    {
      label: 'business model',
      color: 'pie_2_5',
      endpoint: 'order_types',
      valueKey: 'order_type',
      value: 'name',
      labelKey: 'description',
      locale: false,
      type: 'order_type',
      position: 10,
      showSearch: false,
    },
    {
      label: 'InternalID',
      valueKey: 'streetlib_internal_id',
      color: 'pie_1_0',
      isAdmin: true,
      type: 'streetlib_internal_id',
      position: 12,
    },
    {
      label: 'Multi ISBN',
      valueKey: 'item_id',
      color: 'lime_300',
      type: 'isbn',
      position: 11,
    },
    {
      label: 'streetLib company',
      valueKey: 'company.billing.code',
      color: 'pie_0_2',
      isAdmin: true,
      type: 'company.billing.code',
      position: 13,
    },
  ],
};

export default function config(state = initialState, action) {
  switch (action.type) {
    case SET_LANGUAGE:
      return { ...state, languageCode: action.payload.language };
    case GET_PROFILE_SUCCESS: {
      const currency =
        action.payload.company && action.payload.company.code === 'SL_US' ? 'usd' : 'eur';

      return {
        ...state,
        languageCode:
          action.payload.language === 'it'
            ? 'it'
            : action.payload.language === 'pt'
            ? 'pt'
            : action.payload.language === 'de'
            ? 'de'
            : 'en',
        currency,
      };
    }
    case PATCH_LOCALIZATION_SUCCESS: {
      const currency =
        action.payload.company && action.payload.company.code === 'SL_US' ? 'usd' : 'eur';

      return {
        ...state,
        languageCode:
          action.payload.language === 'it'
            ? 'it'
            : action.payload.language === 'pt'
            ? 'pt'
            : action.payload.language === 'de'
            ? 'de'
            : 'en',
        currency,
      };
    }
    case AUTH_SIGNOUT_SUCCESS:
      return {
        ...initialState,
      };
    case SET_USER_IMPERSONATION:
      return { ...initialState };
    default:
      return state;
  }
}
