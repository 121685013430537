import {
  GET_TAXINTERVIEWS_REQUEST,
  GET_TAXINTERVIEWS_SUCCESS,
  GET_TAXINTERVIEWS_FAILURE,
  POST_TAXINTERVIEW_REQUEST,
  POST_TAXINTERVIEW_SUCCESS,
  POST_TAXINTERVIEW_FAILURE,
  PATCH_TAXINTERVIEW_REQUEST,
  PATCH_TAXINTERVIEW_SUCCESS,
  PATCH_TAXINTERVIEW_FAILURE,
  DOWNLOAD_TAXINTERVIEW_REQUEST,
  DOWNLOAD_TAXINTERVIEW_SUCCESS,
  DOWNLOAD_TAXINTERVIEW_FAILURE,
  GET_WITHHOLDING_CODE_REQUEST,
  GET_WITHHOLDING_CODE_SUCCESS,
  GET_WITHHOLDING_CODE_CACHE,
  GET_WITHHOLDING_CODE_FAILURE,
} from '../action/actionTypes';

const initialState = {
  payload: {},
  withholdingCode: {},
  error: '',
  errors: null,
  isGetWaiting: false,
  isPostWaiting: false,
  isPatchWaiting: false,
  isDownloadWaiting: false,
  isWithholdingWaiting: false,
};

export default function taxInterviews(state = initialState, action) {
  switch (action.type) {
    case GET_TAXINTERVIEWS_REQUEST:
      return {
        ...state,
        isGetWaiting: true,
        error: initialState.error,
      };
    case GET_TAXINTERVIEWS_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        isGetWaiting: false,
        error: initialState.error,
      };
    case GET_TAXINTERVIEWS_FAILURE:
      return {
        ...state,
        error: action.error,
        isGetWaiting: false,
      };
    case POST_TAXINTERVIEW_REQUEST:
      return {
        ...state,
        isPostWaiting: true,
        errors: initialState.error,
      };
    case POST_TAXINTERVIEW_SUCCESS:
      return {
        ...state,
        isPostWaiting: false,
        errors: initialState.errors,
      };
    case POST_TAXINTERVIEW_FAILURE:
      return {
        ...state,
        errors: action.error,
        isPostWaiting: false,
      };
    case PATCH_TAXINTERVIEW_REQUEST:
      return {
        ...state,
        isPatchWaiting: true,
        errors: initialState.errors,
      };
    case PATCH_TAXINTERVIEW_SUCCESS:
      return {
        ...state,
        isPatchWaiting: false,
        errors: initialState.errors,
      };
    case PATCH_TAXINTERVIEW_FAILURE:
      return {
        ...state,
        errors: action.error,
        isPatchWaiting: false,
      };
    case DOWNLOAD_TAXINTERVIEW_REQUEST:
      return {
        ...state,
        isDownloadWaiting: true,
        error: initialState.error,
      };
    case DOWNLOAD_TAXINTERVIEW_SUCCESS:
      return {
        ...state,
        isDownloadWaiting: false,
        error: initialState.error,
      };
    case DOWNLOAD_TAXINTERVIEW_FAILURE:
      return {
        ...state,
        error: action.error,
        isDownloadWaiting: false,
      };
    case GET_WITHHOLDING_CODE_REQUEST:
      return {
        ...state,
        isWithholdingWaiting: true,
        error: initialState.error,
      };
    case GET_WITHHOLDING_CODE_SUCCESS:
      return {
        ...state,
        withholdingCode: action.payload,
        isWithholdingWaiting: false,
        error: initialState.error,
      };
    case GET_WITHHOLDING_CODE_CACHE:
      return {
        ...state,
        isWithholdingWaiting: false,
        error: initialState.error,
      };
    case GET_WITHHOLDING_CODE_FAILURE:
      return {
        ...state,
        error: action.error,
        isWithholdingWaiting: false,
      };
    default:
      return state;
  }
}
