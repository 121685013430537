import moment from 'moment';
import {
  GET_NEWS_REQUEST,
  GET_NEWS_SUCCESS,
  GET_NEWS_FAILURE,
  GET_NEWS_CACHED,
} from '../action/actionTypes';

const initialState = {
  payload: {},
  error: '',
  isWaiting: false,
  lastRefresh: null,
};

export default function medium(state = initialState, action) {
  switch (action.type) {
    case GET_NEWS_REQUEST:
      return {
        ...state,
        isWaiting: true,
        error: initialState.error,
      };
    case GET_NEWS_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        isWaiting: false,
        lastRefresh: moment(),
        error: initialState.error,
      };
    case GET_NEWS_FAILURE:
      return {
        ...state,
        error: action.error,
        isWaiting: false,
      };
    case GET_NEWS_CACHED:
      return {
        ...state,
        isWaiting: false,
        error: initialState.error,
      };
    default:
      return state;
  }
}
