import Router from 'next/router';
import { put, takeLatest, call, select, take } from 'redux-saga/effects';
import {
  getTaxInterviews,
  postTaxInterview,
  patchTaxInterview,
  downloadTaxInterview,
  getCountryWithheldDetails,
} from 'api';
import {
  AUTH_REFRESH_SUCCESS,
  AUTH_REFRESH_FAILURE,
  AUTH_REFRESH_SKIP,
  GET_TAXINTERVIEWS_REQUEST,
  POST_TAXINTERVIEW_REQUEST,
  PATCH_TAXINTERVIEW_REQUEST,
  POST_TAXINTERVIEW_SUCCESS,
  PATCH_TAXINTERVIEW_SUCCESS,
  DOWNLOAD_TAXINTERVIEW_REQUEST,
  GET_WITHHOLDING_CODE_REQUEST,
} from 'redux-api/action/actionTypes';
import { refreshRequest } from 'redux-api/action/auth';
import selectAuth from 'redux-api/reselect/auth';
import * as actions from 'redux-api/action/taxInterviews';
import { openFeedback } from 'redux-api/action/feedback';
import { downloadLink } from 'lib/create-download-link';
import translations from 'translations';

const selectProfile = (state) => state.profile.payload;
const selectConfig = (state) => state.config;
const selectWithholdingCode = (state) => state.taxInterviews.withholdingCode;

function* getTaxInterviewsSaga({ payload }) {
  const { languageCode } = yield select(selectConfig);

  try {
    // REFRESH TOKEN
    yield put(refreshRequest());
    const { type } = yield take([AUTH_REFRESH_SUCCESS, AUTH_REFRESH_FAILURE, AUTH_REFRESH_SKIP]);
    if (type === AUTH_REFRESH_FAILURE) throw new Error('401 - Unauthorized');

    // eslint-disable-next-line camelcase
    const { signInUserSession, adminRequestedStreetlibInternalId } = yield select(selectAuth);
    const { streetlib_billing_id } = yield select(selectProfile);

    const { accessToken } = signInUserSession;

    const page = payload && payload.page ? payload.page : '0';
    const pageSize = payload && payload.pageSize ? payload.pageSize : '10';

    const { data } = yield call(
      getTaxInterviews,
      languageCode,
      accessToken,
      adminRequestedStreetlibInternalId,
      streetlib_billing_id,
      page,
      pageSize,
    );

    return yield put(actions.getTaxInterviewsSuccess(data));
  } catch (err) {
    yield put(
      openFeedback({
        message: translations[languageCode].generic_retrieve_api_error,
        error: err,
      }),
    );

    return yield put(actions.getTaxInterviewsFailure(err));
  }
}

function* postTaxInterviewSaga({ payload }) {
  const { languageCode } = yield select(selectConfig);

  try {
    // REFRESH TOKEN
    yield put(refreshRequest());
    const { type } = yield take([AUTH_REFRESH_SUCCESS, AUTH_REFRESH_FAILURE, AUTH_REFRESH_SKIP]);
    if (type === AUTH_REFRESH_FAILURE) throw new Error('401 - Unauthorized');

    // eslint-disable-next-line camelcase
    const { signInUserSession, adminRequestedStreetlibInternalId } = yield select(selectAuth);
    const { streetlib_billing_id } = yield select(selectProfile);

    const { accessToken } = signInUserSession;

    const {
      data: {
        data: {
          id,
          attributes: { formDataErrors },
        },
      },
    } = yield call(
      postTaxInterview,
      languageCode,
      accessToken,
      adminRequestedStreetlibInternalId,
      streetlib_billing_id,
      payload.body,
    );

    if (formDataErrors) {
      const bodyKeys = Object.keys(payload.body.taxDocument.formData).filter(
        (k) => payload.body.taxDocument.formData[k] != null,
      );

      const formDataErrorsKeys = Object.keys(formDataErrors ?? {});

      const validErrorsKeys = [];

      formDataErrorsKeys.forEach((formDataErrorsKey) => {
        if (bodyKeys.includes(formDataErrorsKey)) {
          validErrorsKeys.push(formDataErrorsKey);
        }
      });

      if (validErrorsKeys.length > 0) {
        yield put(
          openFeedback({
            message: translations[languageCode].generic_send_api_error,
          }),
        );

        return yield put(
          actions.postTaxInterviewFailure({
            status: 'CUSTOM-ERROR',
            data: {
              errors: validErrorsKeys.map((key) => ({
                type: `:${key}`,
                detail: formDataErrors[key].join(', '),
              })),
            },
          }),
        );
      }
    }

    if (payload.callback) {
      payload.callback(id);
    }

    return yield put(actions.postTaxInterviewSuccess());
  } catch (err) {
    if (err.response) {
      yield put(
        openFeedback({
          message: err.response.data.errors[0].detail,
          error: err,
        }),
      );

      if (err.response.data.errors[0].title === 'year') {
        Router.push('/billing/profiles');
      }

      return yield put(actions.postTaxInterviewFailure(err.response));
    }

    yield put(actions.postTaxInterviewFailure(err));
    return yield put(
      openFeedback({
        message: translations[languageCode].generic_send_api_error,
        error: err,
      }),
    );
  }
}

function* patchTaxInterviewSaga({ payload }) {
  const { languageCode } = yield select(selectConfig);

  try {
    // REFRESH TOKEN
    yield put(refreshRequest());
    const { type } = yield take([AUTH_REFRESH_SUCCESS, AUTH_REFRESH_FAILURE, AUTH_REFRESH_SKIP]);
    if (type === AUTH_REFRESH_FAILURE) throw new Error('401 - Unauthorized');

    // eslint-disable-next-line camelcase
    const { signInUserSession, adminRequestedStreetlibInternalId } = yield select(selectAuth);
    const { streetlib_billing_id } = yield select(selectProfile);

    const { accessToken } = signInUserSession;

    const {
      data: {
        data: {
          attributes: { formDataErrors },
        },
      },
    } = yield call(
      patchTaxInterview,
      languageCode,
      accessToken,
      adminRequestedStreetlibInternalId,
      streetlib_billing_id,
      payload.pid,
      payload.body,
    );

    if (formDataErrors) {
      const bodyKeys = Object.keys(payload.body.taxDocument.formData).filter(
        (k) => payload.body.taxDocument.formData[k] != null,
      );
      const formDataErrorsKeys = Object.keys(formDataErrors ?? {});

      const validErrorsKeys = [];

      formDataErrorsKeys.forEach((formDataErrorsKey) => {
        if (bodyKeys.includes(formDataErrorsKey)) {
          validErrorsKeys.push(formDataErrorsKey);
        }
      });

      if (validErrorsKeys.length > 0) {
        yield put(
          openFeedback({
            message: translations[languageCode].generic_send_api_error,
          }),
        );

        return yield put(
          actions.patchTaxInterviewFailure({
            status: 'CUSTOM-ERROR',
            data: {
              errors: validErrorsKeys.map((key) => ({
                type: `:${key}`,
                detail: formDataErrors[key].join(', '),
              })),
            },
          }),
        );
      }
    }

    if (payload.callback) {
      payload.callback();
    }

    return yield put(actions.patchTaxInterviewSuccess());
  } catch (err) {
    if (err.response) {
      yield put(
        openFeedback({
          message: err.response.data.errors[0].detail,
          error: err,
        }),
      );

      return yield put(actions.patchTaxInterviewFailure(err.response));
    }

    yield put(actions.patchTaxInterviewFailure(err));
    return yield put(
      openFeedback({
        message: translations[languageCode].generic_send_api_error,
        error: err,
      }),
    );
  }
}

function* getWithHoldingCodeSaga({ payload }) {
  const { languageCode } = yield select(selectConfig);

  try {
    // REFRESH TOKEN
    yield put(refreshRequest());
    const { type } = yield take([AUTH_REFRESH_SUCCESS, AUTH_REFRESH_FAILURE, AUTH_REFRESH_SKIP]);
    if (type === AUTH_REFRESH_FAILURE) throw new Error('401 - Unauthorized');

    // eslint-disable-next-line camelcase
    const { signInUserSession } = yield select(selectAuth);
    const { accessToken } = signInUserSession;

    const { country } = yield select(selectWithholdingCode);

    if (country === payload.toLowerCase()) {
      return yield put(actions.getWithHoldingCodeCache());
    }

    const { data } = yield call(
      getCountryWithheldDetails,
      languageCode,
      accessToken,
      payload.toLowerCase(),
    );

    return yield put(
      actions.getWithHoldingCodeSuccess({ ...data, country: payload.toLowerCase() }),
    );
  } catch (err) {
    yield put(
      openFeedback({
        message: translations[languageCode].generic_retrieve_api_error,
        error: err,
      }),
    );

    return yield put(actions.getWithHoldingCodeFailure(err));
  }
}

function* downloadTaxInterviewSaga({ payload }) {
  const { languageCode } = yield select(selectConfig);

  try {
    // REFRESH TOKEN
    yield put(refreshRequest());
    const { type } = yield take([AUTH_REFRESH_SUCCESS, AUTH_REFRESH_FAILURE, AUTH_REFRESH_SKIP]);
    if (type === AUTH_REFRESH_FAILURE) throw new Error('401 - Unauthorized');

    // eslint-disable-next-line camelcase
    const { signInUserSession, adminRequestedStreetlibInternalId } = yield select(selectAuth);

    const { accessToken } = signInUserSession;
    const { streetlib_billing_id } = yield select(selectProfile);

    const {
      data: { response },
    } = yield call(
      downloadTaxInterview,
      languageCode,
      accessToken,
      adminRequestedStreetlibInternalId,
      streetlib_billing_id,
      payload,
    );

    if (!response) {
      yield put(
        openFeedback({
          message: translations[languageCode].generic_retrieve_api_error,
        }),
      );

      return yield put(actions.downloadTaxInterviewFailure());
    }

    downloadLink(response, `${payload.pid}.pdf`);

    return yield put(actions.downloadTaxInterviewSuccess());
  } catch (err) {
    yield put(
      openFeedback({
        message: translations[languageCode].generic_retrieve_api_error,
        error: err,
      }),
    );

    return yield put(actions.downloadTaxInterviewFailure(err));
  }
}

export default function* watchTaxInterviews() {
  yield takeLatest(GET_TAXINTERVIEWS_REQUEST, getTaxInterviewsSaga);
  yield takeLatest(POST_TAXINTERVIEW_REQUEST, postTaxInterviewSaga);
  yield takeLatest(PATCH_TAXINTERVIEW_REQUEST, patchTaxInterviewSaga);
  yield takeLatest(POST_TAXINTERVIEW_SUCCESS, getTaxInterviewsSaga);
  yield takeLatest(PATCH_TAXINTERVIEW_SUCCESS, getTaxInterviewsSaga);
  yield takeLatest(GET_WITHHOLDING_CODE_REQUEST, getWithHoldingCodeSaga);
  yield takeLatest(DOWNLOAD_TAXINTERVIEW_REQUEST, downloadTaxInterviewSaga);
}
