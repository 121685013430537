import * as types from './actionTypes';

export function getTaxDocumentsRequest(payload) {
  return {
    type: types.GET_TAXDOCUMENTS_REQUEST,
    payload,
  };
}

export function getTaxDocumentsSuccess(payload) {
  return {
    type: types.GET_TAXDOCUMENTS_SUCCESS,
    payload,
  };
}

export function getTaxDocumentsFailure(error) {
  return {
    type: types.GET_TAXDOCUMENTS_FAILURE,
    error,
  };
}

export function downloadTaxDocumentRequest(payload) {
  return {
    type: types.DOWNLOAD_TAXDOCUMENT_REQUEST,
    payload,
  };
}

export function downloadTaxDocumentSuccess(payload) {
  return {
    type: types.DOWNLOAD_TAXDOCUMENT_SUCCESS,
    payload,
  };
}

export function downloadTaxDocumentFailure(error) {
  return {
    type: types.DOWNLOAD_TAXDOCUMENT_FAILURE,
    error,
  };
}
