import { takeLatest } from 'redux-saga/effects';
import {
  CHANGE_ROUTE_SUCCESS,
  AUTH_SIGNOUT_SUCCESS,
  AUTH_SIGNIN_FAILURE,
  AUTH_REFRESH_FAILURE,
  AUTH_SIGNIN_SUCCESS,
  AUTH_SIGNED_FAILURE,
  APPROVE_INVOICE_DOCUMENT_SUCCESS,
} from 'redux-api/action/actionTypes';
import Router from 'next/router';

function sendRouteSaga(action) {
  const isServer = typeof window === 'undefined';

  if (!isServer && window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(JSON.stringify(action));
  }
}

function redirectToSignin() {
  const currentDomain = typeof window !== 'undefined' ? window.location.hostname : '';
  const signinUri = currentDomain.match(/bookrix.de/i) ? process.env.SIGNIN_URI_DE : process.env.SIGNIN_URI;
  const { href } = document.location;

  // Remove query string from current URL
  const urlWithoutQueryString = href.split('?')[0];

  document.location.href = `${signinUri}?redirect_uri=${urlWithoutQueryString}`;
}

function redirectToSignout() {
  const currentDomain = typeof window !== 'undefined' ? window.location.hostname : '';
  const signoutUri = currentDomain.match(/bookrix.de/i) ? process.env.SIGNOUT_URI_DE : process.env.SIGNOUT_URI;
  const { href } = document.location;

  // Remove query string from current URL
  const urlWithoutQueryString = href.split('?')[0];

  setTimeout(() => {
    document.location.href = `${signoutUri}?redirect_uri=${urlWithoutQueryString}`;
  }, 300);
}

function redirectToPage({ payload }) {
  Router.replace({
    pathname: payload && payload.pathname ? payload.pathname : '/',
    query: payload && payload.query ? payload.query : {},
  });
}

function redirectPassiveInvoice({ payload }) {
  const {
    invoice: {
      data: { id },
    },
  } = payload;

  Router.push({ pathname: '/billing/passive', query: { id } });
}

export default function* watchRoute() {
  yield takeLatest(CHANGE_ROUTE_SUCCESS, sendRouteSaga);
  yield takeLatest(AUTH_SIGNIN_SUCCESS, redirectToPage);
  yield takeLatest(AUTH_SIGNIN_FAILURE, redirectToSignin);
  yield takeLatest(AUTH_SIGNED_FAILURE, redirectToSignin);
  yield takeLatest(AUTH_SIGNOUT_SUCCESS, redirectToSignout);
  yield takeLatest(AUTH_REFRESH_FAILURE, redirectToSignout);
  yield takeLatest(APPROVE_INVOICE_DOCUMENT_SUCCESS, redirectPassiveInvoice);
}
