const formatCurrency = (locale, value) => {
  if (locale === 'eur' || locale === 'EUR') {
    return new Intl.NumberFormat('it-IT', {
      style: 'currency',
      currency: 'EUR',
    }).format(value);
  }

  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
};

const formatCurrencyWithLanguage = (locale, value) => {
  if (locale === 'eur' || locale === 'EUR') {
    return new Intl.NumberFormat('it-IT', {
      style: 'currency',
      currency: 'EUR',
    }).format(value);
  }

  return new Intl.NumberFormat('en-US', { style: 'currency', currency: locale }).format(value);
};

export default formatCurrency;
export { formatCurrencyWithLanguage };
