import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { space, color } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import { CSSTransition } from 'react-transition-group';
import Text from 'theme/text';
import Card from 'theme/card';
import Alert from 'theme/icons/alert';
import Close from 'theme/icons/close';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 24px;
  left: 8px;
  right: 8px;
  z-index: 999;

  ${({ open }) =>
    open &&
    css`
      visibility: visible;
      animation: fadein 0.5s, expand 0.5s 0.5s, stay 99999999995s 1s;
    `};

  ${({ open }) =>
    !open &&
    css`
      min-width: 350px;
    `};

  ${({ state }) =>
    state === 'exiting' &&
    css`
      visibility: visible;
      animation: shrink 0.5s 0.5s, fadeout 0.5s 0.5s;
    `};

  ${({ state }) => state === 'exited' && css``};

  @keyframes fadein {
    from {
      bottom: 2px;
      opacity: 0;
    }
    to {
      bottom: 30px;
      opacity: 1;
    }
  }
  @keyframes expand {
    from {
      opacity: 1;
      min-width: 50px;
    }
    to {
      opacity: 1;
      min-width: 350px;
    }
  }
  @keyframes stay {
    from {
      opacity: 1;
      min-width: 350px;
    }
    to {
      opacity: 1;
      min-width: 350px;
    }
  }
  @keyframes shrink {
    from {
      opacity: 1;
      min-width: 350px;
    }
    to {
      opacity: 1;
      min-width: 50px;
    }
  }
  @keyframes fadeout {
    from {
      min-width: 50px;
      bottom: 30px;
      opacity: 1;
    }
    to {
      min-width: 50px;
      bottom: 60px;
      opacity: 0;
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;

  & > .icon {
    color: ${themeGet('colors.red_400')};
  }

  & > .close {
    color: ${themeGet('colors.grey_600')};
  }

  ${space}
  ${color}
`;

function SnackBar({ open, message, onClose, timeout = 6000 }) {
  useEffect(() => {
    let timer = null;

    if (open) {
      timer = setTimeout(() => {
        onClose();
      }, timeout);
    }

    return () => clearTimeout(timer);
  }, [open, onClose, timeout]);

  return (
    <CSSTransition in={open} timeout={open ? 0 : 700} unmountOnExit>
      {(state) => (
        <Wrapper state={state} open={open}>
          <Card borderColor="red_400" bg="red_100" p="m">
            <Content>
              <span className="icon">
                <Alert />
              </span>
              <Text fontSize="h4" lineHeight="h4" fontWeight="medium" color="grey_600" mx="xs">
                {message}
              </Text>
              <button type="button" className="close" onClick={onClose}>
                <Close />
              </button>
            </Content>
          </Card>
        </Wrapper>
      )}
    </CSSTransition>
  );
}

export default SnackBar;
