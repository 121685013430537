import { CLOSE_FEEDBACK, OPEN_FEEDBACK } from '../action/actionTypes';

const initialState = {
  open: false,
  timeout: 6000,
  message: '',
  error: null
};

export default function feedback(state = initialState, action) {
  if (state.open) console.log('FEEDBACK', state);
  switch (action.type) {
    case CLOSE_FEEDBACK:
      return { ...state, open: false };
    case OPEN_FEEDBACK:
      return { ...state, ...action.payload, open: true };

    default:
      return state;
  }
}
