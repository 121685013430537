import * as types from './actionTypes';

export function getWarningRequest() {
  return {
    type: types.GET_WARNING_REQUEST,
  };
}

export function getWarningSuccess(payload) {
  return {
    type: types.GET_WARNING_SUCCESS,
    payload,
  };
}

export function getWarningFailure(error) {
  return {
    type: types.GET_WARNING_FAILURE,
    error,
  };
}

export function getWarningCached() {
  return {
    type: types.GET_WARNING_CACHED,
  };
}

export function setWarningVisible(payload) {
  return {
    type: types.SET_WARNING_VISIBLE,
    payload,
  };
}
