import React from 'react';
import styled from 'styled-components';
import { space, layout } from 'styled-system';

const Wrapper = styled('svg').withConfig({
  shouldForwardProp: (prop) => !['width'].includes(prop),
})`
  width: 18px;

  ${layout}
  ${space}
`;

function Alert({ style }) {
  return (
    <Wrapper {...style} viewBox="0 0 18 16" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.81818 9.81769H8.18182V6.54497H9.81818V9.81769ZM9.81818 13.0904H8.18182V11.4541H9.81818V13.0904ZM0 15.545H18L9 -0.000488281L0 15.545Z"
        fill="currentColor"
      />
    </Wrapper>
  );
}

export default Alert;
