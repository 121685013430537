import queryString from 'query-string';

const formatAdminParams = (params) => {
  const parsed = queryString.parse(params);

  if (parsed.filters) {
    let streetlibInternalId = null;
    let filters = parsed.filters.split(',');
    filters = filters.map((filter) => {
      const [ name, value ] = filter.split('=');
      if (name === 'streetlib_internal_id') streetlibInternalId = value;
      return filter;
    });

    filters = filters.filter((filter) => !filter.match(/streetlib_internal_id/));

    if (streetlibInternalId) parsed.streetlibInternalId = streetlibInternalId;

    filters = filters.join(',');

    parsed.filters = filters;
  }

  const formattedParams = queryString.stringify(parsed);

  return formattedParams
};

export default formatAdminParams;
