import moment from 'moment';
import {
  AUTH_SIGNIN_REQUEST,
  AUTH_SIGNIN_SUCCESS,
  AUTH_SIGNIN_FAILURE,
  AUTH_REFRESH_SUCCESS,
  AUTH_REFRESH_FAILURE,
  AUTH_SIGNOUT_SUCCESS,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  AUTH_UPDATE_TOKEN,
  SET_USER_IMPERSONATION,
  AUTH_SIGNED_SUCCESS,
  AUTH_SIGNED_FAILURE,
} from '../action/actionTypes';

const initialState = {
  payload: {},
  error: '',
  authState: 'LOADING',
  lastRefresh: null,
  isWaiting: false,
  adminRequestedStreetlibInternalId: '',
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case AUTH_SIGNED_SUCCESS:
      return {
        ...state,
        authState: 'SIGNEDIN',
        error: initialState.error,
        isWaiting: false,
      };
    case AUTH_SIGNED_FAILURE:
      return { ...state, isWaiting: false, payload: initialState.payload };
    case AUTH_SIGNIN_REQUEST:
      return { ...initialState };
    case AUTH_SIGNIN_SUCCESS:
      return {
        ...state,
        authState: 'SIGNEDIN',
        error: initialState.error,
        payload: action.payload,
        lastRefresh: moment(),
      };
    case AUTH_SIGNIN_FAILURE:
      return {
        ...state,
        error: action.payload,
        authState: 'SIGNEDOUT',
        payload: initialState.payload,
      };
    case CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        error: initialState.error,
        isWaiting: true,
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        error: initialState.error,
        isWaiting: false,
      };
    case CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.error,
        isWaiting: false,
      };
    case AUTH_SIGNOUT_SUCCESS:
      return {
        ...initialState,
      };
    case AUTH_REFRESH_SUCCESS:
      return {
        ...state,
        lastRefresh: moment(),
      };
    case AUTH_REFRESH_FAILURE:
      return {
        ...state,
        error: action.error,
        authState: 'SIGNEDOUT',
        payload: initialState.payload,
      };
    case AUTH_UPDATE_TOKEN:
      return {
        ...state,
        payload: {
          ...state.payload,
          signInUserSession: {
            ...state.payload.signInUserSession,
            ...action.payload,
          },
        },
      };
    case SET_USER_IMPERSONATION:
      return {
        ...state,
        adminRequestedStreetlibInternalId: action.payload,
      };
    default:
      return state;
  }
}
