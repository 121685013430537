import * as types from './actionTypes';

export function getHistoryRequest(payload) {
  return {
    type: types.GET_HISTORY_REQUEST,
    payload,
  };
}

export function getHistorySuccess(payload) {
  return {
    type: types.GET_HISTORY_SUCCESS,
    payload,
  };
}

export function getHistoryFailure(error) {
  return {
    type: types.GET_HISTORY_FAILURE,
    error,
  };
}

export function getHistoryCached() {
  return {
    type: types.GET_HISTORY_CACHED,
  };
}
