export const NATIVE_LOCAL_AUTH = 'NATIVE_LOCAL_AUTH';
export const NATIVE_USER_PROFILE = 'NATIVE_USER_PROFILE';

export const SET_LANGUAGE = 'SET_LANGUAGE';

// FEEDBACK
export const CLOSE_FEEDBACK = 'CLOSE_FEEDBACK';
export const OPEN_FEEDBACK = 'OPEN_FEEDBACK';

// AUTH
export const SET_LOCAL_AUTH = 'SET_LOCAL_AUTH';

export const GET_AUTH_STATE = 'GET_AUTH_STATE';

export const AUTH_SIGNIN_REQUEST = 'AUTH_SIGNIN_REQUEST';
export const AUTH_SIGNIN_SUCCESS = 'AUTH_SIGNIN_SUCCESS';
export const AUTH_SIGNIN_FAILURE = 'AUTH_SIGNIN_FAILURE';
export const AUTH_SIGNED_SUCCESS = 'AUTH_SIGNED_SUCCESS';
export const AUTH_SIGNED_FAILURE = 'AUTH_SIGNED_FAILURE';

export const AUTH_REFRESH_REQUEST = 'AUTH_REFRESH_REQUEST';
export const AUTH_REFRESH_SUCCESS = 'AUTH_REFRESH_SUCCESS';
export const AUTH_REFRESH_FAILURE = 'AUTH_REFRESH_FAILURE';
export const AUTH_REFRESH_SKIP = 'AUTH_REFRESH_SKIP';
export const AUTH_UPDATE_TOKEN = 'AUTH_UPDATE_TOKEN';
export const AUTH_REFRESH_TOKEN = 'AUTH_REFRESH_TOKEN';

export const AUTH_SIGNOUT_REQUEST = 'AUTH_SIGNOUT_REQUEST';
export const AUTH_SIGNOUT_SUCCESS = 'AUTH_SIGNOUT_SUCCESS';
export const AUTH_SIGNOUT_FAILURE = 'AUTH_SIGNOUT_FAILURE';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const SET_USER_IMPERSONATION = 'SET_USER_IMPERSONATION';

// PROFILE
export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAILURE = 'GET_PROFILE_FAILURE';
export const GET_PROFILE_CACHED = 'GET_PROFILE_CACHED';
export const PATCH_PROFILE_REQUEST = 'PATCH_PROFILE_REQUEST';
export const PATCH_PROFILE_SUCCESS = 'PATCH_PROFILE_SUCCESS';
export const PATCH_PROFILE_FAILURE = 'PATCH_PROFILE_FAILURE';
export const PATCH_LOCALIZATION_REQUEST = 'PATCH_LOCALIZATION_REQUEST';
export const PATCH_LOCALIZATION_SUCCESS = 'PATCH_LOCALIZATION_SUCCESS';
export const PATCH_LOCALIZATION_FAILURE = 'PATCH_LOCALIZATION_FAILURE';
export const PATCH_AVATAR_REQUEST = 'PATCH_AVATAR_REQUEST';
export const PATCH_AVATAR_SUCCESS = 'PATCH_AVATAR_SUCCESS';
export const PATCH_AVATAR_FAILURE = 'PATCH_AVATAR_FAILURE';
export const DELETE_AVATAR_REQUEST = 'DELETE_AVATAR_REQUEST';
export const DELETE_AVATAR_SUCCESS = 'DELETE_AVATAR_SUCCESS';
export const DELETE_AVATAR_FAILURE = 'DELETE_AVATAR_FAILURE';
export const POST_NEWSLETTER_REQUEST = 'POST_NEWSLETTER_REQUEST';
export const POST_NEWSLETTER_SUCCESS = 'POST_NEWSLETTER_SUCCESS';
export const POST_NEWSLETTER_FAILURE = 'POST_NEWSLETTER_FAILURE';
export const DELETE_NEWSLETTER_REQUEST = 'DELETE_NEWSLETTER_REQUEST';
export const DELETE_NEWSLETTER_SUCCESS = 'DELETE_NEWSLETTER_SUCCESS';
export const DELETE_NEWSLETTER_FAILURE = 'DELETE_NEWSLETTER_FAILURE';
export const POST_ACCEPT_CONTRACT_REQUEST = 'POST_ACCEPT_CONTRACT_REQUEST';
export const POST_ACCEPT_CONTRACT_SUCCESS = 'POST_ACCEPT_CONTRACT_SUCCESS';
export const POST_ACCEPT_CONTRACT_FAILURE = 'POST_ACCEPT_CONTRACT_FAILURE';

// BILLING
export const GET_BILLING_REQUEST = 'GET_BILLING_REQUEST';
export const GET_BILLING_SUCCESS = 'GET_BILLING_SUCCESS';
export const GET_BILLING_FAILURE = 'GET_BILLING_FAILURE';
export const GET_BILLING_CACHED = 'GET_BILLING_CACHED';
export const GET_BILLING_SUMMARY_REQUEST = 'GET_BILLING_SUMMARY_REQUEST';
export const GET_BILLING_SUMMARY_SUCCESS = 'GET_BILLING_SUMMARY_SUCCESS';
export const GET_BILLING_SUMMARY_FAILURE = 'GET_BILLING_SUMMARY_FAILURE';
export const GET_BILLING_DOCUMENTS_REQUEST = 'GET_BILLING_DOCUMENTS_REQUEST';
export const GET_BILLING_DOCUMENTS_SUCCESS = 'GET_BILLING_DOCUMENTS_SUCCESS';
export const GET_BILLING_DOCUMENTS_FAILURE = 'GET_BILLING_DOCUMENTS_FAILURE';
export const GET_BILLING_DOCUMENT_REQUEST = 'GET_BILLING_DOCUMENT_REQUEST';
export const GET_BILLING_DOCUMENT_SUCCESS = 'GET_BILLING_DOCUMENT_SUCCESS';
export const GET_BILLING_DOCUMENT_FAILURE = 'GET_BILLING_DOCUMENT_FAILURE';
export const GET_BILLING_DRAFT_DOCUMENT_REQUEST = 'GET_BILLING_DRAFT_DOCUMENT_REQUEST';
export const GET_BILLING_DRAFT_DOCUMENT_SUCCESS = 'GET_BILLING_DRAFT_DOCUMENT_SUCCESS';
export const GET_BILLING_DRAFT_DOCUMENT_FAILURE = 'GET_BILLING_DRAFT_DOCUMENT_FAILURE';
export const GET_BILLING_PROFILES_REQUEST = 'GET_BILLING_PROFILES_REQUEST';
export const GET_BILLING_PROFILES_SUCCESS = 'GET_BILLING_PROFILES_SUCCESS';
export const GET_BILLING_PROFILES_FAILURE = 'GET_BILLING_PROFILES_FAILURE';
export const POST_BILLING_PROFILE_REQUEST = 'POST_BILLING_PROFILE_REQUEST';
export const POST_BILLING_PROFILE_SUCCESS = 'POST_BILLING_PROFILE_SUCCESS';
export const POST_BILLING_PROFILE_FAILURE = 'POST_BILLING_PROFILE_FAILURE';
export const PATCH_BILLING_PROFILE_REQUEST = 'PATCH_BILLING_PROFILE_REQUEST';
export const PATCH_BILLING_PROFILE_SUCCESS = 'PATCH_BILLING_PROFILE_SUCCESS';
export const PATCH_BILLING_PROFILE_FAILURE = 'PATCH_BILLING_PROFILE_FAILURE';
export const DELETE_BILLING_PROFILE_REQUEST = 'DELETE_BILLING_PROFILE_REQUEST';
export const DELETE_BILLING_PROFILE_SUCCESS = 'DELETE_BILLING_PROFILE_SUCCESS';
export const DELETE_BILLING_PROFILE_FAILURE = 'DELETE_BILLING_PROFILE_FAILURE';
export const GET_PAYMENT_ACCOUNTS_REQUEST = 'GET_PAYMENT_ACCOUNTS_REQUEST';
export const GET_PAYMENT_ACCOUNTS_SUCCESS = 'GET_PAYMENT_ACCOUNTS_SUCCESS';
export const GET_PAYMENT_ACCOUNTS_FAILURE = 'GET_PAYMENT_ACCOUNTS_FAILURE';
export const POST_PAYMENT_ACCOUNT_REQUEST = 'POST_PAYMENT_ACCOUNT_REQUEST';
export const POST_PAYMENT_ACCOUNT_SUCCESS = 'POST_PAYMENT_ACCOUNT_SUCCESS';
export const POST_PAYMENT_ACCOUNT_FAILURE = 'POST_PAYMENT_ACCOUNT_FAILURE';
export const PATCH_PAYMENT_ACCOUNT_REQUEST = 'PATCH_PAYMENT_ACCOUNT_REQUEST';
export const PATCH_PAYMENT_ACCOUNT_SUCCESS = 'PATCH_PAYMENT_ACCOUNT_SUCCESS';
export const PATCH_PAYMENT_ACCOUNT_FAILURE = 'PATCH_PAYMENT_ACCOUNT_FAILURE';
export const DELETE_PAYMENT_ACCOUNT_REQUEST = 'DELETE_PAYMENT_ACCOUNT_REQUEST';
export const DELETE_PAYMENT_ACCOUNT_SUCCESS = 'DELETE_PAYMENT_ACCOUNT_SUCCESS';
export const DELETE_PAYMENT_ACCOUNT_FAILURE = 'DELETE_PAYMENT_ACCOUNT_FAILURE';
export const APPROVE_INVOICE_DOCUMENT_REQUEST = 'APPROVE_INVOICE_DOCUMENT_REQUEST';
export const APPROVE_INVOICE_DOCUMENT_SUCCESS = 'APPROVE_INVOICE_DOCUMENT_SUCCESS';
export const APPROVE_INVOICE_DOCUMENT_FAILURE = 'APPROVE_INVOICE_DOCUMENT_FAILURE';
export const DOWNLOAD_INVOICE_REQUEST = 'DOWNLOAD_INVOICE_REQUEST';
export const DOWNLOAD_INVOICE_SUCCESS = 'DOWNLOAD_INVOICE_SUCCESS';
export const DOWNLOAD_INVOICE_FAILURE = 'DOWNLOAD_INVOICE_FAILURE';
export const DOWNLOAD_REPORT_INVOICE_REQUEST = 'DOWNLOAD_REPORT_INVOICE_REQUEST';
export const DOWNLOAD_REPORT_INVOICE_SUCCESS = 'DOWNLOAD_REPORT_INVOICE_SUCCESS';
export const DOWNLOAD_REPORT_INVOICE_FAILURE = 'DOWNLOAD_REPORT_INVOICE_FAILURE';
export const CHECK_TIN_REQUEST = 'CHECK_TIN_REQUEST';
export const CHECK_TIN_SUCCESS = 'CHECK_TIN_SUCCESS';
export const CHECK_TIN_FAILURE = 'CHECK_TIN_FAILURE';
export const CHECK_COMPANY_TIN_REQUEST = 'CHECK_COMPANY_TIN_REQUEST';
export const CHECK_COMPANY_TIN_SUCCESS = 'CHECK_COMPANY_TIN_SUCCESS';
export const CHECK_COMPANY_TIN_FAILURE = 'CHECK_COMPANY_TIN_FAILURE';

// COUNT
export const GET_COUNT_REQUEST = 'GET_COUNT_REQUEST';
export const GET_COUNT_SUCCESS = 'GET_COUNT_SUCCESS';
export const GET_COUNT_FAILURE = 'GET_COUNT_FAILURE';
export const GET_COUNT_CACHED = 'GET_COUNT_CACHED';

// HISTOGRAM
export const GET_HISTOGRAM_REQUEST = 'GET_HISTOGRAM_REQUEST';
export const GET_HISTOGRAM_SUCCESS = 'GET_HISTOGRAM_SUCCESS';
export const GET_HISTOGRAM_FAILURE = 'GET_HISTOGRAM_FAILURE';
export const GET_HISTOGRAM_CACHED = 'GET_HISTOGRAM_CACHED';

// AGGR
export const GET_AGGR_REQUEST = 'GET_AGGR_REQUEST';
export const GET_AGGR_SUCCESS = 'GET_AGGR_SUCCESS';
export const GET_AGGR_FAILURE = 'GET_AGGR_FAILURE';
export const GET_AGGR_CACHED = 'GET_AGGR_CACHED';

// WILDCARD
export const GET_WILDCARD_REQUEST = 'GET_WILDCARD_REQUEST';
export const GET_WILDCARD_SUCCESS = 'GET_WILDCARD_SUCCESS';
export const GET_WILDCARD_FAILURE = 'GET_WILDCARD_FAILURE';
export const GET_WILDCARD_CACHED = 'GET_WILDCARD_CACHED';

// UNITS
export const GET_UNITS_REQUEST = 'GET_UNITS_REQUEST';
export const GET_UNITS_SUCCESS = 'GET_UNITS_SUCCESS';
export const GET_UNITS_FAILURE = 'GET_UNITS_FAILURE';
export const GET_MORE_UNITS_REQUEST = 'GET_MORE_UNITS_REQUEST';
export const GET_MORE_UNITS_SUCCESS = 'GET_MORE_UNITS_SUCCESS';
export const GET_MORE_UNITS_FAILURE = 'GET_MORE_UNITS_FAILURE';
export const GET_UNITS_CACHED = 'GET_UNITS_CACHED';

// FILTERS
export const CHANGE_COMPARE_FILTER = 'CHANGE_COMPARE_FILTER';
export const CHANGE_DATARANGE_FILTER = 'CHANGE_DATARANGE_FILTER';
export const CHANGE_DATATYPE_FILTER = 'CHANGE_DATATYPE_FILTER';
export const CHANGE_DATES_FILTER = 'CHANGE_DATES_FILTER';
export const CHANGE_INTERVAL_FILTER = 'CHANGE_INTERVAL_FILTER';
export const CHANGE_OTHERS_FILTER = 'CHANGE_OTHERS_FILTER';
export const CHANGE_TERMS_FILTER = 'CHANGE_TERMS_FILTER';
export const RESET_CHANGED = 'RESET_CHANGED';
export const RESET_FILTERS = 'RESET_FILTERS';
export const CHANGE_FILTERS = 'CHANGE_FILTERS';
export const COMPARETO_REQUEST = 'COMPARETO_REQUEST';
export const COMPARETO_SUCCESS = 'COMPARETO_SUCCESS';
export const COMPARETO_FAILURE = 'COMPARETO_FAILURE';
export const RELOAD_FILTERS = 'RELOAD_FILTERS';

// ROUTE
export const CHANGE_ROUTE_REQUEST = 'CHANGE_ROUTE_REQUEST';
export const CHANGE_ROUTE_SUCCESS = 'CHANGE_ROUTE_SUCCESS';
export const CHANGE_ROUTE_FAILURE = 'CHANGE_ROUTE_FAILURE';

// NOTIFICATION
export const CHANGE_NOTIFICATION_REQUEST = 'CHANGE_NOTIFICATION_REQUEST';
export const CHANGE_NOTIFICATION_SUCCESS = 'CHANGE_NOTIFICATION_SUCCESS';
export const CHANGE_NOTIFICATION_FAILURE = 'CHANGE_NOTIFICATION_FAILURE';

// REDIRECT
export const CHECK_REDIRECT_REQUEST = 'CHECK_REDIRECT_REQUEST';
export const CHECK_REDIRECT_SUCCESS = 'CHECK_REDIRECT_SUCCESS';
export const CHECK_REDIRECT_FAILURE = 'CHECK_REDIRECT_FAILURE';

// WARNING
export const GET_WARNING_REQUEST = 'GET_WARNING_REQUEST';
export const GET_WARNING_SUCCESS = 'GET_WARNING_SUCCESS';
export const GET_WARNING_FAILURE = 'GET_WARNING_FAILURE';
export const GET_WARNING_CACHED = 'GET_WARNING_CACHED';
export const SET_WARNING_VISIBLE = 'SET_WARNING_VISIBLE';

// NEWS
export const GET_NEWS_REQUEST = 'GET_NEWS_REQUEST';
export const GET_NEWS_SUCCESS = 'GET_NEWS_SUCCESS';
export const GET_NEWS_FAILURE = 'GET_NEWS_FAILURE';
export const GET_NEWS_CACHED = 'GET_NEWS_CACHED';

// HISTORY
export const GET_HISTORY_REQUEST = 'GET_HISTORY_REQUEST';
export const GET_HISTORY_SUCCESS = 'GET_HISTORY_SUCCESS';
export const GET_HISTORY_FAILURE = 'GET_HISTORY_FAILURE';
export const GET_HISTORY_CACHED = 'GET_HISTORY_CACHED';

// PRESET
export const GET_PRESET_REQUEST = 'GET_PRESET_REQUEST';
export const GET_PRESET_SUCCESS = 'GET_PRESET_SUCCESS';
export const GET_PRESET_FAILURE = 'GET_PRESET_FAILURE';
export const GET_PRESET_CACHED = 'GET_PRESET_CACHED';
export const ADD_PRESET_REQUEST = 'ADD_PRESET_REQUEST';
export const ADD_PRESET_SUCCESS = 'ADD_PRESET_SUCCESS';
export const ADD_PRESET_FAILURE = 'ADD_PRESET_FAILURE';
export const PUT_PRESET_REQUEST = 'PUT_PRESET_REQUEST';
export const PUT_PRESET_SUCCESS = 'PUT_PRESET_SUCCESS';
export const PUT_PRESET_FAILURE = 'PUT_PRESET_FAILURE';
export const DEL_PRESET_REQUEST = 'DEL_PRESET_REQUEST';
export const DEL_PRESET_SUCCESS = 'DEL_PRESET_SUCCESS';
export const DEL_PRESET_FAILURE = 'DEL_PRESET_FAILURE';

// COUNTRIES
export const GET_COUNTRIES_REQUEST = 'GET_COUNTRIES_REQUEST';
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';
export const GET_COUNTRIES_FAILURE = 'GET_COUNTRIES_FAILURE';
export const GET_COUNTRIES_CACHED = 'GET_COUNTRIES_CACHED';
export const GET_SUBDIVISIONS_REQUEST = 'GET_SUBDIVISIONS_REQUEST';
export const GET_SUBDIVISIONS_SUCCESS = 'GET_SUBDIVISIONS_SUCCESS';
export const GET_SUBDIVISIONS_FAILURE = 'GET_SUBDIVISIONS_FAILURE';

// TIMEZONES
export const GET_TIMEZONES_REQUEST = 'GET_TIMEZONES_REQUEST';
export const GET_TIMEZONES_SUCCESS = 'GET_TIMEZONES_SUCCESS';
export const GET_TIMEZONES_FAILURE = 'GET_TIMEZONES_FAILURE';
export const GET_TIMEZONES_CACHED = 'GET_TIMEZONES_CACHED';

// TOKEN ACTIVATION
export const TOKEN_ACTIVATION_REQUEST = 'TOKEN_ACTIVATION_REQUEST';
export const TOKEN_ACTIVATION_SUCCESS = 'TOKEN_ACTIVATION_SUCCESS';
export const TOKEN_ACTIVATION_FAILURE = 'TOKEN_ACTIVATION_FAILURE';

// CSV
export const TRANSACTION_CSV_REQUEST = 'TRANSACTION_CSV_REQUEST';
export const TRANSACTION_CSV_SUCCESS = 'TRANSACTION_CSV_SUCCESS';
export const TRANSACTION_CSV_FAILURE = 'TRANSACTION_CSV_FAILURE';
export const TRANSACTION_DOWNLOAD_JOB_REQUEST = 'TRANSACTION_DOWNLOAD_JOB_REQUEST';
export const TRANSACTION_DOWNLOAD_JOB_SUCCESS = 'TRANSACTION_DOWNLOAD_JOB_SUCCESS';
export const TRANSACTION_DOWNLOAD_JOB_FAILURE = 'TRANSACTION_DOWNLOAD_JOB_FAILURE';
export const TRANSACTION_DOWNLOAD_GET_REQUEST = 'TRANSACTION_DOWNLOAD_GET_REQUEST';
export const TRANSACTION_DOWNLOAD_GET_SUCCESS = 'TRANSACTION_DOWNLOAD_GET_SUCCESS';
export const TRANSACTION_DOWNLOAD_GET_FAILURE = 'TRANSACTION_DOWNLOAD_GET_FAILURE';
export const TRANSACTION_DOWNLOAD_GET_TIMEOUT = 'TRANSACTION_DOWNLOAD_GET_TIMEOUT';
export const TRANSACTION_DOWNLOAD_GET_RESET = 'TRANSACTION_DOWNLOAD_GET_RESET';
export const TOP_LINE_CSV_REQUEST = 'TOP_LINE_CSV_REQUEST';
export const TOP_LINE_CSV_SUCCESS = 'TOP_LINE_CSV_SUCCESS';
export const TOP_LINE_CSV_FAILURE = 'TOP_LINE_CSV_FAILURE';

// REPORT STATUSES
export const GET_REPORT_STATUSES_REQUEST = 'GET_REPORT_STATUSES_REQUEST';
export const GET_REPORT_STATUSES_SUCCESS = 'GET_REPORT_STATUSES_SUCCESS';
export const GET_REPORT_STATUSES_FAILURE = 'GET_REPORT_STATUSES_FAILURE';
export const GET_REPORT_STATUSES_CACHED = 'GET_REPORT_STATUSES_CACHED';

// STORES
export const GET_STORES_REQUEST = 'GET_STORES_REQUEST';
export const GET_STORES_SUCCESS = 'GET_STORES_SUCCESS';
export const GET_STORES_FAILURE = 'GET_STORES_FAILURE';
export const GET_STORES_CACHED = 'GET_STORES_CACHED';

// TAX DOCUMENTS
export const GET_TAXDOCUMENTS_REQUEST = 'GET_TAXDOCUMENTS_REQUEST';
export const GET_TAXDOCUMENTS_SUCCESS = 'GET_TAXDOCUMENTS_SUCCESS';
export const GET_TAXDOCUMENTS_FAILURE = 'GET_TAXDOCUMENTS_FAILURE';
export const DOWNLOAD_TAXDOCUMENT_REQUEST = 'DOWNLOAD_TAXDOCUMENT_REQUEST';
export const DOWNLOAD_TAXDOCUMENT_SUCCESS = 'DOWNLOAD_TAXDOCUMENT_SUCCESS';
export const DOWNLOAD_TAXDOCUMENT_FAILURE = 'DOWNLOAD_TAXDOCUMENT_FAILURE';

// TAX INTERVIEWS
export const GET_TAXINTERVIEWS_REQUEST = 'GET_TAXINTERVIEWS_REQUEST';
export const GET_TAXINTERVIEWS_SUCCESS = 'GET_TAXINTERVIEWS_SUCCESS';
export const GET_TAXINTERVIEWS_FAILURE = 'GET_TAXINTERVIEWS_FAILURE';
export const POST_TAXINTERVIEW_REQUEST = 'POST_TAXINTERVIEW_REQUEST';
export const POST_TAXINTERVIEW_SUCCESS = 'POST_TAXINTERVIEW_SUCCESS';
export const POST_TAXINTERVIEW_FAILURE = 'POST_TAXINTERVIEW_FAILURE';
export const PATCH_TAXINTERVIEW_REQUEST = 'PATCH_TAXINTERVIEW_REQUEST';
export const PATCH_TAXINTERVIEW_SUCCESS = 'PATCH_TAXINTERVIEW_SUCCESS';
export const PATCH_TAXINTERVIEW_FAILURE = 'PATCH_TAXINTERVIEW_FAILURE';
export const DOWNLOAD_TAXINTERVIEW_REQUEST = 'DOWNLOAD_TAXINTERVIEW_REQUEST';
export const DOWNLOAD_TAXINTERVIEW_SUCCESS = 'DOWNLOAD_TAXINTERVIEW_SUCCESS';
export const DOWNLOAD_TAXINTERVIEW_FAILURE = 'DOWNLOAD_TAXINTERVIEW_FAILURE';
export const GET_WITHHOLDING_CODE_REQUEST = 'GET_WITHHOLDING_CODE_REQUEST';
export const GET_WITHHOLDING_CODE_SUCCESS = 'GET_WITHHOLDING_CODE_SUCCESS';
export const GET_WITHHOLDING_CODE_CACHE = 'GET_WITHHOLDING_CODE_CACHE';
export const GET_WITHHOLDING_CODE_FAILURE = 'GET_WITHHOLDING_CODE_FAILURE';

// WITHDRAWALS
export const IS_WITHDRAW_AVAILABLE_REQUEST = 'IS_WITHDRAW_AVAILABLE_REQUEST';
export const IS_WITHDRAW_AVAILABLE_SUCCESS = 'IS_WITHDRAW_AVAILABLE_SUCCESS';
export const IS_WITHDRAW_AVAILABLE_FAILURE = 'IS_WITHDRAW_AVAILABLE_FAILURE';
export const GET_WITHDRAWALS_REQUEST = 'GET_WITHDRAWALS_REQUEST';
export const GET_WITHDRAWALS_SUCCESS = 'GET_WITHDRAWALS_SUCCESS';
export const GET_WITHDRAWALS_FAILURE = 'GET_WITHDRAWALS_FAILURE';
export const GET_LAST_WITHDRAWAL_REQUEST = 'GET_LAST_WITHDRAWAL_REQUEST';
export const GET_LAST_WITHDRAWAL_SUCCESS = 'GET_LAST_WITHDRAWAL_SUCCESS';
export const GET_LAST_WITHDRAWAL_FAILURE = 'GET_LAST_WITHDRAWAL_FAILURE';
export const POST_WITHDRAWAL_REQUEST = 'POST_WITHDRAWAL_REQUEST';
export const POST_WITHDRAWAL_SUCCESS = 'POST_WITHDRAWAL_SUCCESS';
export const POST_WITHDRAWAL_FAILURE = 'POST_WITHDRAWAL_FAILURE';

// PAYONEER
export const CHECK_PAYEE_ID_REQUEST = 'CHECK_PAYEE_ID_REQUEST';
export const CHECK_PAYEE_ID_SUCCESS = 'CHECK_PAYEE_ID_SUCCESS';
export const CHECK_PAYEE_ID_FAILURE = 'CHECK_PAYEE_ID_FAILURE';
export const POST_PAYONEER_REGISTRATION_REQUEST = 'POST_PAYONEER_REGISTRATION_REQUEST';
export const POST_PAYONEER_REGISTRATION_SUCCESS = 'POST_PAYONEER_REGISTRATION_SUCCESS';
export const POST_PAYONEER_REGISTRATION_FAILURE = 'POST_PAYONEER_REGISTRATION_FAILURE';
