import moment from 'moment';
import {
  GET_REPORT_STATUSES_REQUEST,
  GET_REPORT_STATUSES_SUCCESS,
  GET_REPORT_STATUSES_FAILURE,
  GET_REPORT_STATUSES_CACHED,
} from '../action/actionTypes';

const initialState = {
  payload: {},
  error: '',
  isWaiting: false,
  lastRefresh: null,
};

export default function reportStatuses(state = initialState, action) {
  switch (action.type) {
    case GET_REPORT_STATUSES_REQUEST:
      return {
        ...state,
        payload: {
          ...state.payload,
          [action.payload.key]: { ...state.payload[action.payload.key], ...action.payload },
        },
        isWaiting: true,
        error: initialState.error,
      };
    case GET_REPORT_STATUSES_SUCCESS:
      return {
        ...state,
        payload: {
          ...state.payload,
          [action.payload.key]: { ...action.payload, lastRefresh: moment() },
        },
        isWaiting: false,
        lastRefresh: moment(),
        error: initialState.error,
      };
    case GET_REPORT_STATUSES_FAILURE:
      return {
        ...state,
        error: action.error,
        isWaiting: false,
      };
    case GET_REPORT_STATUSES_CACHED:
      return {
        ...state,
        isWaiting: false,
        error: initialState.error,
      };
    default:
      return state;
  }
}
