import calculateRange from 'lib/calculate-range';
import {
  CHANGE_COMPARE_FILTER,
  CHANGE_DATARANGE_FILTER,
  CHANGE_DATATYPE_FILTER,
  CHANGE_DATES_FILTER,
  CHANGE_INTERVAL_FILTER,
  CHANGE_OTHERS_FILTER,
  CHANGE_TERMS_FILTER,
  RESET_CHANGED,
  RESET_FILTERS,
  CHANGE_FILTERS,
  SET_USER_IMPERSONATION,
  COMPARETO_REQUEST,
  COMPARETO_SUCCESS,
  COMPARETO_FAILURE,
  RELOAD_FILTERS,
} from 'redux-api/action/actionTypes';

const filterState = {
  compare: 'period',
  dataType: 'aggregated_data', // real_data (earning), temporary_data (sales) or usage_data (usage)
  dateRange: '30-days', // last 30 days
  dates: calculateRange('30-days'), // array [dateFrom, dateTo]
  others: {}, // filters=authors=king,store_name=google
  terms: {}, // terms=order_type=BUY
  interval: 'day', // day, week or month
};

const initialState = {
  ...filterState,
  isChanged: false,
  initialState: filterState,
  isWaiting: false,
  compareTo: {
    dateFrom: '',
    dateTo: '',
  },
  reloadChecked: false,
  error: {},
};

export default function filters(state = initialState, action) {
  switch (action.type) {
    case CHANGE_COMPARE_FILTER:
      return { ...state, compare: action.payload, isChanged: true };
    case CHANGE_DATARANGE_FILTER:
      return { ...state, dateRange: action.payload, isChanged: true };
    case CHANGE_DATATYPE_FILTER:
      return { ...state, dataType: action.payload, isChanged: true };
    case CHANGE_DATES_FILTER:
      return { ...state, dates: action.payload, isChanged: true };
    case CHANGE_OTHERS_FILTER:
      return { ...state, others: action.payload, isChanged: true };
    case CHANGE_TERMS_FILTER:
      return { ...state, terms: action.payload, isChanged: true };
    case CHANGE_INTERVAL_FILTER:
      return { ...state, interval: action.payload, isChanged: true };
    case RESET_CHANGED:
      return { ...state, isChanged: false };
    case RESET_FILTERS:
      return { ...initialState };
    case CHANGE_FILTERS:
      return { ...state, ...action.payload, isChanged: true };
    case COMPARETO_REQUEST:
      return {
        ...state,
        isWaiting: true,
      };
    case COMPARETO_SUCCESS:
      return {
        ...state,
        isWaiting: false,
        compareTo: action.payload,
      };
    case COMPARETO_FAILURE:
      return {
        ...state,
        isWaiting: false,
        error: action.error,
      };
    case SET_USER_IMPERSONATION:
      return { ...initialState };
    case RELOAD_FILTERS:
      return { ...state, reloadChecked: !state.reloadChecked };
    default:
      return state;
  }
}
