import moment from 'moment';
import { put, takeEvery, call, select, take } from 'redux-saga/effects';
import { getCount } from 'api';
import {
  AUTH_REFRESH_SUCCESS,
  AUTH_REFRESH_FAILURE,
  AUTH_REFRESH_SKIP,
  GET_COUNT_REQUEST,
} from 'redux-api/action/actionTypes';
import * as actions from 'redux-api/action/count';
import { refreshRequest } from 'redux-api/action/auth';
import selectAuth from 'redux-api/reselect/auth';
import { openFeedback } from 'redux-api/action/feedback';
import translations from 'translations';
import selectProfile from 'redux-api/reselect/profile';
import adminParams from '../../lib/admin-params';

const selectLastRefresh = (state, key) =>
  state.count.payload[key] ? state.count.payload[key].lastRefresh : null;

const selectConfig = (state) => state.config;

function* getCountSaga({ payload }) {
  const { languageCode } = yield select(selectConfig);

  try {
    // REFRESH TOKEN
    yield put(refreshRequest());
    const { type } = yield take([AUTH_REFRESH_SUCCESS, AUTH_REFRESH_FAILURE, AUTH_REFRESH_SKIP]);
    if (type === AUTH_REFRESH_FAILURE) throw new Error('401 - Unauthorized');

    // eslint-disable-next-line camelcase
    const { signInUserSession, adminRequestedStreetlibInternalId } = yield select(selectAuth);

    const { accessToken } = signInUserSession;

    let params = `${
      adminRequestedStreetlibInternalId
        ? `adminRequestedStreetlibInternalId=${adminRequestedStreetlibInternalId}&`
        : ''
    }${payload.query}`;

    const { isAdmin } = yield select(selectProfile);

    params = `${isAdmin ? `adminRequestedAccess=true&` : ''}${params}`;

    if (isAdmin) params = adminParams(params);

    const now = moment();
    const lastRefresh = yield select((state) => selectLastRefresh(state, payload.key));
    const maxCachedTime = process.env.CACHED_TIME;

    if (lastRefresh) {
      const diff = now.diff(lastRefresh, 'minutes');

      if (diff < maxCachedTime) {
        return yield put(actions.getCountCached());
      }
    }

    const { data } = yield call(getCount, accessToken, params);

    return yield put(actions.getCountSuccess({ ...payload, data }));
  } catch (err) {
    yield put(
      openFeedback({
        message: translations[languageCode].generic_retrieve_api_error,
        error: err
      }),
    );

    return yield put(actions.getCountFailure(err));
  }
}

export default function* watchCount() {
  yield takeEvery(GET_COUNT_REQUEST, getCountSaga);
}
