import * as types from './actionTypes';

export function getPresetRequest() {
  return {
    type: types.GET_PRESET_REQUEST,
  };
}

export function getPresetSuccess(payload) {
  return {
    type: types.GET_PRESET_SUCCESS,
    payload,
  };
}

export function getPresetFailure(error) {
  return {
    type: types.GET_PRESET_FAILURE,
    error,
  };
}

export function getPresetCached() {
  return {
    type: types.GET_PRESET_CACHED,
  };
}

export function addPresetRequest(payload) {
  return {
    type: types.ADD_PRESET_REQUEST,
    payload,
  };
}

export function addPresetSuccess(payload) {
  return {
    type: types.ADD_PRESET_SUCCESS,
    payload,
  };
}

export function addPresetFailure(error) {
  return {
    type: types.ADD_PRESET_FAILURE,
    error,
  };
}

export function putPresetRequest(payload) {
  return {
    type: types.PUT_PRESET_REQUEST,
    payload,
  };
}

export function putPresetSuccess(payload) {
  return {
    type: types.PUT_PRESET_SUCCESS,
    payload,
  };
}

export function putPresetFailure(error) {
  return {
    type: types.PUT_PRESET_FAILURE,
    error,
  };
}

export function delPresetRequest(payload) {
  return {
    type: types.DEL_PRESET_REQUEST,
    payload,
  };
}

export function delPresetSuccess() {
  return {
    type: types.DEL_PRESET_SUCCESS,
  };
}

export function delPresetFailure(error) {
  return {
    type: types.DEL_PRESET_FAILURE,
    error,
  };
}
