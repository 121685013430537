import {
  CHECK_REDIRECT_REQUEST,
  CHECK_REDIRECT_SUCCESS,
  CHECK_REDIRECT_FAILURE,
} from '../action/actionTypes';

const initialState = {
  isWaiting: true,
  error: '',
};

export default function redirect(state = initialState, action) {
  switch (action.type) {
    case CHECK_REDIRECT_REQUEST: {
      return { ...state, isWaiting: true, error: initialState.error };
    }
    case CHECK_REDIRECT_SUCCESS: {
      return { ...state, isWaiting: false, error: initialState.error };
    }
    case CHECK_REDIRECT_FAILURE: {
      return { ...state, isWaiting: false, error: initialState.error };
    }
    default:
      return state;
  }
}
