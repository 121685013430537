import * as types from './actionTypes';

export function getNewsRequest() {
  return {
    type: types.GET_NEWS_REQUEST,
  };
}

export function getNewsSuccess(payload) {
  return {
    type: types.GET_NEWS_SUCCESS,
    payload,
  };
}

export function getNewsFailure(error) {
  return {
    type: types.GET_NEWS_FAILURE,
    error,
  };
}

export function getNewsCached() {
  return {
    type: types.GET_NEWS_CACHED,
  };
}
