import * as types from './actionTypes';

export function transactionCsvRequest(payload) {
  return {
    type: types.TRANSACTION_CSV_REQUEST,
    payload,
  };
}

export function transactionCsvSuccess(payload) {
  return {
    type: types.TRANSACTION_CSV_SUCCESS,
    payload,
  };
}

export function transactionCsvFailure(error) {
  return {
    type: types.TRANSACTION_CSV_FAILURE,
    error,
  };
}

export function topLineCsvRequest(payload) {
  return {
    type: types.TOP_LINE_CSV_REQUEST,
    payload,
  };
}

export function topLineCsvSuccess(payload) {
  return {
    type: types.TOP_LINE_CSV_SUCCESS,
    payload,
  };
}

export function topLineCsvFailure(error) {
  return {
    type: types.TOP_LINE_CSV_FAILURE,
    error,
  };
}

export function transactionDownloadJobRequest(payload) {
  return {
    type: types.TRANSACTION_DOWNLOAD_JOB_REQUEST,
    payload,
  };
}

export function transactionDownloadJobSuccess(payload) {
  return {
    type: types.TRANSACTION_DOWNLOAD_JOB_SUCCESS,
    payload,
  };
}

export function transactionDownloadJobFailure(error) {
  return {
    type: types.TRANSACTION_DOWNLOAD_JOB_FAILURE,
    error,
  };
}

export function transactionDownloadGetRequest(payload) {
  return {
    type: types.TRANSACTION_DOWNLOAD_GET_REQUEST,
    payload,
  };
}

export function transactionDownloadGetSuccess(payload) {
  return {
    type: types.TRANSACTION_DOWNLOAD_GET_SUCCESS,
    payload,
  };
}

export function transactionDownloadGetFailure(error) {
  return {
    type: types.TRANSACTION_DOWNLOAD_GET_FAILURE,
    error,
  };
}

export function transactionDownloadGetTimeout() {
  return {
    type: types.TRANSACTION_DOWNLOAD_GET_TIMEOUT,
  };
}

export function resetDownloadGet() {
  return {
    type: types.TRANSACTION_DOWNLOAD_GET_RESET,
  };
}
