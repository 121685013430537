/* eslint-disable camelcase */
import moment from 'moment';
import { put, takeEvery, call, select } from 'redux-saga/effects';
import { getWarning } from 'api';
import { GET_WARNING_REQUEST } from 'redux-api/action/actionTypes';
import * as actions from 'redux-api/action/warning';
import { openFeedback } from 'redux-api/action/feedback';
import translations from 'translations';
import _ from 'lodash';

const selectConfig = (state) => state.config;
const selectLastRefresh = (state) => state.warning.lastRefresh;
const selectProfile = (state) => state.profile.payload;

function* getWarningSaga() {
  const { languageCode } = yield select(selectConfig);

  try {
    const now = moment();
    const lastRefresh = yield select(selectLastRefresh);
    const maxCachedTime = process.env.CACHED_TIME;

    if (lastRefresh) {
      const diff = now.diff(lastRefresh, 'minutes');

      if (diff < maxCachedTime) {
        return yield put(actions.getWarningCached());
      }
    }

    const { data } = yield call(getWarning);

    const { tokens: userTokens } = yield select(selectProfile);

    const userUpdate = _.find(userTokens, { action: 'user_update' });

    if (userUpdate) {
      data.push({
        id: userUpdate.action,
        data: {
          msg: {
            en: translations.en.user_need_update_notification,
            it: translations.it.user_need_update_notification,
          },
          level: null,
          panel_class: null,
        },
      });
    }

    return yield put(actions.getWarningSuccess(data));
  } catch (err) {
    yield put(
      openFeedback({
        message: translations[languageCode].generic_retrieve_api_error,
        error: err,
      }),
    );

    return yield put(actions.getWarningFailure(err));
  }
}

export default function* watchWarning() {
  yield takeEvery(GET_WARNING_REQUEST, getWarningSaga);
}
