import * as types from './actionTypes';

export function getCountriesRequest(payload) {
  return {
    type: types.GET_COUNTRIES_REQUEST,
    payload,
  };
}

export function getCountriesSuccess(payload) {
  return {
    type: types.GET_COUNTRIES_SUCCESS,
    payload,
  };
}

export function getCountriesFailure(error) {
  return {
    type: types.GET_COUNTRIES_FAILURE,
    error,
  };
}

export function getCountriesCached() {
  return {
    type: types.GET_COUNTRIES_CACHED,
  };
}

export function getSubdivisionsRequest(payload) {
  return {
    type: types.GET_SUBDIVISIONS_REQUEST,
    payload,
  };
}

export function getSubdivisionsSuccess(payload) {
  return {
    type: types.GET_SUBDIVISIONS_SUCCESS,
    payload,
  };
}

export function getSubdivisionsFailure(error) {
  return {
    type: types.GET_SUBDIVISIONS_FAILURE,
    error,
  };
}
