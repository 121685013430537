import * as types from './actionTypes';

export function getCountRequest(payload) {
  return {
    type: types.GET_COUNT_REQUEST,
    payload,
  };
}

export function getCountSuccess(payload) {
  return {
    type: types.GET_COUNT_SUCCESS,
    payload,
  };
}

export function getCountFailure(error) {
  return {
    type: types.GET_COUNT_FAILURE,
    error,
  };
}

export function getCountCached() {
  return {
    type: types.GET_COUNT_CACHED,
  };
}
