import {
  GET_TAXDOCUMENTS_REQUEST,
  GET_TAXDOCUMENTS_SUCCESS,
  GET_TAXDOCUMENTS_FAILURE,
} from '../action/actionTypes';

const initialState = {
  payload: {},
  error: '',
  isWaiting: false,
};

export default function taxDocuments(state = initialState, action) {
  switch (action.type) {
    case GET_TAXDOCUMENTS_REQUEST:
      return {
        ...state,
        isWaiting: true,
        error: initialState.error,
      };
    case GET_TAXDOCUMENTS_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        isWaiting: false,
        error: initialState.error,
      };
    case GET_TAXDOCUMENTS_FAILURE:
      return {
        ...state,
        error: action.error,
        isWaiting: false,
      };
    default:
      return state;
  }
}
