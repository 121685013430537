import moment from 'moment';
import {
  GET_PRESET_REQUEST,
  GET_PRESET_SUCCESS,
  GET_PRESET_FAILURE,
  GET_PRESET_CACHED,
  ADD_PRESET_REQUEST,
  ADD_PRESET_SUCCESS,
  ADD_PRESET_FAILURE,
  PUT_PRESET_REQUEST,
  PUT_PRESET_SUCCESS,
  PUT_PRESET_FAILURE,
  DEL_PRESET_REQUEST,
  DEL_PRESET_SUCCESS,
  DEL_PRESET_FAILURE,
  SET_USER_IMPERSONATION,
} from '../action/actionTypes';

const initialState = {
  payload: {},
  error: null,
  isWaiting: false,
  lastRefresh: null,
};

export default function preset(state = initialState, action) {
  switch (action.type) {
    case GET_PRESET_REQUEST:
      return {
        ...state,
        isWaiting: true,
        error: initialState.error,
      };
    case GET_PRESET_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        isWaiting: false,
        lastRefresh: moment(),
        error: initialState.error,
      };
    case GET_PRESET_FAILURE:
      return {
        ...state,
        error: action.error,
        isWaiting: false,
      };
    case GET_PRESET_CACHED:
      return {
        ...state,
        isWaiting: false,
        error: initialState.error,
      };
    case ADD_PRESET_REQUEST:
      return {
        ...state,
        isWaiting: true,
        error: initialState.error,
      };
    case ADD_PRESET_SUCCESS:
      return {
        ...state,
        isWaiting: false,
        error: initialState.error,
      };
    case ADD_PRESET_FAILURE:
      return {
        ...state,
        error: action.error,
        isWaiting: false,
      };
    case PUT_PRESET_REQUEST:
      return {
        ...state,
        isWaiting: true,
        error: initialState.error,
      };
    case PUT_PRESET_SUCCESS:
      return {
        ...state,
        isWaiting: false,
        error: initialState.error,
      };
    case PUT_PRESET_FAILURE:
      return {
        ...state,
        error: action.error,
        isWaiting: false,
      };
    case DEL_PRESET_REQUEST:
      return {
        ...state,
        isWaiting: true,
        error: initialState.error,
      };
    case DEL_PRESET_SUCCESS:
      return {
        ...state,
        isWaiting: false,
        error: initialState.error,
      };
    case DEL_PRESET_FAILURE:
      return {
        ...state,
        error: action.error,
        isWaiting: false,
      };
    case SET_USER_IMPERSONATION:
      return { ...initialState };
    default:
      return state;
  }
}
