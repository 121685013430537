/* eslint-disable camelcase */
import { put, takeLatest, takeEvery, call, select, take } from 'redux-saga/effects';
import { getSettings, putPreset } from 'api';
import {
  AUTH_REFRESH_SUCCESS,
  AUTH_REFRESH_FAILURE,
  AUTH_REFRESH_SKIP,
  GET_PRESET_REQUEST,
  ADD_PRESET_REQUEST,
  ADD_PRESET_SUCCESS,
  PUT_PRESET_REQUEST,
  PUT_PRESET_SUCCESS,
  DEL_PRESET_REQUEST,
  DEL_PRESET_SUCCESS,
} from 'redux-api/action/actionTypes';
import * as actions from 'redux-api/action/preset';
import { refreshRequest } from 'redux-api/action/auth';
import selectAuth from 'redux-api/reselect/auth';
import { openFeedback } from 'redux-api/action/feedback';
import translations from 'translations';

const selectConfig = (state) => state.config;

function* getPresetSaga() {
  const { languageCode } = yield select(selectConfig);

  try {
    // REFRESH TOKEN
    yield put(refreshRequest());
    const { type } = yield take([AUTH_REFRESH_SUCCESS, AUTH_REFRESH_FAILURE, AUTH_REFRESH_SKIP]);
    if (type === AUTH_REFRESH_FAILURE) throw new Error('401 - Unauthorized');

    // eslint-disable-next-line camelcase
    const { attributes, signInUserSession, adminRequestedStreetlibInternalId } = yield select(
      selectAuth,
    );

    const { accessToken } = signInUserSession;

    const { data } = yield call(
      getSettings,
      accessToken,
      attributes['custom:internal_id'],
      adminRequestedStreetlibInternalId,
    );

    return yield put(actions.getPresetSuccess(data));
  } catch (err) {
    yield put(
      openFeedback({
        message: translations[languageCode].generic_retrieve_api_error,
        error: err,
      }),
    );

    return yield put(actions.getPresetFailure(err));
  }
}

function* addPresetSaga({ payload }) {
  const { languageCode } = yield select(selectConfig);

  try {
    // REFRESH TOKEN
    yield put(refreshRequest());
    const { type } = yield take([AUTH_REFRESH_SUCCESS, AUTH_REFRESH_FAILURE, AUTH_REFRESH_SKIP]);
    if (type === AUTH_REFRESH_FAILURE) throw new Error('401 - Unauthorized');

    // eslint-disable-next-line camelcase
    const { attributes, signInUserSession, adminRequestedStreetlibInternalId } = yield select(
      selectAuth,
    );

    const { accessToken } = signInUserSession;

    const { data: getData } = yield call(
      getSettings,
      accessToken,
      attributes['custom:internal_id'],
      adminRequestedStreetlibInternalId,
    );

    const { settings } = getData;

    const presetSetting = settings.find((setting) => setting.code === 'PRESETS');

    const { id: settingId, user_settings } = presetSetting;

    let id = null;
    let data = null;
    let body = {};

    if (user_settings) {
      id = user_settings.id;
      data = user_settings.data;
    }

    const presets = data ? data.presets : [];

    const isExists = presets.find((preset) => preset.title === payload.preset.title);

    if (isExists) {
      payload.onError();
      return yield put(actions.addPresetFailure('duplicate'));
    }

    if (id) {
      body = {
        user: {
          settings: [
            {
              id: settingId,
              code: 'PRESETS',
              user_settings: {
                id,
                data: {
                  presets: [...presets, payload.preset],
                },
              },
            },
          ],
        },
      };
    } else {
      body = {
        user: {
          settings: [
            {
              id: settingId,
              code: 'PRESETS',
              user_settings: {
                data: {
                  presets: [...presets, payload.preset],
                },
              },
            },
          ],
        },
      };
    }

    yield call(
      putPreset,
      accessToken,
      attributes['custom:internal_id'],
      adminRequestedStreetlibInternalId,
      body,
    );

    payload.onSave();

    return yield put(actions.addPresetSuccess());
  } catch (err) {
    yield put(
      openFeedback({
        message: translations[languageCode].generic_send_api_error,
        error: err,
      }),
    );

    return yield put(actions.addPresetFailure(err));
  }
}

function* putPresetSaga({ payload }) {
  const { languageCode } = yield select(selectConfig);

  try {
    // REFRESH TOKEN
    yield put(refreshRequest());
    const { type } = yield take([AUTH_REFRESH_SUCCESS, AUTH_REFRESH_FAILURE, AUTH_REFRESH_SKIP]);
    if (type === AUTH_REFRESH_FAILURE) throw new Error('401 - Unauthorized');

    // eslint-disable-next-line camelcase
    const { attributes, signInUserSession, adminRequestedStreetlibInternalId } = yield select(
      selectAuth,
    );

    const { accessToken } = signInUserSession;

    const { data: getData } = yield call(
      getSettings,
      accessToken,
      attributes['custom:internal_id'],
      adminRequestedStreetlibInternalId,
    );

    const { settings } = getData;

    const presetSetting = settings.find((setting) => setting.code === 'PRESETS');

    const {
      id: settingId,
      user_settings: { id, data },
    } = presetSetting;

    const presets = data ? data.presets : [];

    const isExists = presets.find((preset) => preset.title === payload.preset.title);

    if (isExists) {
      return yield put(actions.putPresetFailure('duplicate'));
    }

    const filteredPresets = presets.filter((preset) => preset.title !== payload.presetId);

    const body = {
      user: {
        settings: [
          {
            id: settingId,
            code: 'PRESETS',
            user_settings: {
              id,
              data: {
                presets: [...filteredPresets, payload.preset],
              },
            },
          },
        ],
      },
    };

    yield call(
      putPreset,
      accessToken,
      attributes['custom:internal_id'],
      adminRequestedStreetlibInternalId,
      body,
    );

    return yield put(actions.putPresetSuccess());
  } catch (err) {
    yield put(
      openFeedback({
        message: translations[languageCode].generic_send_api_error,
        error: err,
      }),
    );

    return yield put(actions.putPresetFailure(err));
  }
}

function* delPresetSaga({ payload }) {
  const { languageCode } = yield select(selectConfig);

  try {
    // REFRESH TOKEN
    yield put(refreshRequest());
    const { type } = yield take([AUTH_REFRESH_SUCCESS, AUTH_REFRESH_FAILURE, AUTH_REFRESH_SKIP]);
    if (type === AUTH_REFRESH_FAILURE) throw new Error('401 - Unauthorized');

    // eslint-disable-next-line camelcase
    const { attributes, signInUserSession, adminRequestedStreetlibInternalId } = yield select(
      selectAuth,
    );

    const { accessToken } = signInUserSession;

    const { data: getData } = yield call(
      getSettings,
      accessToken,
      attributes['custom:internal_id'],
      adminRequestedStreetlibInternalId,
    );

    const { settings } = getData;

    const presetSetting = settings.find((setting) => setting.code === 'PRESETS');

    const {
      id: settingId,
      user_settings: { id, data },
    } = presetSetting;

    const presets = data ? data.presets : [];
    const presetsFiltered = presets.filter((preset) => preset.title !== payload);

    const body = {
      user: {
        settings: [
          {
            id: settingId,
            code: 'PRESETS',
            user_settings: {
              id,
              data: {
                presets: presetsFiltered,
              },
            },
          },
        ],
      },
    };

    yield call(
      putPreset,
      accessToken,
      attributes['custom:internal_id'],
      adminRequestedStreetlibInternalId,
      body,
    );

    return yield put(actions.delPresetSuccess());
  } catch (err) {
    yield put(
      openFeedback({
        message: translations[languageCode].generic_send_api_error,
        error: err,
      }),
    );

    return yield put(actions.delPresetFailure(err));
  }
}

export default function* watchPreset() {
  yield takeLatest(GET_PRESET_REQUEST, getPresetSaga);
  yield takeEvery(ADD_PRESET_REQUEST, addPresetSaga);
  yield takeEvery(PUT_PRESET_REQUEST, putPresetSaga);
  yield takeEvery(DEL_PRESET_REQUEST, delPresetSaga);
  yield takeLatest(ADD_PRESET_SUCCESS, getPresetSaga);
  yield takeLatest(PUT_PRESET_SUCCESS, getPresetSaga);
  yield takeLatest(DEL_PRESET_SUCCESS, getPresetSaga);
}
