import moment from 'moment';
import {
  GET_COUNTRIES_REQUEST,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_FAILURE,
  GET_COUNTRIES_CACHED,
  GET_SUBDIVISIONS_REQUEST,
  GET_SUBDIVISIONS_SUCCESS,
  GET_SUBDIVISIONS_FAILURE,
} from '../action/actionTypes';

const initialState = {
  payload: [],
  subdivisions: {},
  error: '',
  isWaiting: false,
  lastRefresh: null,
};

export default function countries(state = initialState, action) {
  switch (action.type) {
    case GET_COUNTRIES_REQUEST:
      return {
        ...state,
        isWaiting: true,
        error: initialState.error,
      };
    case GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        isWaiting: false,
        lastRefresh: moment(),
        error: initialState.error,
      };
    case GET_COUNTRIES_FAILURE:
      return {
        ...state,
        error: action.error,
        isWaiting: false,
      };
    case GET_COUNTRIES_CACHED:
      return {
        ...state,
        isWaiting: false,
        error: initialState.error,
      };
    case GET_SUBDIVISIONS_REQUEST:
      return {
        ...state,
        isWaiting: true,
        error: initialState.error,
      };
    case GET_SUBDIVISIONS_SUCCESS:
      return {
        ...state,
        subdivisions: action.payload,
        isWaiting: false,
        error: initialState.error,
      };
    case GET_SUBDIVISIONS_FAILURE:
      return {
        ...state,
        error: action.error,
        isWaiting: false,
      };
    default:
      return state;
  }
}
