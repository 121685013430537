import { put, takeLatest, call, select, take } from 'redux-saga/effects';
import {
  getBillingProfiles,
  getPaymentAccounts,
  getBillingDocuments,
  getPaymentAccount,
  getBillingProfile,
  getBillingSummary,
  getInvoice,
  patchBillingProfile,
  postBillingProfile,
  deleteBillingProfile,
  deletePaymentAccount,
  patchPaymentAccount,
  postPaymentAccount,
  approveInvoiceDocument,
  downloadInvoice,
  downloadReportInvoice,
  isWithrawAvailable,
  getLastWithdrawal,
  postWithdrawal,
  checkPayeeId,
  postPayoneerRegistration,
  checkTin,
} from 'api';
import {
  AUTH_REFRESH_SUCCESS,
  AUTH_REFRESH_FAILURE,
  AUTH_REFRESH_SKIP,
  GET_BILLING_SUMMARY_REQUEST,
  GET_BILLING_DOCUMENTS_REQUEST,
  GET_BILLING_DOCUMENT_REQUEST,
  GET_BILLING_DRAFT_DOCUMENT_REQUEST,
  GET_BILLING_PROFILES_REQUEST,
  POST_BILLING_PROFILE_REQUEST,
  POST_BILLING_PROFILE_SUCCESS,
  PATCH_BILLING_PROFILE_REQUEST,
  PATCH_BILLING_PROFILE_SUCCESS,
  DELETE_BILLING_PROFILE_SUCCESS,
  DELETE_BILLING_PROFILE_REQUEST,
  GET_PAYMENT_ACCOUNTS_REQUEST,
  DELETE_PAYMENT_ACCOUNT_REQUEST,
  DELETE_PAYMENT_ACCOUNT_SUCCESS,
  PATCH_PAYMENT_ACCOUNT_REQUEST,
  POST_PAYMENT_ACCOUNT_REQUEST,
  APPROVE_INVOICE_DOCUMENT_REQUEST,
  DOWNLOAD_INVOICE_REQUEST,
  DOWNLOAD_REPORT_INVOICE_REQUEST,
  IS_WITHDRAW_AVAILABLE_REQUEST,
  GET_LAST_WITHDRAWAL_REQUEST,
  POST_WITHDRAWAL_REQUEST,
  CHECK_PAYEE_ID_REQUEST,
  POST_PAYONEER_REGISTRATION_REQUEST,
  CHECK_TIN_REQUEST,
  CHECK_COMPANY_TIN_REQUEST,
} from 'redux-api/action/actionTypes';
import * as actions from 'redux-api/action/billing';
import { refreshRequest } from 'redux-api/action/auth';
import selectAuth from 'redux-api/reselect/auth';
import { openFeedback } from 'redux-api/action/feedback';
import { downloadLink } from 'lib/create-download-link';
import translations from 'translations';

const selectProfile = (state) => state.profile.payload;
const selectConfig = (state) => state.config;

function* getBillingSummarySaga() {
  const { languageCode } = yield select(selectConfig);

  try {
    // REFRESH TOKEN
    yield put(refreshRequest());
    const { type } = yield take([AUTH_REFRESH_SUCCESS, AUTH_REFRESH_FAILURE, AUTH_REFRESH_SKIP]);
    if (type === AUTH_REFRESH_FAILURE) throw new Error('401 - Unauthorized');

    // eslint-disable-next-line camelcase
    const { streetlib_billing_id } = yield select(selectProfile);
    const { signInUserSession, adminRequestedStreetlibInternalId } = yield select(selectAuth);

    const { accessToken } = signInUserSession;

    const {
      data: { data },
    } = yield call(
      getBillingSummary,
      accessToken,
      streetlib_billing_id,
      adminRequestedStreetlibInternalId,
    );

    return yield put(actions.getBillingSummarySuccess(data));
  } catch (err) {
    yield put(
      openFeedback({
        message: translations[languageCode].generic_retrieve_api_error,
        error: err,
      }),
    );

    return yield put(actions.getBillingSummaryFailure(err));
  }
}

function* getBillingDocumentsSaga({ payload }) {
  const { languageCode } = yield select(selectConfig);

  try {
    // REFRESH TOKEN
    yield put(refreshRequest());
    const { type } = yield take([AUTH_REFRESH_SUCCESS, AUTH_REFRESH_FAILURE, AUTH_REFRESH_SKIP]);
    if (type === AUTH_REFRESH_FAILURE) throw new Error('401 - Unauthorized');

    // eslint-disable-next-line camelcase
    const { streetlib_billing_id } = yield select(selectProfile);
    const { signInUserSession, adminRequestedStreetlibInternalId } = yield select(selectAuth);

    const { accessToken } = signInUserSession;

    const { data } = yield call(
      getBillingDocuments,
      accessToken,
      streetlib_billing_id,
      adminRequestedStreetlibInternalId,
      payload,
    );

    return yield put(actions.getBillingDocumentsSuccess(data));
  } catch (err) {
    yield put(
      openFeedback({
        message: translations[languageCode].generic_retrieve_api_error,
        error: err,
      }),
    );

    return yield put(actions.getBillingDocumentsFailure(err));
  }
}

function* getBillingDocumentSaga({ payload }) {
  const { languageCode } = yield select(selectConfig);

  try {
    // REFRESH TOKEN
    yield put(refreshRequest());
    const { type } = yield take([AUTH_REFRESH_SUCCESS, AUTH_REFRESH_FAILURE, AUTH_REFRESH_SKIP]);
    if (type === AUTH_REFRESH_FAILURE) throw new Error('401 - Unauthorized');

    // eslint-disable-next-line camelcase
    const { streetlib_billing_id } = yield select(selectProfile);
    const { signInUserSession, adminRequestedStreetlibInternalId } = yield select(selectAuth);

    const { accessToken } = signInUserSession;

    const { data: invoice } = yield call(
      getInvoice,
      languageCode,
      accessToken,
      streetlib_billing_id,
      adminRequestedStreetlibInternalId,
      payload,
    );

    const {
      data: {
        relationships: {
          billingProfile: {
            data: { id: billingProfileId },
          },
        },
      },
    } = invoice;

    let billingProfile = {};
    let paymentAccount = {};

    if (billingProfileId) {
      const { data } = yield call(
        getBillingProfile,
        languageCode,
        accessToken,
        streetlib_billing_id,
        billingProfileId,
        adminRequestedStreetlibInternalId,
      );

      billingProfile = data;

      const paymentAccountId =
        billingProfile?.data?.relationships?.paymentAccount?.data?.id ?? null;

      if (paymentAccountId) {
        const { data: paymentAccountData } = yield call(
          getPaymentAccount,
          languageCode,
          accessToken,
          streetlib_billing_id,
          paymentAccountId || null,
          adminRequestedStreetlibInternalId,
        );

        paymentAccount = paymentAccountData;
      }
    }

    return yield put(
      actions.getBillingDocumentSuccess({ invoice, billingProfile, paymentAccount }),
    );
  } catch (err) {
    if (err.response) {
      if (err.response.status === 500) {
        return yield put(actions.getBillingDocumentFailure(err.response.data.errors[0].detail));
      }
    }

    yield put(
      openFeedback({
        message: translations[languageCode].generic_retrieve_api_error,
        error: err,
      }),
    );

    return yield put(actions.getBillingDocumentFailure());
  }
}

function* getBillingDraftDocumentSaga({ payload }) {
  const { languageCode } = yield select(selectConfig);

  try {
    // REFRESH TOKEN
    yield put(refreshRequest());
    const { type } = yield take([AUTH_REFRESH_SUCCESS, AUTH_REFRESH_FAILURE, AUTH_REFRESH_SKIP]);
    if (type === AUTH_REFRESH_FAILURE) throw new Error('401 - Unauthorized');

    // eslint-disable-next-line camelcase
    const { streetlib_billing_id } = yield select(selectProfile);
    const { signInUserSession, adminRequestedStreetlibInternalId } = yield select(selectAuth);

    const { accessToken } = signInUserSession;

    let { billingProfilePid } = payload;

    if (!billingProfilePid) {
      const { data } = yield call(
        getBillingProfiles,
        languageCode,
        accessToken,
        streetlib_billing_id,
        adminRequestedStreetlibInternalId,
      );

      const defaultProfile = data.data.find(({ attributes }) => attributes.default);

      if (defaultProfile) {
        billingProfilePid = defaultProfile.id;
      } else {
        billingProfilePid = data.data[0].id;
      }
    }

    const { data: invoice } = yield call(
      getInvoice,
      languageCode,
      accessToken,
      streetlib_billing_id,
      adminRequestedStreetlibInternalId,
      payload.id,
      billingProfilePid,
    );

    let billingProfile = {};
    let paymentAccount = {};

    const { data } = yield call(
      getBillingProfile,
      languageCode,
      accessToken,
      streetlib_billing_id,
      billingProfilePid,
      adminRequestedStreetlibInternalId,
    );

    billingProfile = data;

    const {
      data: {
        relationships: {
          paymentAccount: {
            data: { id: paymentAccountId },
          },
        },
      },
    } = billingProfile;

    const { data: paymentAccountData } = yield call(
      getPaymentAccount,
      languageCode,
      accessToken,
      streetlib_billing_id,
      paymentAccountId,
      adminRequestedStreetlibInternalId,
    );

    paymentAccount = paymentAccountData;

    return yield put(
      actions.getBillingDraftDocumentSuccess({
        invoice: { ...invoice, billingProfilePid },
        billingProfile,
        paymentAccount,
      }),
    );
  } catch (err) {
    if (err.response) {
      if (err.response.status === 500) {
        return yield put(
          actions.getBillingDraftDocumentFailure(err.response.data.errors[0].detail),
        );
      }
    }

    yield put(
      openFeedback({
        message: translations[languageCode].generic_retrieve_api_error,
        error: err,
      }),
    );

    return yield put(actions.getBillingDraftDocumentFailure());
  }
}

function* getBillingProfilesSaga() {
  const { languageCode } = yield select(selectConfig);

  try {
    // REFRESH TOKEN
    yield put(refreshRequest());
    const { type } = yield take([AUTH_REFRESH_SUCCESS, AUTH_REFRESH_FAILURE, AUTH_REFRESH_SKIP]);
    if (type === AUTH_REFRESH_FAILURE) throw new Error('401 - Unauthorized');

    // eslint-disable-next-line camelcase
    const { streetlib_billing_id } = yield select(selectProfile);
    const { signInUserSession, adminRequestedStreetlibInternalId } = yield select(selectAuth);

    const { accessToken } = signInUserSession;

    const { data: billingProfiles } = yield call(
      getBillingProfiles,
      languageCode,
      accessToken,
      streetlib_billing_id,
      adminRequestedStreetlibInternalId,
    );

    const { data: paymentAccounts } = yield call(
      getPaymentAccounts,
      languageCode,
      accessToken,
      streetlib_billing_id,
      adminRequestedStreetlibInternalId,
    );

    yield put(actions.getBillingProfilesSuccess({ billingProfiles, paymentAccounts }));
  } catch (err) {
    yield put(
      openFeedback({
        message: translations[languageCode].generic_retrieve_api_error,
        error: err,
      }),
    );

    yield put(actions.getBillingProfilesFailure(err));
  }
}

function* postBillingProfileSaga({ payload: { body, callback } }) {
  const { languageCode } = yield select(selectConfig);

  try {
    // REFRESH TOKEN
    yield put(refreshRequest());
    const { type } = yield take([AUTH_REFRESH_SUCCESS, AUTH_REFRESH_FAILURE, AUTH_REFRESH_SKIP]);
    if (type === AUTH_REFRESH_FAILURE) throw new Error('401 - Unauthorized');

    // eslint-disable-next-line camelcase
    const { streetlib_billing_id } = yield select(selectProfile);
    const { signInUserSession, adminRequestedStreetlibInternalId } = yield select(selectAuth);

    const { accessToken } = signInUserSession;

    const {
      data: {
        data: {
          id,
          attributes: { validationActiveInvoiceErrors, validationPassiveInvoiceErrors },
        },
      },
    } = yield call(
      postBillingProfile,
      languageCode,
      accessToken,
      streetlib_billing_id,
      adminRequestedStreetlibInternalId,
      body,
    );

    if (validationActiveInvoiceErrors || validationPassiveInvoiceErrors) {
      const filteredPayload = Object.keys(body.billingProfile)
        .filter((k) => body.billingProfile[k] !== undefined)
        .reduce((a, k) => ({ ...a, [k]: body.billingProfile[k] }), {});

      const bodyKeys = Object.keys(filteredPayload);
      const errorPassiveKeys = Object.keys(validationPassiveInvoiceErrors ?? {});
      const errorActiveKeys = Object.keys(validationActiveInvoiceErrors ?? {});

      const validErrorsKeys = [];

      errorPassiveKeys.forEach((errorPassiveKey) => {
        if (bodyKeys.includes(errorPassiveKey)) {
          validErrorsKeys.push(errorPassiveKey);
        }
      });

      errorActiveKeys.forEach((errorActiveKey) => {
        if (bodyKeys.includes(errorActiveKey) && !validErrorsKeys.includes(errorActiveKey)) {
          validErrorsKeys.push(errorActiveKey);
        }
      });

      if (validErrorsKeys.length > 0) {
        return yield put(
          actions.postBillingProfileFailure({
            status: 'CUSTOM-ERROR',
            data: {
              errors: validErrorsKeys.map((key) => ({
                type: `:${key}`,
                detail:
                  validationActiveInvoiceErrors && validationActiveInvoiceErrors[key]
                    ? validationActiveInvoiceErrors[key].join(', ')
                    : validationPassiveInvoiceErrors[key].join(', '),
              })),
            },
          }),
        );
      }
    }

    if (callback) callback(id);

    return yield put(actions.postBillingProfileSuccess());
  } catch (err) {
    if (err.response) {
      yield put(
        openFeedback({
          message: err.response.data.errors[0].detail,
          error: err,
        }),
      );

      return yield put(actions.postBillingProfileFailure(err.response));
    }

    yield put(actions.postBillingProfileFailure(err));
    return yield put(
      openFeedback({
        message: translations[languageCode].generic_send_api_error,
        error: err,
      }),
    );
  }
}

function* patchBillingProfileSaga({ payload: { pid, body, callback } }) {
  const { languageCode } = yield select(selectConfig);
  try {
    // REFRESH TOKEN
    yield put(refreshRequest());
    const { type } = yield take([AUTH_REFRESH_SUCCESS, AUTH_REFRESH_FAILURE, AUTH_REFRESH_SKIP]);
    if (type === AUTH_REFRESH_FAILURE) throw new Error('401 - Unauthorized');

    // eslint-disable-next-line camelcase
    const { streetlib_billing_id } = yield select(selectProfile);
    const { signInUserSession, adminRequestedStreetlibInternalId } = yield select(selectAuth);

    const { accessToken } = signInUserSession;

    const {
      data: {
        data: {
          attributes: { validationActiveInvoiceErrors, validationPassiveInvoiceErrors },
        },
      },
    } = yield call(
      patchBillingProfile,
      languageCode,
      accessToken,
      streetlib_billing_id,
      adminRequestedStreetlibInternalId,
      pid,
      body,
    );

    if (validationActiveInvoiceErrors || validationPassiveInvoiceErrors) {
      const filteredPayload = Object.keys(body.billingProfile)
        .filter((k) => body.billingProfile[k] !== undefined)
        .reduce((a, k) => ({ ...a, [k]: body.billingProfile[k] }), {});

      const bodyKeys = Object.keys(filteredPayload);
      const errorPassiveKeys = Object.keys(validationPassiveInvoiceErrors ?? {});
      const errorActiveKeys = Object.keys(validationActiveInvoiceErrors ?? {});

      const validErrorsKeys = [];

      errorPassiveKeys.forEach((errorPassiveKey) => {
        if (bodyKeys.includes(errorPassiveKey)) {
          validErrorsKeys.push(errorPassiveKey);
        }
      });

      errorActiveKeys.forEach((errorActiveKey) => {
        if (bodyKeys.includes(errorActiveKey) && !validErrorsKeys.includes(errorActiveKey)) {
          validErrorsKeys.push(errorActiveKey);
        }
      });

      if (validErrorsKeys.length > 0) {
        return yield put(
          actions.patchBillingProfileFailure({
            status: 'CUSTOM-ERROR',
            data: {
              errors: validErrorsKeys.map((key) => ({
                type: `:${key}`,
                detail:
                  validationActiveInvoiceErrors && validationActiveInvoiceErrors[key]
                    ? validationActiveInvoiceErrors[key].join(', ')
                    : validationPassiveInvoiceErrors[key].join(', '),
              })),
            },
          }),
        );
      }
    }

    if (callback) callback();

    return yield put(actions.patchBillingProfileSuccess());
  } catch (err) {
    if (err.response) {
      yield put(
        openFeedback({
          message: err.response.data.errors[0].detail,
          error: err,
        }),
      );

      return yield put(actions.patchBillingProfileFailure(err.response));
    }

    yield put(actions.patchBillingProfileFailure(err));
    return yield put(
      openFeedback({
        message: translations[languageCode].generic_send_api_error,
        error: err,
      }),
    );
  }
}

function* deleteBillingProfileSaga({ payload }) {
  const { languageCode } = yield select(selectConfig);

  try {
    // REFRESH TOKEN
    yield put(refreshRequest());
    const { type } = yield take([AUTH_REFRESH_SUCCESS, AUTH_REFRESH_FAILURE, AUTH_REFRESH_SKIP]);
    if (type === AUTH_REFRESH_FAILURE) throw new Error('401 - Unauthorized');

    // eslint-disable-next-line camelcase
    const { streetlib_billing_id } = yield select(selectProfile);
    const { signInUserSession, adminRequestedStreetlibInternalId } = yield select(selectAuth);

    const { accessToken } = signInUserSession;

    yield call(
      deleteBillingProfile,
      languageCode,
      accessToken,
      streetlib_billing_id,
      adminRequestedStreetlibInternalId,
      payload,
    );

    yield put(actions.deleteBillingProfileSuccess());
  } catch (err) {
    if (err.response) {
      if (err.response.data.errors) {
        yield put(actions.deleteBillingProfileFailure(err.response.data.errors));

        yield put(
          openFeedback({
            message: err.response.data.errors[0].detail,
            error: err,
          }),
        );
      } else {
        yield put(actions.deleteBillingProfileFailure(err.response.data));

        yield put(
          openFeedback({
            message: translations[languageCode].generic_send_api_error,
            error: err,
          }),
        );
      }
    } else {
      yield put(actions.deleteBillingProfileFailure(err));

      yield put(
        openFeedback({
          message: translations[languageCode].generic_send_api_error,
          error: err,
        }),
      );
    }
  }
}

function* getPaymentAccountsSaga() {
  const { languageCode } = yield select(selectConfig);

  try {
    // REFRESH TOKEN
    yield put(refreshRequest());
    const { type } = yield take([AUTH_REFRESH_SUCCESS, AUTH_REFRESH_FAILURE, AUTH_REFRESH_SKIP]);
    if (type === AUTH_REFRESH_FAILURE) throw new Error('401 - Unauthorized');

    // eslint-disable-next-line camelcase
    const { streetlib_billing_id } = yield select(selectProfile);
    const { signInUserSession, adminRequestedStreetlibInternalId } = yield select(selectAuth);

    const { accessToken } = signInUserSession;

    const { data } = yield call(
      getPaymentAccounts,
      languageCode,
      accessToken,
      streetlib_billing_id,
      adminRequestedStreetlibInternalId,
    );

    yield put(actions.getPaymentAccountsSuccess(data));
  } catch (err) {
    yield put(
      openFeedback({
        message: translations[languageCode].generic_retrieve_api_error,
        error: err,
      }),
    );

    yield put(actions.getPaymentAccountsFailure(err));
  }
}

function* deletePaymentAccountSaga({ payload }) {
  const { languageCode } = yield select(selectConfig);

  try {
    // REFRESH TOKEN
    yield put(refreshRequest());
    const { type } = yield take([AUTH_REFRESH_SUCCESS, AUTH_REFRESH_FAILURE, AUTH_REFRESH_SKIP]);
    if (type === AUTH_REFRESH_FAILURE) throw new Error('401 - Unauthorized');

    // eslint-disable-next-line camelcase
    const { streetlib_billing_id } = yield select(selectProfile);
    const { signInUserSession, adminRequestedStreetlibInternalId } = yield select(selectAuth);

    const { accessToken } = signInUserSession;

    yield call(
      deletePaymentAccount,
      languageCode,
      accessToken,
      streetlib_billing_id,
      adminRequestedStreetlibInternalId,
      payload,
    );

    yield put(actions.deletePaymentAccountSuccess());
  } catch (err) {
    if (err.response) {
      if (err.response.data.errors) {
        yield put(actions.deletePaymentAccountFailure(err.response.data.errors[0]));

        yield put(
          openFeedback({
            message: err.response.data.errors[0].detail,
            error: err,
          }),
        );
      } else {
        yield put(actions.deletePaymentAccountFailure(err.response.data));
        yield put(
          openFeedback({
            message: translations[languageCode].generic_send_api_error,
            error: err,
          }),
        );
      }
    } else {
      yield put(actions.deletePaymentAccountFailure(err));
      yield put(
        openFeedback({
          message: translations[languageCode].generic_send_api_error,
          error: err,
        }),
      );
    }
  }
}

function* postPaymentAccountSaga({ payload: { billingProfileId, body, callback } }) {
  const { languageCode } = yield select(selectConfig);

  try {
    // REFRESH TOKEN
    yield put(refreshRequest());
    const { type } = yield take([AUTH_REFRESH_SUCCESS, AUTH_REFRESH_FAILURE, AUTH_REFRESH_SKIP]);
    if (type === AUTH_REFRESH_FAILURE) throw new Error('401 - Unauthorized');

    // eslint-disable-next-line camelcase
    const { streetlib_billing_id } = yield select(selectProfile);
    const { signInUserSession, adminRequestedStreetlibInternalId } = yield select(selectAuth);

    const { accessToken } = signInUserSession;

    const {
      data: {
        data: { id },
      },
    } = yield call(
      postPaymentAccount,
      languageCode,
      accessToken,
      streetlib_billing_id,
      adminRequestedStreetlibInternalId,
      body,
    );

    if (billingProfileId) {
      yield call(
        patchBillingProfile,
        languageCode,
        accessToken,
        streetlib_billing_id,
        adminRequestedStreetlibInternalId,
        billingProfileId,
        {
          billingProfile: {
            paymentAccountPid: id,
          },
        },
      );
    }

    if (callback) callback(id);

    return yield put(actions.postPaymentAccountSuccess());
  } catch (err) {
    if (err.response) {
      yield put(
        openFeedback({
          message: err.response.data.errors[0].detail,
          error: err,
        }),
      );

      return yield put(actions.postPaymentAccountFailure(err.response));
    }

    yield put(actions.postPaymentAccountFailure(err));
    return yield put(
      openFeedback({
        message: translations[languageCode].generic_send_api_error,
        error: err,
      }),
    );
  }
}

function* patchPaymentAccountSaga({ payload: { pid, body, callback } }) {
  const { languageCode } = yield select(selectConfig);
  try {
    // REFRESH TOKEN
    yield put(refreshRequest());
    const { type } = yield take([AUTH_REFRESH_SUCCESS, AUTH_REFRESH_FAILURE, AUTH_REFRESH_SKIP]);
    if (type === AUTH_REFRESH_FAILURE) throw new Error('401 - Unauthorized');

    // eslint-disable-next-line camelcase
    const { streetlib_billing_id } = yield select(selectProfile);
    const { signInUserSession, adminRequestedStreetlibInternalId } = yield select(selectAuth);

    const { accessToken } = signInUserSession;

    yield call(
      patchPaymentAccount,
      languageCode,
      accessToken,
      streetlib_billing_id,
      adminRequestedStreetlibInternalId,
      pid,
      body,
    );

    if (callback) callback();

    return yield put(actions.patchPaymentAccountSuccess());
  } catch (err) {
    if (err.response) {
      yield put(
        openFeedback({
          message: err.response.data.errors[0].detail,
          error: err,
        }),
      );

      return yield put(actions.patchPaymentAccountFailure(err.response));
    }

    yield put(actions.patchPaymentAccountFailure(err));
    return yield put(
      openFeedback({
        message: translations[languageCode].generic_send_api_error,
        error: err,
      }),
    );
  }
}

function* approveInvoiceDocumentSaga({ payload: { approveData, callback } }) {
  const { languageCode } = yield select(selectConfig);
  try {
    // REFRESH TOKEN
    yield put(refreshRequest());
    const { type } = yield take([AUTH_REFRESH_SUCCESS, AUTH_REFRESH_FAILURE, AUTH_REFRESH_SKIP]);
    if (type === AUTH_REFRESH_FAILURE) throw new Error('401 - Unauthorized');

    // eslint-disable-next-line camelcase
    const { streetlib_billing_id } = yield select(selectProfile);
    const { signInUserSession, adminRequestedStreetlibInternalId } = yield select(selectAuth);

    const { accessToken } = signInUserSession;

    const { pid, body } = approveData;

    let payloadBody = {
      invoicePayable: {
        ...body,
      },
    };

    if (adminRequestedStreetlibInternalId) {
      payloadBody = {
        invoicePayable: {
          ...body,
          approvedBy: streetlib_billing_id,
        },
      };
    }

    yield call(
      approveInvoiceDocument,
      languageCode,
      accessToken,
      streetlib_billing_id,
      adminRequestedStreetlibInternalId,
      pid,
      payloadBody,
    );

    const { data: invoice } = yield call(
      getInvoice,
      languageCode,
      accessToken,
      streetlib_billing_id,
      adminRequestedStreetlibInternalId,
      pid,
    );

    const {
      data: {
        relationships: {
          billingProfile: {
            data: { id: billingProfileId },
          },
        },
      },
    } = invoice;

    let billingProfile = {};
    let paymentAccount = {};

    if (billingProfileId) {
      const { data } = yield call(
        getBillingProfile,
        languageCode,
        accessToken,
        streetlib_billing_id,
        billingProfileId,
        adminRequestedStreetlibInternalId,
      );

      billingProfile = data;

      const {
        data: {
          relationships: {
            paymentAccount: {
              data: { id: paymentAccountId },
            },
          },
        },
      } = billingProfile;

      const { data: paymentAccountData } = yield call(
        getPaymentAccount,
        languageCode,
        accessToken,
        streetlib_billing_id,
        paymentAccountId,
        adminRequestedStreetlibInternalId,
      );

      paymentAccount = paymentAccountData;
    }

    if (callback) {
      callback();
    }

    return yield put(
      actions.approveInvoiceDocumentSuccess({ invoice, billingProfile, paymentAccount }),
    );
  } catch (err) {
    if (callback) {
      callback();
    }

    if (err.response) {
      return yield put(
        actions.approveInvoiceDocumentFailure({
          [err.response.data.errors[0].type]: err.response.data.errors[0].detail,
        }),
      );
    }

    return yield put(
      openFeedback({
        message: translations[languageCode].generic_send_api_error,
        error: err,
      }),
    );
  }
}

function* downloadInvoiceSaga({ payload }) {
  const { languageCode } = yield select(selectConfig);

  try {
    // REFRESH TOKEN
    yield put(refreshRequest());
    const { type } = yield take([AUTH_REFRESH_SUCCESS, AUTH_REFRESH_FAILURE, AUTH_REFRESH_SKIP]);
    if (type === AUTH_REFRESH_FAILURE) throw new Error('401 - Unauthorized');

    // eslint-disable-next-line camelcase
    const { signInUserSession, adminRequestedStreetlibInternalId } = yield select(selectAuth);

    const { accessToken } = signInUserSession;

    const {
      data: { response },
    } = yield call(
      downloadInvoice,
      languageCode,
      accessToken,
      adminRequestedStreetlibInternalId,
      payload.path,
    );

    downloadLink(response, `${payload.pid}.pdf`);

    return yield put(actions.downloadInvoiceSuccess());
  } catch (err) {
    yield put(actions.downloadInvoiceFailure(err));

    if (err.response) {
      if (err.response.status === 404) {
        return yield put(
          openFeedback({
            message: err.response.data.errors[0].detail,
            error: err,
          }),
        );
      }
    }

    return yield put(
      openFeedback({
        message: translations[languageCode].generic_retrieve_api_error,
        error: err,
      }),
    );
  }
}

function* downloadReportInvoiceSaga({ payload }) {
  const { languageCode } = yield select(selectConfig);

  try {
    // REFRESH TOKEN
    yield put(refreshRequest());
    const { type } = yield take([AUTH_REFRESH_SUCCESS, AUTH_REFRESH_FAILURE, AUTH_REFRESH_SKIP]);
    if (type === AUTH_REFRESH_FAILURE) throw new Error('401 - Unauthorized');

    // eslint-disable-next-line camelcase
    const { streetlib_billing_id } = yield select(selectProfile);
    const { signInUserSession, adminRequestedStreetlibInternalId } = yield select(selectAuth);

    const { accessToken } = signInUserSession;

    const {
      data: { response },
    } = yield call(
      downloadReportInvoice,
      languageCode,
      accessToken,
      adminRequestedStreetlibInternalId,
      streetlib_billing_id,
      payload.pid,
      payload.format,
    );

    downloadLink(response, `${payload.pid}.${payload.format}`);

    return yield put(actions.downloadInvoiceSuccess());
  } catch (err) {
    yield put(actions.downloadInvoiceFailure(err));

    if (err.response) {
      if (err.response.status === 404) {
        return yield put(
          openFeedback({
            message: err.response.data.errors[0].detail,
            error: err,
          }),
        );
      }
    }

    return yield put(
      openFeedback({
        message: translations[languageCode].generic_retrieve_api_error,
        error: err,
      }),
    );
  }
}

function* isWithrawAvailableSaga() {
  const { languageCode } = yield select(selectConfig);

  try {
    // REFRESH TOKEN
    yield put(refreshRequest());
    const { type } = yield take([AUTH_REFRESH_SUCCESS, AUTH_REFRESH_FAILURE, AUTH_REFRESH_SKIP]);
    if (type === AUTH_REFRESH_FAILURE) throw new Error('401 - Unauthorized');

    // eslint-disable-next-line camelcase
    const { streetlib_billing_id } = yield select(selectProfile);
    const { signInUserSession, adminRequestedStreetlibInternalId } = yield select(selectAuth);

    const { accessToken } = signInUserSession;

    const { data } = yield call(
      isWithrawAvailable,
      languageCode,
      accessToken,
      adminRequestedStreetlibInternalId,
      streetlib_billing_id,
    );

    return yield put(actions.isWithrawAvailableSuccess(data?.response || false));
  } catch (err) {
    yield put(actions.isWithrawAvailableFailure(err));

    if (err.response) {
      if (err.response.status === 404) {
        return yield put(
          openFeedback({
            message: err.response.data.errors[0].detail,
            error: err,
          }),
        );
      }
    }

    return yield put(
      openFeedback({
        message: translations[languageCode].generic_retrieve_api_error,
        error: err,
      }),
    );
  }
}

function* getLastWithdrawalSaga() {
  const { languageCode } = yield select(selectConfig);

  try {
    // REFRESH TOKEN
    yield put(refreshRequest());
    const { type } = yield take([AUTH_REFRESH_SUCCESS, AUTH_REFRESH_FAILURE, AUTH_REFRESH_SKIP]);
    if (type === AUTH_REFRESH_FAILURE) throw new Error('401 - Unauthorized');

    // eslint-disable-next-line camelcase
    const { streetlib_billing_id } = yield select(selectProfile);
    const { signInUserSession, adminRequestedStreetlibInternalId } = yield select(selectAuth);

    const { accessToken } = signInUserSession;

    const { data } = yield call(
      getLastWithdrawal,
      languageCode,
      accessToken,
      adminRequestedStreetlibInternalId,
      streetlib_billing_id,
    );

    return yield put(actions.getLastWithdrawalSuccess(data));
  } catch (err) {
    if (err.response) {
      if (err.response.status !== 404) {
        const message = err.response?.data?.errors[0]?.detail || err.response?.data;
        return yield put(
          openFeedback({
            message,
            error: err,
          }),
        );
      }

      return yield put(actions.getLastWithdrawalFailure(err));
    }

    yield put(actions.getLastWithdrawalFailure(err));
    return yield put(
      openFeedback({
        message: translations[languageCode].generic_retrieve_api_error,
        error: err,
      }),
    );
  }
}

function* postWithdrawalSaga() {
  const { languageCode } = yield select(selectConfig);

  try {
    // REFRESH TOKEN
    yield put(refreshRequest());
    const { type } = yield take([AUTH_REFRESH_SUCCESS, AUTH_REFRESH_FAILURE, AUTH_REFRESH_SKIP]);
    if (type === AUTH_REFRESH_FAILURE) throw new Error('401 - Unauthorized');

    // eslint-disable-next-line camelcase
    const { streetlib_billing_id } = yield select(selectProfile);
    const { signInUserSession, adminRequestedStreetlibInternalId } = yield select(selectAuth);

    const { accessToken } = signInUserSession;

    const { data } = yield call(
      postWithdrawal,
      languageCode,
      accessToken,
      adminRequestedStreetlibInternalId,
      streetlib_billing_id,
      {}, // Body isn't required for this endpoint
    );

    return yield put(actions.postWithdrawalSuccess(data));
  } catch (err) {
    yield put(actions.postWithdrawalFailure(err));

    if (err.response) {
      if (err.response.status !== 404) {
        return yield put(
          openFeedback({
            message: err.response.data.errors[0].detail,
            error: err,
          }),
        );
      }
    }

    return yield put(
      openFeedback({
        message: translations[languageCode].generic_retrieve_api_error,
        error: err,
      }),
    );
  }
}

function* checkPayeeIdSaga({ payload: { payeeId } }) {
  const { languageCode } = yield select(selectConfig);

  try {
    // REFRESH TOKEN
    yield put(refreshRequest());
    const { type } = yield take([AUTH_REFRESH_SUCCESS, AUTH_REFRESH_FAILURE, AUTH_REFRESH_SKIP]);
    if (type === AUTH_REFRESH_FAILURE) throw new Error('401 - Unauthorized');

    // eslint-disable-next-line camelcase
    const { signInUserSession } = yield select(selectAuth);

    const { accessToken } = signInUserSession;

    const { data } = yield call(checkPayeeId, languageCode, accessToken, payeeId);

    return yield put(actions.checkPayeeIdSuccess(data));
  } catch (err) {
    yield put(actions.checkPayeeIdFailure(err));

    if (err.response) {
      return yield put(
        openFeedback({
          message: err.response.data.errors[0].detail,
          error: err,
        }),
      );
    }

    return yield put(
      openFeedback({
        message: translations[languageCode].generic_retrieve_api_error,
        error: err,
      }),
    );
  }
}

function* postPayoneerRegistrationSaga({
  payload: { payeeIdentifier, alreadyHaveAnAccount, redirectUrl, callback },
}) {
  const { languageCode } = yield select(selectConfig);

  try {
    // REFRESH TOKEN
    yield put(refreshRequest());
    const { type } = yield take([AUTH_REFRESH_SUCCESS, AUTH_REFRESH_FAILURE, AUTH_REFRESH_SKIP]);
    if (type === AUTH_REFRESH_FAILURE) throw new Error('401 - Unauthorized');

    // eslint-disable-next-line camelcase
    const { signInUserSession, adminRequestedStreetlibInternalId } = yield select(selectAuth);

    const { accessToken } = signInUserSession;

    const body = { registrationLink: { payeeIdentifier, alreadyHaveAnAccount, redirectUrl } };

    const { data } = yield call(
      postPayoneerRegistration,
      languageCode,
      accessToken,
      adminRequestedStreetlibInternalId,
      body,
    );

    if (callback) callback();

    return yield put(actions.postPayoneerRegistrationSuccess(data));
  } catch (err) {
    yield put(actions.postPayoneerRegistrationFailure(err));

    if (err.response) {
      return yield put(
        openFeedback({
          message: err.response.data.errors[0].detail,
          error: err,
        }),
      );
    }

    return yield put(
      openFeedback({
        message: translations[languageCode].generic_retrieve_api_error,
        error: err,
      }),
    );
  }
}

function* checkTinSaga({ payload }) {
  const { languageCode } = yield select(selectConfig);

  const { countryCode, tin, type: tinType = null } = payload;

  try {
    // REFRESH TOKEN
    yield put(refreshRequest());
    const { type } = yield take([AUTH_REFRESH_SUCCESS, AUTH_REFRESH_FAILURE, AUTH_REFRESH_SKIP]);
    if (type === AUTH_REFRESH_FAILURE) throw new Error('401 - Unauthorized');

    // eslint-disable-next-line camelcase
    const { signInUserSession } = yield select(selectAuth);

    const { accessToken } = signInUserSession;

    const { data } = yield call(checkTin, languageCode, accessToken, countryCode, tin, tinType);

    yield put(
      tinType === 'company' ? actions.checkCompanyTinSuccess(data) : actions.checkTinSuccess(data),
    );
  } catch (err) {
    yield put(
      tinType === 'company' ? actions.checkCompanyTinFailure(err) : actions.checkTinFailure(err),
    );
  }
}

export default function* watchBilling() {
  yield takeLatest(GET_BILLING_SUMMARY_REQUEST, getBillingSummarySaga);
  yield takeLatest(GET_BILLING_DOCUMENTS_REQUEST, getBillingDocumentsSaga);
  yield takeLatest(GET_BILLING_DOCUMENT_REQUEST, getBillingDocumentSaga);
  yield takeLatest(GET_BILLING_DRAFT_DOCUMENT_REQUEST, getBillingDraftDocumentSaga);
  yield takeLatest(GET_BILLING_PROFILES_REQUEST, getBillingProfilesSaga);
  yield takeLatest(PATCH_BILLING_PROFILE_SUCCESS, getBillingProfilesSaga);
  yield takeLatest(DELETE_BILLING_PROFILE_SUCCESS, getBillingProfilesSaga);
  yield takeLatest(DELETE_PAYMENT_ACCOUNT_SUCCESS, getBillingProfilesSaga);
  yield takeLatest(PATCH_BILLING_PROFILE_REQUEST, patchBillingProfileSaga);
  yield takeLatest(POST_BILLING_PROFILE_REQUEST, postBillingProfileSaga);
  yield takeLatest(POST_BILLING_PROFILE_SUCCESS, getBillingProfilesSaga);
  yield takeLatest(DELETE_BILLING_PROFILE_REQUEST, deleteBillingProfileSaga);
  yield takeLatest(GET_PAYMENT_ACCOUNTS_REQUEST, getPaymentAccountsSaga);
  yield takeLatest(DELETE_PAYMENT_ACCOUNT_REQUEST, deletePaymentAccountSaga);
  yield takeLatest(PATCH_PAYMENT_ACCOUNT_REQUEST, patchPaymentAccountSaga);
  yield takeLatest(POST_PAYMENT_ACCOUNT_REQUEST, postPaymentAccountSaga);
  yield takeLatest(APPROVE_INVOICE_DOCUMENT_REQUEST, approveInvoiceDocumentSaga);
  yield takeLatest(DOWNLOAD_INVOICE_REQUEST, downloadInvoiceSaga);
  yield takeLatest(DOWNLOAD_REPORT_INVOICE_REQUEST, downloadReportInvoiceSaga);
  yield takeLatest(IS_WITHDRAW_AVAILABLE_REQUEST, isWithrawAvailableSaga);
  yield takeLatest(GET_LAST_WITHDRAWAL_REQUEST, getLastWithdrawalSaga);
  yield takeLatest(POST_WITHDRAWAL_REQUEST, postWithdrawalSaga);
  yield takeLatest(CHECK_PAYEE_ID_REQUEST, checkPayeeIdSaga);
  yield takeLatest(POST_PAYONEER_REGISTRATION_REQUEST, postPayoneerRegistrationSaga);
  yield takeLatest(CHECK_TIN_REQUEST, checkTinSaga);
  yield takeLatest(CHECK_COMPANY_TIN_REQUEST, checkTinSaga);
}
