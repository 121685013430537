/* eslint-disable camelcase */
import moment from 'moment';
import { put, takeEvery, call, select, take } from 'redux-saga/effects';
import { getWildcard } from 'api';
import {
  AUTH_REFRESH_SUCCESS,
  AUTH_REFRESH_FAILURE,
  AUTH_REFRESH_SKIP,
  GET_WILDCARD_REQUEST,
} from 'redux-api/action/actionTypes';
import * as actions from 'redux-api/action/wildcard';
import { refreshRequest } from 'redux-api/action/auth';
import selectAuth from 'redux-api/reselect/auth';
import { openFeedback } from 'redux-api/action/feedback';
import translations from 'translations';

const selectLastRefresh = (state, key) =>
  state.wildcard.payload[key] ? state.wildcard.payload[key].lastRefresh : null;
const selectConfig = (state) => state.config;

function* getWildcardSaga({ payload }) {
  const { languageCode } = yield select(selectConfig);

  try {
    // REFRESH TOKEN
    yield put(refreshRequest());
    const { type } = yield take([AUTH_REFRESH_SUCCESS, AUTH_REFRESH_FAILURE, AUTH_REFRESH_SKIP]);
    if (type === AUTH_REFRESH_FAILURE) throw new Error('401 - Unauthorized');

    const { signInUserSession, adminRequestedStreetlibInternalId } = yield select(selectAuth);

    const { accessToken } = signInUserSession;

    const now = moment();
    const lastRefresh = yield select((state) => selectLastRefresh(state, payload.key));
    const maxCachedTime = process.env.CACHED_TIME;

    if (lastRefresh) {
      const diff = now.diff(lastRefresh, 'minutes');

      if (diff < maxCachedTime) {
        return yield put(actions.getWildcardCached());
      }
    }

    const { currency } = yield select(selectConfig);

    const { offset, term, wildcard, dateFrom, dateTo } = payload;

    const aggrTerm = term.replace('_search', '');

    const { data } = yield call(getWildcard, accessToken, {
      adminRequestedStreetlibInternalId,
      dateFrom,
      dateTo,
      currency,
      offset,
      term,
      aggrTerm,
      wildcard,
    });

    return yield put(actions.getWildcardSuccess({ ...payload, data }));
  } catch (err) {
    yield put(
      openFeedback({
        message: translations[languageCode].generic_retrieve_api_error,
        error: err,
      }),
    );

    return yield put(actions.getWildcardFailure({ ...payload, error: err }));
  }
}

export default function* watchWildcard() {
  yield takeEvery(GET_WILDCARD_REQUEST, getWildcardSaga);
}
