import { all, fork } from 'redux-saga/effects';

// import watchEvent from './event';
import watchAuth from './auth';
import watchProfile from './profile';
import watchBilling from './billing';
import watchCount from './count';
import watchHistogram from './histogram';
import watchAggr from './aggr';
import watchWildcard from './wildcard';
import watchUnits from './units';
import watchRoute from './route';
import watchRedirect from './redirect';
import watchNews from './news';
import watchHistory from './history';
import watchWarning from './warning';
import watchPreset from './preset';
import watchFilter from './filters';
import watchCountries from './countries';
import watchTimezones from './timezones';
import watchCsv from './csv';
import watchStores from './stores';
import watchReportStatuses from './reportStatuses';
import watchTaxDocuments from './taxDocuments';
import watchTaxInterviews from './taxInterviews';

export default function* rootSaga() {
  yield all([
    // fork(watchEvent),
    fork(watchAuth),
    fork(watchProfile),
    fork(watchBilling),
    fork(watchCount),
    fork(watchHistogram),
    fork(watchAggr),
    fork(watchUnits),
    fork(watchRoute),
    fork(watchRedirect),
    fork(watchNews),
    fork(watchHistory),
    fork(watchWildcard),
    fork(watchWarning),
    fork(watchPreset),
    fork(watchFilter),
    fork(watchCountries),
    fork(watchTimezones),
    fork(watchCsv),
    fork(watchStores),
    fork(watchReportStatuses),
    fork(watchTaxDocuments),
    fork(watchTaxInterviews),
  ]);
}
