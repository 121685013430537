import moment from 'moment';
import {
  GET_COUNT_REQUEST,
  GET_COUNT_SUCCESS,
  GET_COUNT_FAILURE,
  GET_COUNT_CACHED,
  SET_USER_IMPERSONATION,
  RELOAD_FILTERS,
} from '../action/actionTypes';

const initialState = {
  payload: {},
  error: '',
  isWaiting: false,
};

export default function count(state = initialState, action) {
  switch (action.type) {
    case GET_COUNT_REQUEST:
      return {
        ...state,
        isWaiting: true,
        payload: {
          ...state.payload,
          [action.payload.key]: { ...state.payload[action.payload.key], ...action.payload },
        },
        error: initialState.error,
      };
    case GET_COUNT_SUCCESS:
      return {
        ...state,
        payload: {
          ...state.payload,
          [action.payload.key]: { ...action.payload, lastRefresh: moment() },
        },
        isWaiting: false,
        error: initialState.error,
      };
    case GET_COUNT_FAILURE:
      return {
        ...state,
        error: action.error,
        isWaiting: false,
      };
    case GET_COUNT_CACHED:
      return {
        ...state,
        isWaiting: false,
        error: initialState.error,
      };
    case SET_USER_IMPERSONATION:
      return { ...initialState };
    case RELOAD_FILTERS:
      return initialState;
    default:
      return state;
  }
}
