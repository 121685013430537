import moment from 'moment';
import {
  GET_UNITS_REQUEST,
  GET_UNITS_SUCCESS,
  GET_UNITS_FAILURE,
  GET_MORE_UNITS_REQUEST,
  GET_MORE_UNITS_SUCCESS,
  GET_MORE_UNITS_FAILURE,
  GET_UNITS_CACHED,
  SET_USER_IMPERSONATION,
  RELOAD_FILTERS,
} from '../action/actionTypes';

const initialState = {
  payload: {},
  error: '',
  isWaiting: false,
  isWaitingMore: false,
};

export default function units(state = initialState, action) {
  switch (action.type) {
    case GET_UNITS_REQUEST:
      return {
        ...state,
        isWaiting: true,
        error: initialState.error,
      };
    case GET_UNITS_SUCCESS:
      return {
        ...state,
        payload: {
          ...state.payload,
          [action.payload.key]: { ...action.payload, lastRefresh: moment() },
        },
        isWaiting: false,
        error: initialState.error,
      };
    case GET_UNITS_FAILURE:
      return {
        ...state,
        error: action.error,
        isWaiting: false,
      };
    case GET_MORE_UNITS_REQUEST:
      return {
        ...state,
        isWaitingMore: true,
        error: initialState.error,
      };
    case GET_MORE_UNITS_SUCCESS: {
      return {
        ...state,
        payload: {
          ...state.payload,
          [action.payload.key]: {
            ...state.payload[action.payload.key],
            data: {
              ...state.payload[action.payload.key].data,
              hits: {
                ...state.payload[action.payload.key].data.hits,
                hits: [...state.payload[action.payload.key].data.hits.hits, ...action.payload.data],
              },
            },
            lastRefresh: moment(),
          },
        },
        isWaitingMore: false,
        error: initialState.error,
      };
    }
    case GET_MORE_UNITS_FAILURE:
      return {
        ...state,
        isWaitingMore: false,
        error: action.error,
      };
    case GET_UNITS_CACHED:
      return {
        ...state,
        error: initialState.error,
        isWaiting: false,
      };
    case SET_USER_IMPERSONATION:
      return { ...initialState };
    case RELOAD_FILTERS:
      return initialState;
    default:
      return state;
  }
}
