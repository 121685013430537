/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import moment from 'moment';
import XLSX from 'xlsx';
import papa from 'papaparse';
import { put, takeEvery, call, select, take, delay } from 'redux-saga/effects';
import { getUnits, getAggr, postDownloadJob, getDownloadJob } from 'api';
import {
  AUTH_REFRESH_SUCCESS,
  AUTH_REFRESH_SKIP,
  AUTH_REFRESH_FAILURE,
  TRANSACTION_CSV_REQUEST,
  TRANSACTION_DOWNLOAD_JOB_REQUEST,
  TRANSACTION_DOWNLOAD_JOB_SUCCESS,
  TOP_LINE_CSV_REQUEST,
} from 'redux-api/action/actionTypes';
import * as actions from 'redux-api/action/csv';
import { openFeedback } from 'redux-api/action/feedback';
import { refreshRequest } from 'redux-api/action/auth';
import selectAuth from 'redux-api/reselect/auth';
import createDownloadLink, { downloadLink } from 'lib/create-download-link';
import formatCurrency from 'lib/format-currency';
import translations from 'translations';
import selectProfile from 'redux-api/reselect/profile';
// import formatCurrency from 'lib/format-currency';

const selectConfig = (state) => state.config;
const selectCsv = (state) => state.csv;

function* transactionCsvSaga({ payload }) {
  const { languageCode } = yield select(selectConfig);

  try {
    // REFRESH TOKEN
    yield put(refreshRequest());
    const { type } = yield take([AUTH_REFRESH_SUCCESS, AUTH_REFRESH_FAILURE, AUTH_REFRESH_SKIP]);
    if (type === AUTH_REFRESH_FAILURE) throw new Error('401 - Unauthorized');

    // eslint-disable-next-line camelcase
    const { signInUserSession, adminRequestedStreetlibInternalId } = yield select(selectAuth);

    const { accessToken } = signInUserSession;

    const params = `${
      adminRequestedStreetlibInternalId
        ? `adminRequestedStreetlibInternalId=${adminRequestedStreetlibInternalId}&`
        : ''
    }${payload.query}`;

    const { data } = yield call(getUnits, accessToken, params);

    const {
      hits: { hits },
    } = data;

    const units = hits.map((hit) => {
      const {
        _source: {
          id,
          store_name,
          isbn,
          title,
          language,
          authors,
          main_category,
          cover_link,
          price_eur,
          price_usd,
          country,
          publisher,
          item_type,
          date_order,
          date_publisher,
          platform_name,
          revenues_eur,
          revenues_usd,
          collections,
          supplier,
          order_type,
          quantity,
          data_type,
          public_domain,
        },
      } = hit;

      return {
        id,
        cover: cover_link,
        title,
        authors,
        genre: main_category,
        type: item_type,
        language,
        publisher,
        publishDate: date_publisher,
        orderDate: date_order,
        isbn,
        price: {
          eur: price_eur,
          usd: price_usd,
        },
        serie: collections,
        revenues: {
          eur: revenues_eur.supplier,
          usd: revenues_usd.supplier,
        },
        store: store_name,
        platform: platform_name,
        country,
        supplier,
        orderType: order_type,
        quantity,
        dataType: data_type,
        publicDomain: public_domain,
        itemType: item_type,
      };
    });

    let formattedUnits = {};

    units.forEach(
      ({
        id,
        genre,
        authors,
        language,
        publishDate,
        orderDate,
        price,
        revenues,
        country,
        serie,
        ...others
      }) => {
        moment.locale(payload.languageCode);

        // const formattedPrice = formatCurrency(payload.currency, Number(price[payload.currency]));
        // const formattedEarning = formatCurrency(payload.currency, Number(revenues[payload.currency]));

        const capitalAuthors = authors.map((author) => {
          const authorSplit = author.split(' ');

          const capitalName = authorSplit.map(
            (name) => `${name.charAt(0).toUpperCase()}${name.slice(1)}`,
          );

          return capitalName.join(' ');
        });

        // const countryCode = country.code;

        formattedUnits = {
          ...formattedUnits,
          [id]: {
            ...others,
            key: id,
            id,
            authors: capitalAuthors.join(', '),
            genre: genre && genre[payload.languageCode] ? genre[payload.languageCode] : null,
            language:
              language && language[payload.languageCode] ? language[payload.languageCode] : null,
            publishDate: moment(publishDate).format('DD MMM YYYY'),
            orderDate: moment(orderDate).format('DD MMM YYYY'),
            country: country[payload.languageCode],
            price: Number(price[payload.currency]).toFixed(3),
            earning: Number(revenues[payload.currency]).toFixed(3),
            currecy: payload.currency,
            serie: serie.join(' - '),
          },
        };
      },
    );

    const result = Object.keys(formattedUnits).map((key) => formattedUnits[key]);

    const csv = papa.unparse(result);
    createDownloadLink(
      csv,
      `${moment(payload.dateFrom).format('DD MMM YYYY')}-${moment(payload.dateTo).format(
        'DD MMM YYYY',
      )}.csv`,
    );

    return yield put(actions.transactionCsvSuccess({ ...payload, data }));
  } catch (err) {
    yield put(
      openFeedback({
        message: translations[languageCode].generic_retrieve_api_error,
        error: err
      }),
    );

    return yield put(actions.transactionCsvFailure(err));
  }
}

function* topLineCsvSaga({ payload }) {
  const { languageCode } = yield select(selectConfig);

  try {
    // REFRESH TOKEN
    yield put(refreshRequest());
    const { type } = yield take([AUTH_REFRESH_SUCCESS, AUTH_REFRESH_FAILURE, AUTH_REFRESH_SKIP]);
    if (type === AUTH_REFRESH_FAILURE) throw new Error('401 - Unauthorized');

    // eslint-disable-next-line camelcase
    const { signInUserSession, adminRequestedStreetlibInternalId } = yield select(selectAuth);

    const { accessToken } = signInUserSession;

    const params = `${
      adminRequestedStreetlibInternalId
        ? `adminRequestedStreetlibInternalId=${adminRequestedStreetlibInternalId}&`
        : ''
    }${payload.query}`;

    const { data } = yield call(getAggr, accessToken, params);

    const {
      aggregations: {
        results: { buckets },
      },
    } = data;

    const result = buckets
      .sort((a, b) => a.current_period.total_revenues.value - b.current_period.total_revenues.value)
      .map(({ key: title, current_period, previous_period }) => {
        const totalCurrentRevenues = formatCurrency(
          payload.currency,
          current_period.total_revenues.value,
        );
        const totalPreviousRevenues = formatCurrency(
          payload.currency,
          previous_period.total_revenues.value,
        );

        let percentEarning = 0;
        if (previous_period.total_revenues.value !== 0) {
          percentEarning = Math.round(
            ((current_period.total_revenues.value - previous_period.total_revenues.value) /
              previous_period.total_revenues.value) *
              100,
          );
        }

        return {
          Title: title,
          Revenues: totalCurrentRevenues,
          Qty: current_period.total_quantity.value,
          Earning: `${percentEarning > 0 ? '+' : percentEarning < 0 ? '-' : ''} ${Math.abs(
            percentEarning,
          )}%`,
          'Previous Revenues': totalPreviousRevenues,
        };
      })
      .reverse();

    const ws = XLSX.utils.json_to_sheet(result);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'top');
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    createDownloadLink(
      excelBuffer,
      `top-${payload.term}-${moment(payload.dateFrom).format('YYYYMMDD')}-${moment(
        payload.dateTo,
      ).format('YYYYMMDD')}.xlsx`,
    );

    return yield put(actions.topLineCsvSuccess({ ...payload, data }));
  } catch (err) {
    yield put(
      openFeedback({
        message: translations[languageCode].generic_retrieve_api_error,
        error: err
      }),
    );

    return yield put(actions.topLineCsvFailure(err));
  }
}

function* transactionDownloadJobSaga({ payload }) {
  const { languageCode } = yield select(selectConfig);

  try {
    // REFRESH TOKEN
    yield put(refreshRequest());
    const { type } = yield take([AUTH_REFRESH_SUCCESS, AUTH_REFRESH_FAILURE, AUTH_REFRESH_SKIP]);
    if (type === AUTH_REFRESH_FAILURE) throw new Error('401 - Unauthorized');

    // eslint-disable-next-line camelcase
    const { signInUserSession, adminRequestedStreetlibInternalId } = yield select(selectAuth);

    const { accessToken } = signInUserSession;

    const params = `${
      adminRequestedStreetlibInternalId
        ? `adminRequestedStreetlibInternalId=${adminRequestedStreetlibInternalId}&`
        : ''
    }`;

    const { isAdmin } = yield select(selectProfile);

    if (payload.body) payload.body.isAdmin = isAdmin;

    const {
      data: {
        params: { key },
      },
    } = yield call(postDownloadJob, accessToken, params, payload.body);

    return yield put(actions.transactionDownloadJobSuccess(key));
  } catch (err) {
    yield put(
      openFeedback({
        message: translations[languageCode].generic_send_api_error,
        error: err
      }),
    );

    return yield put(actions.transactionDownloadJobFailure(err));
  }
}

function* transactionDownloadGetSaga({ payload }) {
  const { languageCode } = yield select(selectConfig);

  try {
    // REFRESH TOKEN
    yield put(refreshRequest());
    const { type } = yield take([AUTH_REFRESH_SUCCESS, AUTH_REFRESH_FAILURE, AUTH_REFRESH_SKIP]);
    if (type === AUTH_REFRESH_FAILURE) throw new Error('401 - Unauthorized');

    // eslint-disable-next-line camelcase
    const { signInUserSession } = yield select(selectAuth);

    const { accessToken } = signInUserSession;

    const { data } = yield call(getDownloadJob, accessToken, payload);

    downloadLink(data.url, payload);

    return yield put(actions.transactionDownloadGetSuccess(data.url));
  } catch (err) {
    const { retryCounter } = yield select(selectCsv);

    if (
      err.response &&
      err.response.status > 299 &&
      retryCounter < process.env.RETRY_DOWNLOAD_REPORT_TIMES
    ) {
      yield delay(process.env.RETRY_DOWNLOAD_REPORT_DELAY);
      return yield put(actions.transactionDownloadJobSuccess(payload));
    }

    if (retryCounter >= process.env.RETRY_DOWNLOAD_REPORT_TIMES) {
      return yield put(actions.transactionDownloadGetTimeout());
    }

    yield put(
      openFeedback({
        message: translations[languageCode].generic_retrieve_api_error,
        error: err,
      }),
    );

    return yield put(actions.transactionDownloadGetFailure(err));
  }
}

export default function* watchCsv() {
  yield takeEvery(TRANSACTION_CSV_REQUEST, transactionCsvSaga);
  yield takeEvery(TOP_LINE_CSV_REQUEST, topLineCsvSaga);
  yield takeEvery(TRANSACTION_DOWNLOAD_JOB_REQUEST, transactionDownloadJobSaga);
  yield takeEvery(TRANSACTION_DOWNLOAD_JOB_SUCCESS, transactionDownloadGetSaga);
}
