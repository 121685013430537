import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Snackbar from 'theme/snackbar';
import { closeFeedback } from 'redux-api/action/feedback';

function ReduxSnackbar() {
  const dispatch = useDispatch();

  const payload = useSelector((state) => state.feedback);

  return <Snackbar {...payload} onClose={() => dispatch(closeFeedback())} />;
}

export default ReduxSnackbar;
