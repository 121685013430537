import * as types from './actionTypes';

export function getBillingProfilesRequest() {
  return {
    type: types.GET_BILLING_PROFILES_REQUEST,
  };
}

export function getBillingProfilesSuccess(payload) {
  return {
    type: types.GET_BILLING_PROFILES_SUCCESS,
    payload,
  };
}

export function getBillingProfilesFailure(error) {
  return {
    type: types.GET_BILLING_PROFILES_FAILURE,
    error,
  };
}

export function getPaymentAccountsRequest() {
  return {
    type: types.GET_PAYMENT_ACCOUNTS_REQUEST,
  };
}

export function getPaymentAccountsSuccess(payload) {
  return {
    type: types.GET_PAYMENT_ACCOUNTS_SUCCESS,
    payload,
  };
}

export function getPaymentAccountsFailure(error) {
  return {
    type: types.GET_PAYMENT_ACCOUNTS_FAILURE,
    error,
  };
}

export function getBillingRequest() {
  return {
    type: types.GET_BILLING_REQUEST,
  };
}

export function getBillingSuccess(payload) {
  return {
    type: types.GET_BILLING_SUCCESS,
    payload,
  };
}

export function getBillingFailure(error) {
  return {
    type: types.GET_BILLING_FAILURE,
    error,
  };
}

export function getBillingCached() {
  return {
    type: types.GET_BILLING_CACHED,
  };
}

export function getBillingSummaryRequest(payload) {
  return {
    type: types.GET_BILLING_SUMMARY_REQUEST,
    payload,
  };
}

export function getBillingSummarySuccess(payload) {
  return {
    type: types.GET_BILLING_SUMMARY_SUCCESS,
    payload,
  };
}

export function getBillingSummaryFailure(error) {
  return {
    type: types.GET_BILLING_SUMMARY_FAILURE,
    error,
  };
}

export function getBillingDocumentsRequest(payload) {
  return {
    type: types.GET_BILLING_DOCUMENTS_REQUEST,
    payload,
  };
}

export function getBillingDocumentsSuccess(payload) {
  return {
    type: types.GET_BILLING_DOCUMENTS_SUCCESS,
    payload,
  };
}

export function getBillingDocumentsFailure(error) {
  return {
    type: types.GET_BILLING_DOCUMENTS_FAILURE,
    error,
  };
}

export function getBillingDocumentRequest(payload) {
  return {
    type: types.GET_BILLING_DOCUMENT_REQUEST,
    payload,
  };
}

export function getBillingDocumentSuccess(payload) {
  return {
    type: types.GET_BILLING_DOCUMENT_SUCCESS,
    payload,
  };
}

export function getBillingDocumentFailure(error) {
  return {
    type: types.GET_BILLING_DOCUMENT_FAILURE,
    error,
  };
}

export function getBillingDraftDocumentRequest(payload) {
  return {
    type: types.GET_BILLING_DRAFT_DOCUMENT_REQUEST,
    payload,
  };
}

export function getBillingDraftDocumentSuccess(payload) {
  return {
    type: types.GET_BILLING_DRAFT_DOCUMENT_SUCCESS,
    payload,
  };
}

export function getBillingDraftDocumentFailure(error) {
  return {
    type: types.GET_BILLING_DRAFT_DOCUMENT_FAILURE,
    error,
  };
}

export function postBillingProfileRequest(payload) {
  return {
    type: types.POST_BILLING_PROFILE_REQUEST,
    payload,
  };
}

export function postBillingProfileSuccess(payload) {
  return {
    type: types.POST_BILLING_PROFILE_SUCCESS,
    payload,
  };
}

export function postBillingProfileFailure(error) {
  return {
    type: types.POST_BILLING_PROFILE_FAILURE,
    error,
  };
}

export function patchBillingProfileRequest(payload) {
  return {
    type: types.PATCH_BILLING_PROFILE_REQUEST,
    payload,
  };
}

export function patchBillingProfileSuccess(payload) {
  return {
    type: types.PATCH_BILLING_PROFILE_SUCCESS,
    payload,
  };
}

export function patchBillingProfileFailure(error) {
  return {
    type: types.PATCH_BILLING_PROFILE_FAILURE,
    error,
  };
}

export function deleteBillingProfileRequest(payload) {
  return {
    type: types.DELETE_BILLING_PROFILE_REQUEST,
    payload,
  };
}

export function deleteBillingProfileSuccess(payload) {
  return {
    type: types.DELETE_BILLING_PROFILE_SUCCESS,
    payload,
  };
}

export function deleteBillingProfileFailure(error) {
  return {
    type: types.DELETE_BILLING_PROFILE_FAILURE,
    error,
  };
}

export function deletePaymentAccountRequest(payload) {
  return {
    type: types.DELETE_PAYMENT_ACCOUNT_REQUEST,
    payload,
  };
}

export function deletePaymentAccountSuccess(payload) {
  return {
    type: types.DELETE_PAYMENT_ACCOUNT_SUCCESS,
    payload,
  };
}

export function deletePaymentAccountFailure(error) {
  return {
    type: types.DELETE_PAYMENT_ACCOUNT_FAILURE,
    error,
  };
}

export function postPaymentAccountRequest(payload) {
  return {
    type: types.POST_PAYMENT_ACCOUNT_REQUEST,
    payload,
  };
}

export function postPaymentAccountSuccess(payload) {
  return {
    type: types.POST_PAYMENT_ACCOUNT_SUCCESS,
    payload,
  };
}

export function postPaymentAccountFailure(error) {
  return {
    type: types.POST_PAYMENT_ACCOUNT_FAILURE,
    error,
  };
}

export function patchPaymentAccountRequest(payload) {
  return {
    type: types.PATCH_PAYMENT_ACCOUNT_REQUEST,
    payload,
  };
}

export function patchPaymentAccountSuccess(payload) {
  return {
    type: types.PATCH_PAYMENT_ACCOUNT_SUCCESS,
    payload,
  };
}

export function patchPaymentAccountFailure(error) {
  return {
    type: types.PATCH_PAYMENT_ACCOUNT_FAILURE,
    error,
  };
}

export function approveInvoiceDocumentRequest(payload) {
  return {
    type: types.APPROVE_INVOICE_DOCUMENT_REQUEST,
    payload,
  };
}

export function approveInvoiceDocumentSuccess(payload) {
  return {
    type: types.APPROVE_INVOICE_DOCUMENT_SUCCESS,
    payload,
  };
}

export function approveInvoiceDocumentFailure(error) {
  return {
    type: types.APPROVE_INVOICE_DOCUMENT_FAILURE,
    error,
  };
}

export function downloadInvoiceRequest(payload) {
  return {
    type: types.DOWNLOAD_INVOICE_REQUEST,
    payload,
  };
}

export function downloadInvoiceSuccess(payload) {
  return {
    type: types.DOWNLOAD_INVOICE_SUCCESS,
    payload,
  };
}

export function downloadInvoiceFailure(error) {
  return {
    type: types.DOWNLOAD_INVOICE_FAILURE,
    error,
  };
}

export function downloadReportInvoiceRequest(payload) {
  return {
    type: types.DOWNLOAD_REPORT_INVOICE_REQUEST,
    payload,
  };
}

export function downloadReportInvoiceSuccess(payload) {
  return {
    type: types.DOWNLOAD_REPORT_INVOICE_SUCCESS,
    payload,
  };
}

export function downloadReportInvoiceFailure(error) {
  return {
    type: types.DOWNLOAD_REPORT_INVOICE_FAILURE,
    error,
  };
}

export function isWithrawAvailableRequest(payload) {
  return {
    type: types.IS_WITHDRAW_AVAILABLE_REQUEST,
    payload,
  };
}

export function isWithrawAvailableSuccess(payload) {
  return {
    type: types.IS_WITHDRAW_AVAILABLE_SUCCESS,
    payload,
  };
}

export function isWithrawAvailableFailure(error) {
  return {
    type: types.IS_WITHDRAW_AVAILABLE_FAILURE,
    error,
  };
}

export function getWithdrawalsRequest(payload) {
  return {
    type: types.GET_WITHDRAWALS_REQUEST,
    payload,
  };
}

export function getWithdrawalsSuccess(payload) {
  return {
    type: types.GET_WITHDRAWALS_SUCCESS,
    payload,
  };
}

export function getWithdrawalsFailure(error) {
  return {
    type: types.GET_WITHDRAWALS_FAILURE,
    error,
  };
}

export function getLastWithdrawalRequest(payload) {
  return {
    type: types.GET_LAST_WITHDRAWAL_REQUEST,
    payload,
  };
}

export function getLastWithdrawalSuccess(payload) {
  return {
    type: types.GET_LAST_WITHDRAWAL_SUCCESS,
    payload,
  };
}

export function getLastWithdrawalFailure(error) {
  return {
    type: types.GET_LAST_WITHDRAWAL_FAILURE,
    error,
  };
}

export function postWithdrawalRequest(payload) {
  return {
    type: types.POST_WITHDRAWAL_REQUEST,
    payload,
  };
}

export function postWithdrawalSuccess(payload) {
  return {
    type: types.POST_WITHDRAWAL_SUCCESS,
    payload,
  };
}

export function postWithdrawalFailure(error) {
  return {
    type: types.POST_WITHDRAWAL_FAILURE,
    error,
  };
}

export function checkPayeeIdRequest(payload) {
  return {
    type: types.CHECK_PAYEE_ID_REQUEST,
    payload,
  };
}

export function checkPayeeIdSuccess(payload) {
  return {
    type: types.CHECK_PAYEE_ID_SUCCESS,
    payload,
  };
}

export function checkPayeeIdFailure(error) {
  return {
    type: types.CHECK_PAYEE_ID_FAILURE,
    error,
  };
}

export function postPayoneerRegistrationRequest(payload) {
  return {
    type: types.POST_PAYONEER_REGISTRATION_REQUEST,
    payload,
  };
}

export function postPayoneerRegistrationSuccess(payload) {
  return {
    type: types.POST_PAYONEER_REGISTRATION_SUCCESS,
    payload,
  };
}

export function postPayoneerRegistrationFailure(error) {
  return {
    type: types.POST_PAYONEER_REGISTRATION_FAILURE,
    error,
  };
}

export function checkTinRequest(payload) {
  return {
    type: types.CHECK_TIN_REQUEST,
    payload,
  };
}

export function checkTinSuccess(payload) {
  return {
    type: types.CHECK_TIN_SUCCESS,
    payload,
  };
}

export function checkTinFailure(error) {
  return {
    type: types.CHECK_TIN_FAILURE,
    error,
  };
}

export function checkCompanyTinRequest(payload) {
  return {
    type: types.CHECK_COMPANY_TIN_REQUEST,
    payload,
  };
}

export function checkCompanyTinSuccess(payload) {
  return {
    type: types.CHECK_COMPANY_TIN_SUCCESS,
    payload,
  };
}

export function checkCompanyTinFailure(error) {
  return {
    type: types.CHECK_COMPANY_TIN_FAILURE,
    error,
  };
}
