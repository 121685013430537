import { put, takeEvery, call, select } from 'redux-saga/effects';
import { getNews } from 'api';
import { GET_NEWS_REQUEST } from 'redux-api/action/actionTypes';
import * as actions from 'redux-api/action/news';
import { openFeedback } from 'redux-api/action/feedback';
import translations from 'translations';

const selectConfig = (state) => state.config;

function* getNewsSaga() {
  let { languageCode } = yield select(selectConfig);

  if (languageCode === 'pt' || languageCode === 'de') languageCode = 'en';

  try {
    const { data } = yield call(getNews, languageCode);

    yield put(actions.getNewsSuccess(data));
  } catch (err) {
    yield put(actions.getNewsFailure(err));

    yield put(
      openFeedback({
        message: translations[languageCode].generic_retrieve_api_error,
        error: err,
      }),
    );
  }
}

export default function* watchNews() {
  yield takeEvery(GET_NEWS_REQUEST, getNewsSaga);
}
