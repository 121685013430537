import * as types from './actionTypes';

export function getProfileRequest(payload) {
  return {
    type: types.GET_PROFILE_REQUEST,
    payload,
  };
}

export function getProfileSuccess(payload) {
  return {
    type: types.GET_PROFILE_SUCCESS,
    payload,
  };
}

export function getProfileFailure(error) {
  return {
    type: types.GET_PROFILE_FAILURE,
    error,
  };
}

export function getProfileCached() {
  return {
    type: types.GET_PROFILE_CACHED,
  };
}

export function patchProfileRequest(payload) {
  return {
    type: types.PATCH_PROFILE_REQUEST,
    payload,
  };
}

export function patchProfileSuccess(payload) {
  return {
    type: types.PATCH_PROFILE_SUCCESS,
    payload,
  };
}

export function patchProfileFailure(error) {
  return {
    type: types.PATCH_PROFILE_FAILURE,
    error,
  };
}

export function setLocalAuth(payload) {
  return {
    type: types.SET_LOCAL_AUTH,
    payload,
  };
}

export function tokenActivationRequest(payload) {
  return {
    type: types.TOKEN_ACTIVATION_REQUEST,
    payload,
  };
}

export function tokenActivationSuccess() {
  return {
    type: types.TOKEN_ACTIVATION_SUCCESS,
  };
}

export function tokenActivationFailure(error) {
  return {
    type: types.TOKEN_ACTIVATION_FAILURE,
    error,
  };
}

export function patchAvatarRequest(payload) {
  return {
    type: types.PATCH_AVATAR_REQUEST,
    payload,
  };
}

export function patchAvatarSuccess(payload) {
  return {
    type: types.PATCH_AVATAR_SUCCESS,
    payload,
  };
}

export function patchAvatarFailure(error) {
  return {
    type: types.PATCH_AVATAR_FAILURE,
    error,
  };
}

export function patchLocalizationRequest(payload) {
  return {
    type: types.PATCH_LOCALIZATION_REQUEST,
    payload,
  };
}

export function patchLocalizationSuccess(payload) {
  return {
    type: types.PATCH_LOCALIZATION_SUCCESS,
    payload,
  };
}

export function patchLocalizationFailure(error) {
  return {
    type: types.PATCH_LOCALIZATION_FAILURE,
    error,
  };
}

export function deleteAvatarRequest() {
  return {
    type: types.DELETE_AVATAR_REQUEST,
  };
}

export function deleteAvatarSuccess() {
  return {
    type: types.DELETE_AVATAR_SUCCESS,
  };
}

export function deleteAvatarFailure(error) {
  return {
    type: types.DELETE_AVATAR_FAILURE,
    error,
  };
}

export function postNewsletterRequest(payload) {
  return {
    type: types.POST_NEWSLETTER_REQUEST,
    payload,
  };
}

export function postNewsletterSuccess(payload) {
  return {
    type: types.POST_NEWSLETTER_SUCCESS,
    payload,
  };
}

export function postNewsletterFailure(error) {
  return {
    type: types.POST_NEWSLETTER_FAILURE,
    error,
  };
}

export function deleteNewsletterRequest(payload) {
  return {
    type: types.DELETE_NEWSLETTER_REQUEST,
    payload,
  };
}

export function deleteNewsletterSuccess(payload) {
  return {
    type: types.DELETE_NEWSLETTER_SUCCESS,
    payload,
  };
}

export function deleteNewsletterFailure(error) {
  return {
    type: types.DELETE_NEWSLETTER_FAILURE,
    error,
  };
}

export function postAcceptContractRequest(payload) {
  return {
    type: types.POST_ACCEPT_CONTRACT_REQUEST,
    payload,
  };
}

export function postAcceptContractSuccess() {
  return {
    type: types.POST_ACCEPT_CONTRACT_SUCCESS,
  };
}

export function postAcceptContractFailure(error) {
  return {
    type: types.POST_ACCEPT_CONTRACT_FAILURE,
    error,
  };
}
