import styled from 'styled-components';
import { typography, color, space } from 'styled-system';

export default styled.p`
  ${typography}
  ${color}
  ${space}

  ${({ noWrap }) => (noWrap ? 'white-space: nowrap' : '')};
`;
