import styled from 'styled-components';
import { layout, color, space, border } from 'styled-system';

const Card = styled.div`
  ${layout}
  ${color}
  ${space}

  border: 1px solid;
  border-radius: 12px;
  outline: 0;

  ${border}
`;

export default Card;
