import {
  TRANSACTION_CSV_REQUEST,
  TRANSACTION_CSV_SUCCESS,
  TRANSACTION_CSV_FAILURE,
  TOP_LINE_CSV_REQUEST,
  TOP_LINE_CSV_SUCCESS,
  TOP_LINE_CSV_FAILURE,
  TRANSACTION_DOWNLOAD_JOB_REQUEST,
  TRANSACTION_DOWNLOAD_JOB_SUCCESS,
  TRANSACTION_DOWNLOAD_JOB_FAILURE,
  TRANSACTION_DOWNLOAD_GET_REQUEST,
  TRANSACTION_DOWNLOAD_GET_SUCCESS,
  TRANSACTION_DOWNLOAD_GET_FAILURE,
  TRANSACTION_DOWNLOAD_GET_TIMEOUT,
  TRANSACTION_DOWNLOAD_GET_RESET,
} from '../action/actionTypes';

const initialState = {
  payload: [],
  error: '',
  isWaiting: false,
  isDownloadWaiting: false,
  retryCounter: 0,
  reportLink: null,
  timeout: false,
};

export default function csv(state = initialState, action) {
  switch (action.type) {
    case TRANSACTION_CSV_REQUEST:
      return {
        ...initialState,
        isWaiting: true,
        error: initialState.error,
      };
    case TRANSACTION_CSV_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        isWaiting: false,
        error: initialState.error,
      };
    case TRANSACTION_CSV_FAILURE:
      return {
        ...state,
        error: action.error,
        isWaiting: false,
      };
    case TOP_LINE_CSV_REQUEST:
      return {
        ...initialState,
        isWaiting: true,
        error: initialState.error,
      };
    case TOP_LINE_CSV_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        isWaiting: false,
        error: initialState.error,
      };
    case TOP_LINE_CSV_FAILURE:
      return {
        ...state,
        error: action.error,
        isWaiting: false,
      };
    case TRANSACTION_DOWNLOAD_JOB_REQUEST:
      return {
        ...initialState,
        isWaiting: true,
        error: initialState.error,
        retryCounter: initialState.retryCounter,
        reportLink: initialState.reportLink,
        timeout: false,
      };
    case TRANSACTION_DOWNLOAD_JOB_SUCCESS:
      return {
        ...state,
        isWaiting: false,
        isDownloadWaiting: true,
        error: initialState.error,
        retryCounter: state.retryCounter + 1,
      };
    case TRANSACTION_DOWNLOAD_JOB_FAILURE:
      return {
        ...state,
        error: action.error,
        isWaiting: false,
        retryCounter: initialState.retryCounter,
        reportLink: initialState.reportLink,
        timeout: false,
      };
    case TRANSACTION_DOWNLOAD_GET_REQUEST:
      return {
        ...initialState,
        isDownloadWaiting: true,
        error: initialState.error,
        retryCounter: initialState.retryCounter,
        reportLink: initialState.reportLink,
        timeout: false,
      };
    case TRANSACTION_DOWNLOAD_GET_SUCCESS:
      return {
        ...state,
        isDownloadWaiting: false,
        error: initialState.error,
        retryCounter: initialState.retryCounter,
        reportLink: action.payload,
        timeout: false,
      };
    case TRANSACTION_DOWNLOAD_GET_FAILURE:
      return {
        ...state,
        error: action.error,
        isDownloadWaiting: false,
        retryCounter: initialState.retryCounter,
        reportLink: initialState.reportLink,
        timeout: false,
      };
    case TRANSACTION_DOWNLOAD_GET_TIMEOUT:
      return {
        ...state,
        timeout: true,
        isDownloadWaiting: false,
        retryCounter: initialState.retryCounter,
        reportLink: initialState.reportLink,
      };
    case TRANSACTION_DOWNLOAD_GET_RESET:
      return {
        ...state,
        timeout: initialState.timeout,
        isDownloadWaiting: initialState.isDownloadWaiting,
        retryCounter: initialState.retryCounter,
        reportLink: initialState.reportLink,
      };
    default:
      return state;
  }
}
