import * as types from './actionTypes';

export function getReportStatusesRequest(payload) {
  return {
    type: types.GET_REPORT_STATUSES_REQUEST,
    payload
  };
}

export function getReportStatusesSuccess(payload) {
  return {
    type: types.GET_REPORT_STATUSES_SUCCESS,
    payload,
  };
}

export function getReportStatusesFailure(error) {
  return {
    type: types.GET_REPORT_STATUSES_FAILURE,
    error,
  };
}

export function getReportStatusesCached() {
  return {
    type: types.GET_REPORT_STATUSES_CACHED,
  };
}
