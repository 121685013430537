import * as types from './actionTypes';

export function getUnitsRequest(payload) {
  return {
    type: types.GET_UNITS_REQUEST,
    payload,
  };
}

export function getUnitsSuccess(payload) {
  return {
    type: types.GET_UNITS_SUCCESS,
    payload,
  };
}

export function getUnitsFailure(error) {
  return {
    type: types.GET_UNITS_FAILURE,
    error,
  };
}

export function getUnitsCached() {
  return {
    type: types.GET_UNITS_CACHED,
  };
}

export function getMoreUnitsRequest(payload) {
  return {
    type: types.GET_MORE_UNITS_REQUEST,
    payload,
  };
}

export function getMoreUnitsSuccess(payload) {
  return {
    type: types.GET_MORE_UNITS_SUCCESS,
    payload,
  };
}

export function getMoreUnitsFailure(error) {
  return {
    type: types.GET_MORE_UNITS_FAILURE,
    error,
  };
}
