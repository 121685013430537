import * as types from './actionTypes';

export function getWildcardRequest(payload) {
  return {
    type: types.GET_WILDCARD_REQUEST,
    payload,
  };
}

export function getWildcardSuccess(payload) {
  return {
    type: types.GET_WILDCARD_SUCCESS,
    payload,
  };
}

export function getWildcardFailure(payload) {
  return {
    type: types.GET_WILDCARD_FAILURE,
    payload,
  };
}

export function getWildcardCached() {
  return {
    type: types.GET_WILDCARD_CACHED,
  };
}
