/* eslint-disable camelcase */
import { createSelector } from 'reselect';

const selectAuth = createSelector(
  (state) => state.auth,
  (auth) => {
    const {
      payload: { signInUserSession, Username, UserAttributes },
      adminRequestedStreetlibInternalId,
    } = auth;

    if (!signInUserSession || !Username || !UserAttributes) {
      return {
        signInUserSession: {},
        Username: '',
        attributes: {},
      };
    }

    let UserAttributesObject = {};

    UserAttributes.forEach((attribute) => {
      UserAttributesObject = {
        ...UserAttributesObject,
        [attribute.Name]: attribute.Value,
      };
    });

    return {
      signInUserSession,
      Username,
      attributes: UserAttributesObject,
      adminRequestedStreetlibInternalId,
    };
  },
);

export default selectAuth;
