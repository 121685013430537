import { put, takeLatest, call, select, take } from 'redux-saga/effects';
import { getTaxDocuments, downloadTaxDocument } from 'api';
import {
  AUTH_REFRESH_SUCCESS,
  AUTH_REFRESH_FAILURE,
  AUTH_REFRESH_SKIP,
  GET_TAXDOCUMENTS_REQUEST,
  DOWNLOAD_TAXDOCUMENT_REQUEST,
} from 'redux-api/action/actionTypes';
import { refreshRequest } from 'redux-api/action/auth';
import selectAuth from 'redux-api/reselect/auth';
import * as actions from 'redux-api/action/taxDocuments';
import { openFeedback } from 'redux-api/action/feedback';
import { downloadLink } from 'lib/create-download-link';
import translations from 'translations';

const selectProfile = (state) => state.profile.payload;
const selectConfig = (state) => state.config;

function* getTaxDocumentsSaga({ payload }) {
  const { languageCode } = yield select(selectConfig);

  try {
    // REFRESH TOKEN
    yield put(refreshRequest());
    const { type } = yield take([AUTH_REFRESH_SUCCESS, AUTH_REFRESH_FAILURE, AUTH_REFRESH_SKIP]);
    if (type === AUTH_REFRESH_FAILURE) throw new Error('401 - Unauthorized');

    // eslint-disable-next-line camelcase
    const { signInUserSession, adminRequestedStreetlibInternalId } = yield select(selectAuth);
    const { streetlib_billing_id } = yield select(selectProfile);

    const { accessToken } = signInUserSession;

    const { data } = yield call(
      getTaxDocuments,
      languageCode,
      accessToken,
      streetlib_billing_id,
      adminRequestedStreetlibInternalId,
      payload.page,
      payload.pageSize,
    );

    return yield put(actions.getTaxDocumentsSuccess(data));
  } catch (err) {
    yield put(
      openFeedback({
        message: translations[languageCode].generic_retrieve_api_error,
        error: err,
      }),
    );

    return yield put(actions.getTaxDocumentsFailure(err));
  }
}

function* downloadTaxDocumentSaga({ payload }) {
  const { languageCode } = yield select(selectConfig);

  try {
    // REFRESH TOKEN
    yield put(refreshRequest());
    const { type } = yield take([AUTH_REFRESH_SUCCESS, AUTH_REFRESH_FAILURE, AUTH_REFRESH_SKIP]);
    if (type === AUTH_REFRESH_FAILURE) throw new Error('401 - Unauthorized');

    // eslint-disable-next-line camelcase
    const { signInUserSession, adminRequestedStreetlibInternalId } = yield select(selectAuth);

    const { accessToken } = signInUserSession;

    const {
      data: { response },
    } = yield call(
      downloadTaxDocument,
      languageCode,
      accessToken,
      adminRequestedStreetlibInternalId,
      payload.path,
    );

    downloadLink(response, `${payload.pid}.pdf`);

    return yield put(actions.downloadTaxDocumentSuccess());
  } catch (err) {
    yield put(
      openFeedback({
        message: translations[languageCode].generic_retrieve_api_error,
        error: err,
      }),
    );

    return yield put(actions.downloadTaxDocumentFailure(err));
  }
}

export default function* watchTaxDocuments() {
  yield takeLatest(GET_TAXDOCUMENTS_REQUEST, getTaxDocumentsSaga);
  yield takeLatest(DOWNLOAD_TAXDOCUMENT_REQUEST, downloadTaxDocumentSaga);
}
