import * as types from './actionTypes';

export function getTimezonesRequest() {
  return {
    type: types.GET_TIMEZONES_REQUEST,
  };
}

export function getTimezonesSuccess(payload) {
  return {
    type: types.GET_TIMEZONES_SUCCESS,
    payload,
  };
}

export function getTimezonesFailure(error) {
  return {
    type: types.GET_TIMEZONES_FAILURE,
    error,
  };
}

export function getTimezonesCached() {
  return {
    type: types.GET_TIMEZONES_CACHED,
  };
}
