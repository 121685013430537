import { createSelector } from 'reselect';
import translations from 'translations';

const selectConfig = createSelector(
  (state) => state.config,
  (payload) => payload,
);

export const selectOtherFilter = createSelector(
  (state) => state.config.otherFilter,
  selectConfig,
  (payload, config) => {
    const { languageCode } = config;

    return payload
      .map((p) => {
        const translated = translations[languageCode][p.label.toLowerCase()];

        return { ...p, label: translated };
      })
      .sort((a, b) => a.position - b.position);
  },
);

export const selectByFiler = createSelector(
  (state) => state.config.selectByFilter,
  selectConfig,
  (payload, config) => {
    const { languageCode } = config;

    return payload.map((p) => {
      const translated = translations[languageCode][p.label.toLowerCase()];

      return { ...p, label: translated };
    });
  },
);

export default selectConfig;
