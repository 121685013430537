import * as types from './actionTypes';

export function nativeLocalAuth(payload) {
  return {
    type: types.NATIVE_LOCAL_AUTH,
    payload,
  };
}

export function nativeUserProfile(payload) {
  return {
    type: types.NATIVE_USER_PROFILE,
    payload,
  };
}
