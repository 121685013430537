import * as types from './actionTypes';

export function changeDataRangeFilter(payload) {
  return {
    type: types.CHANGE_DATARANGE_FILTER,
    payload,
  };
}

export function changeDataTypeFilter(payload) {
  return {
    type: types.CHANGE_DATATYPE_FILTER,
    payload,
  };
}

export function changeCompareFilter(payload) {
  return {
    type: types.CHANGE_COMPARE_FILTER,
    payload,
  };
}

export function changeDatesFilter(payload) {
  return {
    type: types.CHANGE_DATES_FILTER,
    payload,
  };
}

export function changeIntervalFilter(payload) {
  return {
    type: types.CHANGE_INTERVAL_FILTER,
    payload,
  };
}

export function changeOthersFilter(payload) {
  return {
    type: types.CHANGE_OTHERS_FILTER,
    payload,
  };
}

export function changeTermsFilter(payload) {
  return {
    type: types.CHANGE_TERMS_FILTER,
    payload,
  };
}

export function changeFilters(payload) {
  return {
    type: types.CHANGE_FILTERS,
    payload,
  };
}

export function resetChanged() {
  return {
    type: types.RESET_CHANGED,
  };
}

export function resetFilters() {
  return {
    type: types.RESET_FILTERS,
  };
}

export function reloadFilters() {
  return {
    type: types.RELOAD_FILTERS,
  };
}

export function comparedToRequest(payload) {
  return {
    type: types.COMPARETO_REQUEST,
    payload,
  };
}

export function comparedToSuccess(payload) {
  return {
    type: types.COMPARETO_SUCCESS,
    payload,
  };
}

export function comparedToFailure(error) {
  return {
    type: types.COMPARETO_FAILURE,
    error,
  };
}
