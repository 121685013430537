import * as types from './actionTypes';

export function getAuthState(payload) {
  return {
    type: types.GET_AUTH_STATE,
    payload,
  };
}

export function signinRequest(payload) {
  return {
    type: types.AUTH_SIGNIN_REQUEST,
    payload,
  };
}

export function signinSuccess(payload) {
  return {
    type: types.AUTH_SIGNIN_SUCCESS,
    payload,
  };
}

export function signinFailure(payload) {
  return {
    type: types.AUTH_SIGNIN_FAILURE,
    payload,
  };
}

export function signedSuccess(payload) {
  return {
    type: types.AUTH_SIGNED_SUCCESS,
    payload,
  };
}

export function signedFailure(payload) {
  return {
    type: types.AUTH_SIGNED_FAILURE,
    payload,
  };
}

export function refreshRequest() {
  return {
    type: types.AUTH_REFRESH_REQUEST,
  };
}

export function refreshSuccess(payload) {
  return {
    type: types.AUTH_REFRESH_SUCCESS,
    payload,
  };
}

export function refreshFailure(error) {
  return {
    type: types.AUTH_REFRESH_FAILURE,
    error,
  };
}

export function signoutRequest() {
  return {
    type: types.AUTH_SIGNOUT_REQUEST,
  };
}

export function signoutSuccess() {
  return {
    type: types.AUTH_SIGNOUT_SUCCESS,
  };
}

export function signoutFailure(error) {
  return {
    type: types.AUTH_SIGNOUT_FAILURE,
    error,
  };
}

export function changePasswordRequest(payload) {
  return {
    type: types.CHANGE_PASSWORD_REQUEST,
    payload,
  };
}

export function changePasswordSuccess() {
  return {
    type: types.CHANGE_PASSWORD_SUCCESS,
  };
}

export function changePasswordFailure(error) {
  return {
    type: types.CHANGE_PASSWORD_FAILURE,
    error,
  };
}

export function refreshSkip() {
  return {
    type: types.AUTH_REFRESH_SKIP,
  };
}

export function refreshToken() {
  return {
    type: types.AUTH_REFRESH_TOKEN,
  };
}

export function updateToken(payload) {
  return {
    type: types.AUTH_UPDATE_TOKEN,
    payload,
  };
}

export function setUserImpersonation(payload) {
  return {
    type: types.SET_USER_IMPERSONATION,
    payload,
  };
}
