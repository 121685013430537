import * as types from './actionTypes';

export function getAggrRequest(payload) {
  return {
    type: types.GET_AGGR_REQUEST,
    payload,
  };
}

export function getAggrSuccess(payload) {
  return {
    type: types.GET_AGGR_SUCCESS,
    payload,
  };
}

export function getAggrFailure(error) {
  return {
    type: types.GET_AGGR_FAILURE,
    error,
  };
}

export function getAggrCached() {
  return {
    type: types.GET_AGGR_CACHED,
  };
}
