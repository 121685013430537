import * as types from './actionTypes';

export function getTaxInterviewsRequest(payload) {
  return {
    type: types.GET_TAXINTERVIEWS_REQUEST,
    payload,
  };
}

export function getTaxInterviewsSuccess(payload) {
  return {
    type: types.GET_TAXINTERVIEWS_SUCCESS,
    payload,
  };
}

export function getTaxInterviewsFailure(error) {
  return {
    type: types.GET_TAXINTERVIEWS_FAILURE,
    error,
  };
}

export function postTaxInterviewRequest(payload) {
  return {
    type: types.POST_TAXINTERVIEW_REQUEST,
    payload,
  };
}

export function postTaxInterviewSuccess(payload) {
  return {
    type: types.POST_TAXINTERVIEW_SUCCESS,
    payload,
  };
}

export function postTaxInterviewFailure(error) {
  return {
    type: types.POST_TAXINTERVIEW_FAILURE,
    error,
  };
}

export function patchTaxInterviewRequest(payload) {
  return {
    type: types.PATCH_TAXINTERVIEW_REQUEST,
    payload,
  };
}

export function patchTaxInterviewSuccess(payload) {
  return {
    type: types.PATCH_TAXINTERVIEW_SUCCESS,
    payload,
  };
}

export function patchTaxInterviewFailure(error) {
  return {
    type: types.PATCH_TAXINTERVIEW_FAILURE,
    error,
  };
}

export function downloadTaxInterviewRequest(payload) {
  return {
    type: types.DOWNLOAD_TAXINTERVIEW_REQUEST,
    payload,
  };
}

export function downloadTaxInterviewSuccess(payload) {
  return {
    type: types.DOWNLOAD_TAXINTERVIEW_SUCCESS,
    payload,
  };
}

export function downloadTaxInterviewFailure(error) {
  return {
    type: types.DOWNLOAD_TAXINTERVIEW_FAILURE,
    error,
  };
}

export function getWithHoldingCodeRequest(payload) {
  return {
    type: types.GET_WITHHOLDING_CODE_REQUEST,
    payload,
  };
}

export function getWithHoldingCodeSuccess(payload) {
  return {
    type: types.GET_WITHHOLDING_CODE_SUCCESS,
    payload,
  };
}

export function getWithHoldingCodeCache() {
  return {
    type: types.GET_WITHHOLDING_CODE_CACHE,
  };
}

export function getWithHoldingCodeFailure(error) {
  return {
    type: types.GET_WITHHOLDING_CODE_FAILURE,
    error,
  };
}
