import React from 'react';

function Loader({ width }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width || '40'} viewBox="0 0 40 40">
      <path
        d="M0.416016 18.2998H10.2077V22.2998H0.416016V18.2998ZM29.791 18.2998H39.5827V22.2998H29.791V18.2998ZM18.041 30.2998H21.9577V40.2998H18.041V30.2998ZM18.041 0.299805H21.9577V10.2998H18.041V0.299805Z"
        fill="currentColor"
      />
      <path
        d="M4.76736 7.57148L7.53644 4.74348L14.4611 11.8155L11.692 14.6435L4.76736 7.57148Z"
        fill="currentColor"
      />
      <path
        d="M35.2312 33.0279L32.4621 35.8559L25.5374 28.7839L28.3065 25.9559L35.2312 33.0279Z"
        fill="currentColor"
      />
      <path
        d="M11.692 25.9559L14.4611 28.7839L7.53644 35.8559L4.76736 33.0279L11.692 25.9559Z"
        fill="currentColor"
      />
      <path
        d="M25.5374 11.8135L32.4621 4.74348L35.2312 7.57348L28.3065 14.6435L25.5374 11.8135Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Loader;
