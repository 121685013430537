import { put, takeLatest, call, select, take } from 'redux-saga/effects';
import { getComparedTo } from 'api';
import {
  AUTH_REFRESH_SUCCESS,
  AUTH_REFRESH_FAILURE,
  AUTH_REFRESH_SKIP,
  COMPARETO_REQUEST,
} from 'redux-api/action/actionTypes';
import * as actions from 'redux-api/action/filters';
import { refreshRequest } from 'redux-api/action/auth';
import selectAuth from 'redux-api/reselect/auth';
import { openFeedback } from 'redux-api/action/feedback';
import translations from 'translations';

const selectConfig = (state) => state.config;

function* getCompareToSaga({ payload }) {
  const { languageCode } = yield select(selectConfig);

  try {
    // REFRESH TOKEN
    yield put(refreshRequest());
    const { type } = yield take([AUTH_REFRESH_SUCCESS, AUTH_REFRESH_FAILURE, AUTH_REFRESH_SKIP]);
    if (type === AUTH_REFRESH_FAILURE) throw new Error('401 - Unauthorized');

    // eslint-disable-next-line camelcase
    const { signInUserSession } = yield select(selectAuth);

    const { accessToken } = signInUserSession;

    const {
      data: {
        compare_dates: {
          previous_period: { gte: dateFrom, lte: dateTo },
        },
      },
    } = yield call(getComparedTo, accessToken, payload.dateFrom, payload.dateTo, payload.compare, payload.dateRange);

    yield put(actions.comparedToSuccess({ dateFrom, dateTo }));
  } catch (err) {
    yield put(actions.comparedToFailure(err));

    yield put(
      openFeedback({
        message: translations[languageCode].generic_retrieve_api_error,
        error: err,
      }),
    );
  }
}

export default function* watchBilling() {
  yield takeLatest(COMPARETO_REQUEST, getCompareToSaga);
}
