/* eslint-disable no-undef */

const createDownloadLink = (data, filename, mimeType) => {
  const blob = new Blob([data], { type: mimeType || 'text/csv;charset=utf-8' });
  const url = window.URL.createObjectURL(blob);
  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = url;
  tempLink.setAttribute('download', filename);
  tempLink.setAttribute('target', '_blank');

  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
};

const downloadLink = (link, filename) => {
  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = link;
  tempLink.setAttribute('download', filename);
  tempLink.setAttribute('target', '_blank');

  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
};

export default createDownloadLink;
export { downloadLink };
