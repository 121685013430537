import moment from 'moment';
import { put, take, takeLatest, call, select } from 'redux-saga/effects';
import { getReportStatuses } from 'api';
import {
  GET_REPORT_STATUSES_REQUEST,
  AUTH_REFRESH_SUCCESS,
  AUTH_REFRESH_FAILURE,
  AUTH_REFRESH_SKIP,
} from 'redux-api/action/actionTypes';
import * as actions from 'redux-api/action/reportStatuses';
import { openFeedback } from 'redux-api/action/feedback';
import { refreshRequest } from 'redux-api/action/auth';
import selectAuth from 'redux-api/reselect/auth';
// import selectProfile from 'redux-api/reselect/profile';
import translations from 'translations';
// import adminParams from '../../lib/admin-params';

const selectLastRefresh = (state, key) =>
  state.reportStatuses.payload[key] ? state.reportStatuses.payload[key].lastRefresh : null;

const selectConfig = (state) => state.config;

function* getReportStatusesSaga({ payload }) {
  const { languageCode } = yield select(selectConfig);

  try {
    // REFRESH TOKEN
    yield put(refreshRequest());
    const { type } = yield take([AUTH_REFRESH_SUCCESS, AUTH_REFRESH_FAILURE, AUTH_REFRESH_SKIP]);
    if (type === AUTH_REFRESH_FAILURE) throw new Error('401 - Unauthorized');

    // eslint-disable-next-line camelcase
    const { signInUserSession, adminRequestedStreetlibInternalId } = yield select(selectAuth);

    const { accessToken } = signInUserSession;

    const now = moment();
    const lastRefresh = yield select((state) => selectLastRefresh(state, payload.key));
    const maxCachedTime = process.env.CACHED_TIME;

    if (lastRefresh) {
      const diff = now.diff(lastRefresh, 'minutes');

      if (diff < maxCachedTime) {
        return yield put(actions.getReportStatusesCached());
      }
    }

    const { data } = yield call(
      getReportStatuses,
      languageCode,
      accessToken,
      adminRequestedStreetlibInternalId,
    );

    return yield put(actions.getReportStatusesSuccess({ ...payload, data }));
  } catch (err) {
    yield put(
      openFeedback({
        message: translations[languageCode].generic_retrieve_api_error,
        error: err,
      }),
    );

    return yield put(actions.getReportStatusesFailure(err));
  }
}

export default function* watchReportStatuses() {
  yield takeLatest(GET_REPORT_STATUSES_REQUEST, getReportStatusesSaga);
}
