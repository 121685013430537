import moment from 'moment';
import {
  GET_TIMEZONES_REQUEST,
  GET_TIMEZONES_SUCCESS,
  GET_TIMEZONES_FAILURE,
  GET_TIMEZONES_CACHED,
} from '../action/actionTypes';

const initialState = {
  payload: [],
  error: '',
  isWaiting: false,
  lastRefresh: null,
};

export default function timezones(state = initialState, action) {
  switch (action.type) {
    case GET_TIMEZONES_REQUEST:
      return {
        ...state,
        isWaiting: true,
        error: initialState.error,
      };
    case GET_TIMEZONES_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        isWaiting: false,
        lastRefresh: moment(),
        error: initialState.error,
      };
    case GET_TIMEZONES_FAILURE:
      return {
        ...state,
        error: action.error,
        isWaiting: false,
      };
    case GET_TIMEZONES_CACHED:
      return {
        ...state,
        isWaiting: false,
        error: initialState.error,
      };
    default:
      return state;
  }
}
