/* eslint-disable import/prefer-default-export */
import * as types from './actionTypes';

export function checkRedirectRequest(payload) {
  return {
    type: types.CHECK_REDIRECT_REQUEST,
    payload,
  };
}

export function checkRedirectSuccess() {
  return {
    type: types.CHECK_REDIRECT_SUCCESS,
  };
}

export function checkRedirectFailure(error) {
  return {
    type: types.CHECK_REDIRECT_FAILURE,
    error,
  };
}
